import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useQuery, useMutation } from 'react-apollo-hooks';
import { GET_ALL_PRODUCT } from '../../graphql/product/query';
import { REMOVE_PRODUCT } from '../../graphql/product/mutation';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import ProductItem from './item/ProductItem';
import IProduct from '../../Interface/IProduct';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ProductEdit from '../Products/ProductEdit';
import ProductAdd from '../Products/ProductAdd';
import {
  NotificationContainer,
  NotificationManager
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ISubcategory from '../../Interface/ISubcategory';
import ConfirmationDialog from '../../Common/ConfirmationDialog';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 30
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  fab: {
    color: theme.customPalette.text.white,
    textTransform: 'capitalize',
    backgroundColor: theme.customPalette.secondary.green,
    '&:hover': {
      backgroundColor: '#739a47'
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(2)
  },
  iconMarge: {
    marginRight: 8
  },
  padding24: {
    padding: 24
  },
  textField: {
    width: '100%',
    marginBottom: 24
  },
  bordTop: {
    borderTop: '1px solid #CACED5',
    marginTop: 16
  }
}));

interface ISUbCategoryState {
  subCategory: ISubcategory;
}

export interface IProductProps {
  titleCateg: string;
}
const ProductPage = (props: RouteComponentProps & IProductProps) => {
  const { history, location, titleCateg } = props;

  const { state } = location;
  const titleBoutonAdd =
    titleCateg === 'pizzas' ? 'Ajouter une pizza' : 'Ajouter une boisson';
  const classes = useStyles();
  const [dialogEditProductId, setDialogEditProductId] = useState<
    string | undefined
  >(undefined);

  const [isDialogAddProductOpen, setIsDialogAddProductOpen] = useState<boolean>(
    false
  );

  const idSubCategory = history.location.state
    ? history.location.state.subCategory
    : 0;
  

  const [idProduct, setIdProduct] = useState('');
  const { data, loading, error } = useQuery<ISUbCategoryState>(
    GET_ALL_PRODUCT,
    {
      variables: {
        id: idSubCategory
      },
      fetchPolicy: 'network-only'
    }
  );
  const removeProduct = useMutation(REMOVE_PRODUCT);
  const [open, setOpen] = useState(false);

  const [value, setValue] = useState('Dione');
  const handleClickEdit = (product: IProduct) => {
    history.replace(location.pathname, {
      ...state,
      product
    });

    setDialogEditProductId(product.id);
  };
  const handleClickOpen = val => {
    setIdProduct(val);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleRemove = () => {
    setOpen(false);
    removeProduct({
      update: (cache, mutationResult: any) => {
        const productData = cache.readQuery<{ subCategory: any }>({
          query: GET_ALL_PRODUCT,
          variables: {
            id: idSubCategory
          }
        });

        if (
          productData &&
          productData.subCategory &&
          mutationResult &&
          mutationResult.data
        ) {
          productData.subCategory.products = [
            ...productData.subCategory.products.filter(i => i.id !== idProduct)
          ];
          cache.writeQuery({
            query: GET_ALL_PRODUCT,
            variables: {
              id: idSubCategory
            },
            data: productData
          });
        }
      },
      variables: {
        id: idProduct
      }
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Pas de produit</div>;
  }

  console.log('==================================== data');
  console.log(data);
  console.log('====================================');

  if (!data || !data.subCategory.products) {
    return <div>Error</div>;
  }

  let menuCateg = data.subCategory.products.map((el: IProduct, key) => {
    return (
      <ProductItem
        key={key}
        titleCateg={titleCateg}
        product={el}
        handleClickOpen={handleClickOpen}
        handleCLickEdit={handleClickEdit}
        idSubCategory={idSubCategory}
      />
    );
  });


  return (
    <>
      <NotificationContainer />
      <ProductAdd
        open={Boolean(isDialogAddProductOpen)}
        titleCateg={titleCateg}
        handleClose={message => {
          setIsDialogAddProductOpen(false);
          if (message != '') {
            NotificationManager.success(message);
          }
        }}
        handleError={message => {
          if (message != '') {
            NotificationManager.error(message);
          }
        }}
      />

      {state && state.subCategory && state.product && (
        <ProductEdit
          key={state.product.id}
          titleCateg={titleCateg}
          open={Boolean(dialogEditProductId)}
          productId={dialogEditProductId}
          handleClose={message => {
            setDialogEditProductId(undefined);
            if (message != '') {
              NotificationManager.success(message);
            }
          }}
          handleError={message => {
            setIsDialogAddProductOpen(false);
            if (message != '') {
              NotificationManager.error(message);
            }
          }}
        />
      )}

      <div className={classes.root}>
        <Button
          variant="contained"
          aria-label="Ajouter"
          size="large"
          className={classes.fab}
          onClick={() => setIsDialogAddProductOpen(true)}
        >
          <AddIcon className={classes.iconMarge} />
          {titleBoutonAdd}
        </Button>

        <div className={classes.bordTop}>
          {menuCateg}

          <ConfirmationDialog
            title={
              titleCateg === 'pizzas'
                ? 'Supprimer une pizza'
                : 'Supprimer une boisson'
            }
            classes={{
              paper: classes.paper
            }}
            onConfirm={handleRemove}
            id="ringtone-menu"
            keepMounted
            open={open}
            onClose={handleClose}
            value={value}
          />
        </div>
      </div>
    </>
  );
};

export default withRouter(ProductPage);
