import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Button, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ITarif from '../../../Interface/ITarif';
import IDetail from '../../../Interface/IDetail';
import IBonus from '../../../Interface/IBonus';
import IUser from '../../../Interface/IUser';
import { useMutation } from 'react-apollo-hooks';
import ISession from '../../../Interface/ISession';
import { UPDATE_ORDER } from '../../../graphql/order/mutation';
import {
  Formatter,
  Constants,
  Status,
  Paid,
  ParamsOrder
} from '../../../Common/constant';
import IOrder from '../../../Interface/IOrder';
import { isNullOrEmpty, isNullOrEmptyList } from '../../../Utilities/Validator';
import StyledTableCell from '../../../Common/TableBodyElement/StyledTableCell/StyledTableCell';
import IProduct from "../../../Interface/IProduct";

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none'
  },
  closeButton: {
    color: theme.customPalette.text.white,
    paddingRight: 16
  },
  textButton: {
    color: theme.customPalette.text.white
  },
  textField: {
    marginTop: 20
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  button: {
    margin: '8px 0 16px'
  },
  none: {
    display: 'none'
  },
  dialogActionPadding: {
    padding: '24px'
  },
  roots: {
    width: '100%',
    marginTop: 24,
    overflowX: 'auto'
  },
  row: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row' as 'row',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto',
    padding: '12px 0 2px'
  },

  titleCatalogue: {
    fontSize: 18,
    color: theme.customPalette.text.orange
  },

  titleNom: {
    fontSize: 16,
    minWidth: 85
  },
  nom: {
    fontSize: 16
  },
  bordure: {
    border: '1px solid #CACED5',
    marginTop: 20,
    marginBottom: 24
  },
  widthProduct: {
    width: 275
  },
  bonus: {
    borderBottom: '0px solid #F1F1F3',
    width: 275,
    color: 'red'
  },
  cmmentaire: {
    borderBottom: '0px solid #F1F1F3',
    color: 'red',
    width: 275
  },
  widthPrice: {
    width: 94
  }
}));

interface ITarifState {
  getAllTarifOfProduct: ITarif[];
}
export interface ICamPagnePush {
  getAllSessionOfMail: [ISession];
}
export interface IProductProps {
  deliveryMethod: string;
  deliveryPrice: string;
  details: [IDetail];
  bonus: [IBonus];
  user: IUser;
  id: string;
  statusOrder: string;
  paidOrder: string;
  order: IOrder;
  open: boolean;
  titleCateg: string;
  handleClose: (message: String) => void;
  handleError: (message: String) => void;
}

const PopupDetail = (props: RouteComponentProps & IProductProps) => {
  const {
    open,
    handleClose,
    details,
    deliveryMethod,
    user,
    id,
    bonus,
    deliveryPrice,
    statusOrder,
    paidOrder,
    order
  } = props;

  // let listDetail = details ? details : [];
  const handleClear = () => {};
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();
  const onClose = (message: String) => {
    handleClose(message);
    handleClear();
  };
  const generateStatus = () => {
    switch (statusOrder) {
      case ParamsOrder.prete:
        return ParamsOrder.prete;
      case ParamsOrder.enAttente:
        return ParamsOrder.enAttente;
      case ParamsOrder.annuler:
        return ParamsOrder.annuler;
      default:
        return ParamsOrder.enAttente;
    }
  };
  const generatePaid = () => {
    switch (paidOrder) {
      case ParamsOrder.oui:
        return ParamsOrder.oui;
      case ParamsOrder.non:
        return ParamsOrder.non;
      default:
        return ParamsOrder.non;
    }
  };

  const isPaidOnInit = paidOrder;
  const statusOnInit = statusOrder;


  const [values, setValues] = useState({
    status: ''
  });
  const [valuePaid, setValuePaid] = useState({
    paid: ''
  });
  useEffect(() => {
    setValues({ ...values, status: statusOrder });
    setValuePaid({ ...valuePaid, paid: paidOrder });
  }, [statusOrder, paidOrder]);

  const updateOrder = useMutation(UPDATE_ORDER);
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleChangePaid = name => event => {
    setValuePaid({ ...valuePaid, [name]: event.target.value });
  };

  if (!details) {
    return null;
  }

  const handleSubmit = async () => {
    updateOrder({
      variables: { id, status: values.status, paid: valuePaid.paid }
    }).finally(() => handleClose(''));
  };
  const generatePrice = (quantity: string, price: string) => {
    return parseFloat(quantity) * parseFloat(price);
  };
  const generateTaille = (size: string) => {
    switch (size) {
      case Constants.SM:
        return 'P';
      case Constants.MD:
        return 'Mo';
      case Constants.LG:
        return 'G';
      case Constants.XLG:
        return 'Mx';
      default:
        return '';
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose('');
      }}
      fullScreen={fullScreen}
      fullWidth
    >
      <Grid
        direction="row"
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
        container
        id="alert-dialog-title"
      >
        <DialogTitle>{'Détails commande'}</DialogTitle>
        <IconButton
          className={classes.closeButton}
          onClick={() => onClose('')}
          aria-label="fermer"
        >
          <ClearIcon />
        </IconButton>
      </Grid>

      <DialogContent>
        <div className={classes.row}>
          <Typography className={classes.titleNom}>Prénom :</Typography>
          <Typography className={classes.nom}>
            {user.firstName ? user.firstName : '-'}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleNom}>Nom :</Typography>
          <Typography className={classes.nom}>
            {user.lastName ? user.lastName : '-'}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleNom}>Email :</Typography>
          <Typography className={classes.nom}>
            {user.email ? user.email : '-'}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleNom}>Téléphone :</Typography>
          <Typography className={classes.nom}>
            {order.phone ? order.phone : '-'}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleNom}>Adresse :</Typography>
          <Typography className={classes.nom}>
            {order.deliveryAddress ? order.deliveryAddress : '-'}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleNom}>Total :</Typography>
          <Typography className={classes.nom}>
            {order.subTotal
              ? Formatter.format(parseFloat(order.subTotal))
              : '-'}
          </Typography>
        </div>
        <div className={classes.bordure}>
          {details.map(row => (
            <div>
              {' '}
              <Grid
                key={Math.floor(Math.random() * Math.floor(1000))}
                direction="row"
                wrap="nowrap"
                justify="space-between"
                alignItems="center"
                container
              >
                <StyledTableCell
                  className={classes.widthProduct}
                  component="th"
                  scope="row"
                >
                  {row.product.productName}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {`${row.quantity} ${generateTaille(row.size)}`}
                </StyledTableCell>
                <StyledTableCell align="right" className={classes.widthPrice}>
                  {Formatter.format(generatePrice(row.quantity, row.priceUnit))}
                </StyledTableCell>
              </Grid>
              <div>
                {!isNullOrEmptyList(row.supplements) &&
                  row.supplements.map(s => (
                    <Grid
                      key={s.id}
                      direction="row"
                      wrap="nowrap"
                      justify="space-between"
                      alignItems="center"
                      container
                    >
                      <StyledTableCell
                        className={classes.bonus}
                        component="th"
                        scope="row"
                      >
                        {`${s.supplementName} ${Formatter.format(
                          parseFloat(s.supplementPrice)
                        )}`}
                      </StyledTableCell>
                    </Grid>
                  ))}
              </div>
              <div>
                {!isNullOrEmpty(row.codeKDO) && (
                  <Grid
                    direction="row"
                    wrap="nowrap"
                    justify="flex-start"
                    alignItems="center"
                    container
                  >
                    <StyledTableCell
                      className={classes.cmmentaire}
                      component="th"
                      scope="row"
                    >
                      {`*Code KDO : ${row.codeKDO} ( - ${row.priceUnit}`} &euro;
                      )
                    </StyledTableCell>
                  </Grid>
                )}
              </div>
              <div>
                {!isNullOrEmpty(row.comment) && (
                  <Grid
                    direction="row"
                    wrap="nowrap"
                    justify="flex-start"
                    alignItems="center"
                    container
                  >
                    <StyledTableCell
                      className={classes.cmmentaire}
                      component="th"
                      scope="row"
                    >
                      {`Commentaires : ${row.comment}`}
                    </StyledTableCell>
                  </Grid>
                )}
              </div>
            </div>
          ))}
          {deliveryMethod === ParamsOrder.aDomicile && (
            <Grid
              direction="row"
              wrap="nowrap"
              justify="space-between"
              alignItems="center"
              container
            >
              <StyledTableCell
                className={classes.widthProduct}
                component="th"
                scope="row"
              >
                {'Livraison'}
              </StyledTableCell>
              <StyledTableCell align="left">{'-'}</StyledTableCell>
              <StyledTableCell align="right" className={classes.widthPrice}>
                {Formatter.format(order.deliveryPrice)}
              </StyledTableCell>
            </Grid>
          )}
        </div>

        {!isNullOrEmptyList(bonus) && (
          <div className={classes.row}>
            <Typography className={classes.titleNom}>Bonus :</Typography>
          </div>
        )}

        {!isNullOrEmptyList(bonus) &&
          bonus.map(b => (
            <Grid
              key={b.id}
              direction="row"
              wrap="nowrap"
              justify="space-between"
              alignItems="center"
              container
            >
              <StyledTableCell
                className={classes.bonus}
                component="th"
                scope="row"
              >
                {`${parseInt(b.qty) * b.formule.drinkQty} ${
                  b.formule.drink.productName
                }`}
              </StyledTableCell>
            </Grid>
          ))}
        <div className={classes.row}>
          <TextField
            id="outlined-select-currency-native"
            select
            label="Statut"
            fullWidth
            value={values.status}
            onChange={handleChange('status')}
            SelectProps={{
              native: true
            }}
            variant="outlined"
          >
            {Status.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </div>
        
        <div className={classes.row}>
          <TextField
            id="outlined-select-currency-native"
            select
            label="Paiement"
            disabled={(isPaidOnInit === ParamsOrder.oui || statusOnInit === ParamsOrder.annuler) ? true : false}
            fullWidth
            value={valuePaid.paid}
            onChange={handleChangePaid('paid')}
            SelectProps={{
              native: true
            }}
            variant="outlined"
          >
            {Paid.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActionPadding}>
        <Button
          variant="contained"
          className={classes.textButton}
          color="secondary"
          onClick={handleSubmit}
        >
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(PopupDetail);
