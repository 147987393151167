import React, { useState, Fragment } from 'react';
import logo from '../../Assets/Images/logoj2rc.png';
import { Typography, makeStyles } from '@material-ui/core';
import TableKdoCodes from './TableKdoCodes';

const useStyles = makeStyles(theme => ({
  titleCatalogue: {
    fontSize: 18,
    color: theme.customPalette.text.orange
  }
}));

const ListKdoCodes = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <Typography align="left" className={classes.titleCatalogue}>
        Code KDOs
      </Typography>
      <TableKdoCodes />
    </Fragment>
  );
};

export default ListKdoCodes;
