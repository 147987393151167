import React, { useState } from 'react';
import logo from '../../Assets/Images/logoj2rc.png';
const Apropos = () => {
  return (
    <html>
      <body>
        <table style={{ width: '100%', height: '100%' }}>
          <tr>
            <td align="center" style={{ width: '100%', height: '100%' }}>
              <h3>Application : DLYSS Pizza</h3>
              <br />
              <br />
              <b>Version :</b> 1.0
              <br />
              <br />
              <b>Date :</b> 01/08/2019
              <br />
              <br />
              <b>Développée par :</b>
              <br />
              <br />
              <a href="https://j2rconsulting.fr" target="_blank">
                <img src={logo} width="200px" height="220px" />
              </a>
              <br />
              <br />
              <b>Mail :</b>{' '}
              <a href="mailto:contact@j2rconsulting.fr">
                contact@j2rconsulting.fr
              </a>
              <br />
              <br />
            </td>
          </tr>
        </table>
      </body>
    </html>
  );
};

export default Apropos;
