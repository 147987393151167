import gql from "graphql-tag";
import { FORMULE_FRAGMENT } from "./fragment";

const GET_FORMULES = gql`
  query GET_FORMULES($filter: String) {
    formules(filter: $filter) {
      ...formuleInfos
    }
  }
  ${FORMULE_FRAGMENT}
`;
export { GET_FORMULES };
