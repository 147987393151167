import gql from 'graphql-tag';
import { FIDELITY_FRAGMENT } from './fragment';

const FIDELITIES = gql`
  query fidelities {
    fidelities {
      ...fidelityInfos
      user {
        ...userInfos
      }
    }
  }
  ${FIDELITY_FRAGMENT}
`;
export { FIDELITIES };
