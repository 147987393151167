import gql from 'graphql-tag';

const GET_CA = gql`
  query chiffreAffaires($debut: Date!, $fin: Date!) {
    chiffreAffaires(input: { debut: $debut, fin: $fin }) {
      id
      product {
        productName
      }
      quantity
      priceUnit
      subTotal
      size
      supplements {
        id
        supplementName
        supplementPrice
      }
      order {
        id
        orderTime
        city
        status
        user {
          id
          firstName
          address
        }
      }
    }
  }
`;

export { GET_CA };
