import React, { useRef, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';
import ImageWithUpload from '../../Common/cloudinary/ImageWithUpload';
import ICloudinaryUploadResult from '../../Interface/ICloudinaryUploadResult';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useMutation } from 'react-apollo-hooks';
import { UPDATE_PRODUCT } from '../../graphql/product/mutation';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import {
  Button,
  TextField,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox
} from '@material-ui/core';
import { ProductAvailabilityEnum } from '../../Interface/ProductAvailabilityEnum';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'none'
  },
  closeButton: {
    color: theme.customPalette.text.white,
    paddingRight: 16
  },
  textButton: {
    color: theme.customPalette.text.white
  },
  textField: {
    marginTop: 20
  },
  uploadCheckBox: { display: 'flex', flexDirection: 'row' },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  button: {
    margin: '8px 0 16px'
  },
  none: {
    display: 'none'
  },
  dialogActionPadding: {
    padding: '0 12px'
  },
  availabilityFields: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&>legend': {
      marginLeft: 10,
      marginRight: 10
    }
  }
}));

export interface IProductProps {
  open: boolean;
  productId: string;
  titleCateg: string;
  handleClose: (message: String) => void;
  handleError: (message: String) => void;
}

const ProductEdit = (props: RouteComponentProps & IProductProps) => {
  const {
    history,
    productId,
    open,
    handleClose,
    handleError,
    titleCateg
  } = props;
  const idSubCategory = history.location.state.subCategory;

  const [nom, setNom] = useState(history.location.state.product.productName);
  const [description, setDescription] = useState(
    history.location.state.product.productDescritpion
  );
  const [timeToprepare, setTimeToprepare] = useState(
    history.location.state.product.timeToPrepare
  );
  const [isActive, setIsActive] = useState(
    history.location.state.product.isActivate
  );
  const [urlPhoto, setUrlPhoto] = useState(
    history.location.state.product.urlPhoto
  );

  const [buyableByKdoCode, setBuyableByKdoCode] = useState(
    history.location.state.product.buyableByKdoCode
  );

  const inputFileRef = useRef<HTMLInputElement>(null);
  const [prixP, setPrixP] = useState(history.location.state.product.tarif1);
  const [prixM, setPrixM] = useState(history.location.state.product.tarif2);
  const [prixG, setPrixG] = useState(history.location.state.product.tarif3);
  const [isUploading, setUploading] = useState(false);
  const [prixMaxi, setPrixMaxi] = useState(
    history.location.state.product.tarif4
  );
  const [availability, setAvailability] = useState(
    history.location.state.product.availability
  );
  const classes = useStyles();
  const updateProduct = useMutation(UPDATE_PRODUCT);

  // const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = async () => {
    updateProductInList();
  };

  const updateProductInList = () => {
    try {
      updateProduct({
        variables: {
          id: productId,
          productName: nom,
          productDescritpion: description,
          isActivate: isActive,
          timeToPrepare: timeToprepare,
          typeProduct: nom,
          subCategoryId: idSubCategory,
          urlPhoto: urlPhoto,
          tarif1: prixP,
          tarif2: prixM,
          tarif3: prixG,
          tarif4: prixMaxi,
          buyableByKdoCode,
          availability
        }
      }).then(
        valeur => {
          handleClose('Enregistrement avec succès');
        },
        raison => {}
      );
    } catch {
      handleError('Erreur lors du modification');
    }
  };
  const generatePrix = () => {
    if (titleCateg === 'pizzas') {
      return (
        <Grid
          container
          className={classes.textField}
          direction="row"
          justify="space-between"
          wrap="nowrap"
          spacing={3}
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-name"
              fullWidth
              variant="outlined"
              label="Prix P"
              placeholder="Prix P"
              required
              type="number"
              value={prixP}
              onChange={e => setPrixP(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-name"
              fullWidth
              variant="outlined"
              label="Prix M"
              placeholder="Prix M"
              required
              type="number"
              value={prixM}
              onChange={e => setPrixM(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-name"
              fullWidth
              variant="outlined"
              label="Prix G"
              placeholder="Prix G"
              required
              type="number"
              value={prixG}
              onChange={e => setPrixG(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-name"
              fullWidth
              variant="outlined"
              label="Prix Mx"
              placeholder="Prix Mx"
              required
              type="number"
              value={prixMaxi}
              onChange={e => setPrixMaxi(e.target.value)}
            />
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          className={classes.textField}
          direction="row"
          justify="space-between"
          wrap="nowrap"
          spacing={3}
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-name"
              fullWidth
              variant="outlined"
              label="Prix"
              placeholder="Prix"
              required
              type="number"
              value={prixP}
              onChange={e => setPrixP(e.target.value)}
            />
          </Grid>
        </Grid>
      );
    }
  };

  /**availability map */
  const availabilityMap = new Map();
  availabilityMap.set(ProductAvailabilityEnum.ALWAYS, 'Tout le temps');
  availabilityMap.set(ProductAvailabilityEnum.NOT_AVAILABLE, 'Non disponible');
  availabilityMap.set(ProductAvailabilityEnum.WEEKEND, 'Week-end');

  const onUploadDone = (arg: ICloudinaryUploadResult) => {
    setUrlPhoto(arg.public_id);
  };
  const handleAvailabilityChange = (event: React.ChangeEvent<unknown>) => {
    setAvailability(
      ProductAvailabilityEnum[(event.target as HTMLInputElement).value]
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose('')}
      fullScreen={fullScreen}
      fullWidth
    >
      <Grid
        direction="row"
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
        container
        id="alert-dialog-title"
      >
        <DialogTitle>
          {titleCateg === 'pizzas'
            ? 'Modifier une pizza'
            : 'Modifier une boisson'}
        </DialogTitle>
        <IconButton
          className={classes.closeButton}
          onClick={() => handleClose('')}
          aria-label="fermer"
        >
          <ClearIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            id="outlined-name"
            fullWidth
            variant="outlined"
            label={
              titleCateg === 'pizzas' ? 'Nom de la pizza' : 'Nom de la boisson'
            }
            className={classes.textField}
            required
            value={nom}
            onChange={e => setNom(e.target.value)}
          />

          <TextField
            id="outlined-dense-multiline"
            fullWidth
            variant="outlined"
            label={titleCateg === 'pizzas' ? 'Ingrédients' : 'Description'}
            className={classes.textField}
            required
            multiline
            rowsMax="4"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          {titleCateg === 'pizzas' ? (
            <TextField
              id="outlined-name"
              fullWidth
              variant="outlined"
              className={classes.textField}
              label="Temps de preparation"
              placeholder="Temps de preparation"
              required
              value={timeToprepare}
              onChange={e => setTimeToprepare(e.target.value)}
            />
          ) : null}
          {generatePrix()}
          
            <Grid
              className={classNames(
                classes.textField,
                classes.availabilityFields
              )}
            >
              <FormLabel component="legend">Disponibilité</FormLabel>
              <RadioGroup
                aria-label="Disponibilité"
                name="availability"
                value={availability}
                onChange={handleAvailabilityChange}
                row={true}
              >
                {Object.values(ProductAvailabilityEnum).map(availability => (
                  <FormControlLabel
                    key={availability}
                    value={availability}
                    control={<Radio />}
                    label={availabilityMap.get(availability)}
                  />
                ))}
              </RadioGroup>

              {titleCateg === 'pizzas' && (
                <Fragment>
                  <FormLabel component="legend">
                    Pizza acceptée via code kdo
                  </FormLabel>
                  <Checkbox
                    checked={buyableByKdoCode}
                    onChange={() => setBuyableByKdoCode(!buyableByKdoCode)}
                    value="checkedA"
                    inputProps={{
                      'aria-label': 'primary checkbox'
                    }}
                  />
                </Fragment>
              )}
            </Grid>

          <ImageWithUpload
            publicId={urlPhoto}
            onUploadDone={onUploadDone}
            onUploadStateChange={setUploading}
            edit={true}
            width={'150'}
            height={'150'}
            crop={'fill'}
          />
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActionPadding}>
        <div className="margin">
          <Button
            type="submit"
            onClick={handleSubmit}
            disabled={isUploading}
            className={classes.textButton}
            variant="contained"
            color="secondary"
          >
            Modifier
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(ProductEdit);
