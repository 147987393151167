import gql from 'graphql-tag';
import { SUPPLEMENT_FRAGMENT } from './fragment';

const GET_SUPPLEMENTS = gql`
  query GET_SUPPLEMENTS($filter: String) {
    supplements(filter: $filter) {
      ...supplementInfos
    }
  }
  ${SUPPLEMENT_FRAGMENT}
`;
export { GET_SUPPLEMENTS };
