import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { styles } from './styles';
import { isEmailValid } from '../../../Utilities/Validator';
import { FORGOT_PASSWORD } from '../../../graphql/authentication/mutation';
import { useMutation } from 'react-apollo-hooks';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}
interface ForgotPasswordState {
  email: string;
  isLoading: boolean;
}
const initialState: ForgotPasswordState = {
  email: '',
  isLoading: false
};
const ForgotPassword = (props: Props & WithStyles) => {
  const [state, setState] = useState(initialState);
  const [requestMessage, setRequestMessage] = useState('');
  const [isResetPwdDone, setResetPwdDone] = useState(false);

  // handlers
  const setRequestMessageAndStatus = (isSuccess: boolean, message: string) => {
    setRequestMessage(message);
    setResetPwdDone(isSuccess);
  };

  const handleMailChange = (e: any) => {
    const email = (e && e.target && e.target.value) || '';
    setState({ ...state, email });
    setRequestMessage('');
  };

  const handleSubmit = () => {
    if (state.isLoading) {
      return;
    }
    setState({ ...state, isLoading: true });
    forgotPassword({
      variables: {
        email: state.email
      }
    })
      .then(
        () =>
          setRequestMessageAndStatus(
            true,
            'Votre mot de passe a été réinitialisé, veuillez vérifier votre boite mail.'
          ),
        () =>
          setRequestMessageAndStatus(
            false,
            "Une erreur est survenue. Veuillez réessayer plus tard, ou vérifier que l'adresse entrée soit correcte."
          )
      )
      .finally(() => setState({ ...state, isLoading: false }));
  };

  // mutation
  const forgotPassword = useMutation(FORGOT_PASSWORD);

  // render variables
  const { isOpen, closeModal, classes } = props;
  const isValidEmail: boolean = isEmailValid(state.email);

  return (
    <Modal
      open={isOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      onClose={closeModal}
      className={classes.modal}
    >
      <div className={classNames(classes.paper)}>
        Veuillez entrer votre adresse mail
        <TextField
          label="Email"
          fullWidth={true}
          className={classes.margin}
          onChange={handleMailChange}
        />
        {requestMessage && (
          <span
            className={classNames(
              classes.margin,
              classes.info,
              isResetPwdDone ? classes.success : classes.error
            )}
          >
            {requestMessage}
          </span>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          className={classes.buttonReset}
          disabled={!isValidEmail}
          onClick={isResetPwdDone ? closeModal : handleSubmit}
        >
          {isResetPwdDone
            ? 'Fermer'
            : state.isLoading
            ? ' Réinitialisation... '
            : ' Réinitialiser le mot de passe '}
        </Button>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(ForgotPassword);
