import React from 'react';
import useStyles from './styles';
import classNames from 'classnames';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Constants from '../../defaults/Constants';

const StatusList = [
  Constants.FIDELITY_OPERATION.ACTIVATE,
  Constants.FIDELITY_OPERATION.DESACTIVATE,
  Constants.FIDELITY_OPERATION.CANCEL
];

const FilterState = (props: {
  value: string;
  setFilterState: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { value, setFilterState } = props;
  const classes = useStyles({});

  const inputLabel = React.useRef<HTMLLabelElement>(null);

  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  return (
    <FormControl
      variant="outlined"
      className={classNames(classes.formControl, classes.formControlSelect)}
    >
      <InputLabel ref={inputLabel} htmlFor="filter-status">
        STATUT
      </InputLabel>
      <Select
        value={value}
        onChange={(value: any) => setFilterState(value.target.value)}
        margin="dense"
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name="filter-status"
            id="filter-status"
            margin="dense"
          />
        }
      >
        <MenuItem value="">
          <em>Aucun</em>
        </MenuItem>
        {StatusList.map(status => (
          <MenuItem value={status.VALUE}>{status.LABEL}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterState;
