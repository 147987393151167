import * as React from 'react';
import AuthService from '../Services/AuthService';
import { Redirect, Route, RouteProps } from 'react-router-dom';

const ProtectedRoute = (
  props: { authenticationPath?: string } & RouteProps
) => {
  let redirectPath: string = '';

  if (!AuthService.isAuthenticated()) {
    redirectPath = props.authenticationPath || '/login';
  }

  if (redirectPath !== '') {
    const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  } else {
    return <Route {...props} />;
  }
};
export default ProtectedRoute;
