import gql from "graphql-tag";
import { FORMULE_FRAGMENT } from "./fragment";

const ADD_FORMULE = gql`
  mutation ADD_FORMULE(
    $name: String!
    $pizzaQty: Int!
    $pizzaSize: PizzaSizeEnum!
    $drinkQty: Int!
    $drink: ID!
  ) {
    addFormule(
      input: {
        name: $name
        pizzaQty: $pizzaQty
        pizzaSize: $pizzaSize
        drinkQty: $drinkQty
        drink: $drink
      }
    ) {
      ...formuleInfos
    }
  }
  ${FORMULE_FRAGMENT}
`;

const DELETE_FORMULE = gql`
  mutation DELETE_FORMULE($id: ID!) {
    deleteFormule(id: $id) {
      message
      success
      action
    }
  }
`;
const UPDATE_FORMULE = gql`
  mutation UPDATE_FORMULE(
    $id: ID!
    $name: String!
    $pizzaQty: Int!
    $pizzaSize: PizzaSizeEnum!
    $drinkQty: Int!
    $drink: ID!
  ) {
    updateFormule(
      input: {
        id: $id
        name: $name
        pizzaQty: $pizzaQty
        pizzaSize: $pizzaSize
        drinkQty: $drinkQty
        drink: $drink
      }
    ) {
      ...formuleInfos
    }
  }
  ${FORMULE_FRAGMENT}
`;
export { ADD_FORMULE, DELETE_FORMULE, UPDATE_FORMULE };
