import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  roots: {
    width: '100%',
    marginTop: 24,
    overflowX: 'auto'
  },
  row: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row' as 'row',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto',
    alignItems: 'center' as 'center'
  },

  titleCatalogue: {
    fontSize: 18,
    color: theme.customPalette.text.orange
  },
  table: {
    minWidth: 700
  },
  minWidthThTitle: {
    minWidth: 144
  },
  minWidthThClient: {
    minWidth: 144
  },
  minWidthThStatus: {
    minWidth: 132
  },
  minWidthThLivrer: {
    minWidth: 100
  },
  minWidthThSupplement: {
    minWidth: 274
  },
  roundVertStatus: {
    backgroundColor: theme.customPalette.secondary.green,
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 12,
    display: 'inline-block'
  },
  roundOrangeStatus: {
    backgroundColor: theme.customPalette.secondary.main,
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 12,
    display: 'inline-block'
  },
  roundRedStatus: {
    backgroundColor: theme.customPalette.text.red,
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 12,
    display: 'inline-block'
  },
  roundGreyStatus: {
    backgroundColor: theme.customPalette.text.blueGrey,
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 12,
    display: 'inline-block'
  },
  roundVertLivrer: {
    backgroundColor: theme.customPalette.secondary.green,
    width: 15,
    height: 15,
    borderRadius: '50%',
    margin: '0 auto'
  },
  rootPagination: {
    borderBottom: 'none',
    padding: 0
  },
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  },
  filters: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  buttonPrimary: {
    color: '#fff',
    backgroundColor: theme.customPalette.secondary.green
  },
  buttonSecondary: {
    color: '#fff',
    backgroundColor: theme.customPalette.secondary.main
  }
}));

export default useStyles;
