import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export type SortDirection = 'asc' | 'desc';
function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}
function desc<T>(a: T, b: T, orderBy: keyof T) {
  let valA = `${orderBy}`.split('.').reduce((o, i) => o[i], a);
  let valB = `${orderBy}`.split('.').reduce((o, i) => o[i], b);
  valA = isNaN(valA) || valA === '' ? valA : +valA;
  valB = isNaN(valB) || valB === '' ? valB : +valB;
  if (valB < valA) {
    return -1;
  }
  if (valB > valA) {
    return 1;
  }
  return 0;
}
function getSorting<K extends keyof any>(
  orderByDirection: SortDirection,
  orderBy: K
): (
  a: { [key in K]: number | string | any },
  b: { [key in K]: number | string | any }
) => number {
  return orderByDirection === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

// export excel
const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export { stableSort, desc, getSorting, exportToCSV };
