import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FormulesAdd from '../categoryFormules/Dialog/FormulesAdd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { GET_FORMULES } from '../../graphql/formule/query';
import IFormule from '../../Interface/IFormule';
import { useQuery, useMutation } from 'react-apollo-hooks';
import ListFormules from './ListFormules';
import {
  ADD_FORMULE,
  DELETE_FORMULE,
  UPDATE_FORMULE
} from '../../graphql/formule/mutation';
import { GET_BOISSONS } from '../../graphql/product/query';
import IProduct from '../../Interface/IProduct';
import {
  NotificationContainer,
  NotificationManager
} from 'react-notifications';
import ConfirmationDialog from '../../Common/ConfirmationDialog';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '24px 28px'
  },
  col: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'column' as 'column',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto'
  },
  row: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row' as 'row',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto',
    '@media (max-width: 768px)': {
      flexDirection: 'column' as 'column'
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  fab: {
    color: theme.customPalette.text.white,
    textTransform: 'capitalize',
    backgroundColor: theme.customPalette.secondary.green,
    '&:hover': {
      backgroundColor: '#739a47'
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(2)
  },
  titleCatalogue: {
    fontSize: 18,
    color: theme.customPalette.text.orange
  },
  bordure: {
    border: '1px solid #CACED5',
    marginTop: 14
  },
  padding24: {
    padding: '30px 24px',
    borderBottom: '1px solid #CACED5'
  },
  iconMarge: {
    marginRight: 8
  },
  textField: {
    width: '100%',
    marginBottom: 24
  },
  bordTop: {
    borderTop: '1px solid #CACED5',
    marginTop: 16
  }
}));

interface IQueryFormulesResult {
  formules: IFormule[];
}
interface IQueryBoissonsResult {
  category: {
    products: IProduct[];
  };
}

export interface ICategoryFormulesProps {
  titleCateg: string;
}
const Formules = (props: RouteComponentProps & ICategoryFormulesProps) => {
  const titleBoutonAdd = 'Ajouter une formule';
  const classes = useStyles();

  const [open, setOpen] = useState(false); // to open add/edit dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // to open delete dialog
  const [stagedFormule, setStagedFormule] = useState<IFormule>(); // to store the formule to delete/edit
  const [formulesFilter, setFormulesFilter] = useState<string>(undefined); // to store the filter string

  const handleClickOpen = () => {
    setStagedFormule(undefined);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStagedFormule(undefined);
    setOpenDeleteDialog(false);
  };

  const handleFilterChanged = e => setFormulesFilter(e.target.value);

  const handleOnNewFormule = (newFormule: {
    name;
    pizzaQty;
    pizzaSize;
    drinkQty;
    drink;
  }) => {
    const { name, pizzaQty, pizzaSize, drinkQty, drink } = newFormule;
    addFormule({
      variables: {
        name,
        pizzaQty: +pizzaQty,
        pizzaSize,
        drinkQty: +drinkQty,
        drink: +drink
      }
    }).then(
      valeur => {
        handleClose();
        NotificationManager.success('Formule ajoutée avec succès');
      },
      raison => {
        NotificationManager.error("Erreur lors de l'ajout");
        console.log('ADD_FORMULE FAILED :', raison);
      }
    );
  };

  const handleOnUpdateFormule = (newFormule: IFormule) => {
    const { id, name, pizzaQty, pizzaSize, drinkQty, drink } = newFormule;
    updateFormule({
      variables: {
        id,
        name,
        pizzaQty: +pizzaQty,
        pizzaSize,
        drinkQty: +drinkQty,
        drink: +drink.id
      }
    }).then(
      valeur => {
        handleClose();
        NotificationManager.success('Formule mofifiée avec succès');
      },
      raison => {
        NotificationManager.error('Erreur lors de la modification');
        console.log('UPDATE_FORMULE FAILED :', raison);
      }
    );
  };

  const deleteStagedFormule = () => {
    deleteFormule({
      variables: {
        id: +stagedFormule.id
      }
    }).then(
      valeur => {
        handleClose();
        NotificationManager.success('Formule supprimée avec succès');
      },
      raison => {
        NotificationManager.error('Erreur lors de la suppression');
        console.log('DELETE_FORMULE FAILED :', raison);
      }
    );
  };

  const handleDeleteClicked = (id: number) => {
    setStagedFormule({ id } as IFormule);
    setOpenDeleteDialog(true);
  };

  const handleEditClicked = (formule: IFormule) => {
    setStagedFormule(formule);
    setOpen(true);
  };

  const addFormule = useMutation(ADD_FORMULE, {
    update: (cache, { data }: { data: { addFormule: IFormule } }) => {
      const { addFormule } = data;
      const { formules } = cache.readQuery({ query: GET_FORMULES });
      cache.writeQuery({
        query: GET_FORMULES,
        data: {
          formules: formules.concat([addFormule])
        }
      });
    }
  });
  const updateFormule = useMutation(UPDATE_FORMULE, {
    update: (cache, { data }: { data: { updateFormule: IFormule } }) => {
      const { updateFormule } = data;
      const { formules } = cache.readQuery({ query: GET_FORMULES });
      var foundIndex = formules.findIndex(
        formule => formule.id === updateFormule.id
      );
      formules[foundIndex] = updateFormule;
      cache.writeQuery({
        query: GET_FORMULES,
        data: {
          formules: [...formules]
        }
      });
    }
  });
  const deleteFormule = useMutation(DELETE_FORMULE, {
    update: (
      cache,
      {
        data
      }: {
        data: {
          deleteFormule: { message: string; action: string; success: boolean };
        };
      }
    ) => {
      const { deleteFormule } = data;
      const { formules } = cache.readQuery({ query: GET_FORMULES });
      cache.writeQuery({
        query: GET_FORMULES,
        data: {
          formules: [
            ...formules.filter(formule => formule.id !== deleteFormule.message)
          ]
        }
      });
    }
  });
  const { data: dataQueryFormules } = useQuery<IQueryFormulesResult>(
    GET_FORMULES,
    {
      variables: {
        filter: formulesFilter
      }
    }
  );
  const listFormules = dataQueryFormules ? dataQueryFormules.formules : [];

  const { data: dataQueryBoissons } = useQuery<IQueryBoissonsResult>(
    GET_BOISSONS
  );
  const listBoissons =
    dataQueryBoissons && dataQueryBoissons.category
      ? dataQueryBoissons.category.products
      : [];

  return (
    <>
      <NotificationContainer />
      <FormulesAdd
        open={open}
        formuleToUpdate={stagedFormule}
        onClose={handleClose}
        onNewFormule={handleOnNewFormule}
        onUpdateFormule={handleOnUpdateFormule}
        boissons={listBoissons}
      />
      <div>
        <Typography align="left" className={classes.titleCatalogue}>
          Catalogues : Formules
        </Typography>
        <Grid container className={classNames(classes.bordure)} spacing={0}>
          <Grid item justify="center" direction="column" xs={12}>
            <div className={classes.root}>
              {/*<form noValidate autoComplete="off">
                <TextField
                  id="outlined-search"
                  label="Recherche"
                  type="search"
                  className={classes.textField}
                  variant="outlined"
                  onChange={handleFilterChanged}
                />
              </form>*/}
              <Button
                variant="contained"
                aria-label="Ajouter"
                size="large"
                className={classes.fab}
                onClick={handleClickOpen}
              >
                <AddIcon className={classes.iconMarge} />
                {titleBoutonAdd}
              </Button>
              {/* List */}
              <div className={classes.bordTop}>
                <ListFormules
                  formules={listFormules}
                  onEditClicked={handleEditClicked}
                  onDeleteClicked={handleDeleteClicked}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* Deletion dialog */}
      <ConfirmationDialog
        title={'Supprimer une formule'}
        id={'delete-dialog'}
        classes={{
          paper: classes.paper
        }}
        keepMounted
        open={openDeleteDialog}
        onConfirm={deleteStagedFormule}
        onClose={handleClose}
        value={''}
      />
    </>
  );
};

export default withRouter(Formules);
