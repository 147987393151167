import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableRow, TableCell, Fab } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import EditIcon from '@material-ui/icons/Edit';

import DeleteIcon from '@material-ui/icons/Delete';
import ITarif from '../../../Interface/ITarif';
import IProduct from '../../../Interface/IProduct';

export interface ICategoryMenuProps {
  handleCLickEdit: Function;
  handleClickOpen: Function;
  tarif: ITarif;
  product: IProduct;
}

export interface ICategoryCategoryMenuItemtate { }

const TarifItem = (props: ICategoryMenuProps) => {

  const classes = useStyles();
  const { handleClickOpen, tarif, handleCLickEdit } = props;

  return (
    <Fragment>
      <TableRow key="key">
        <TableCell component="th" scope="row">
          <Typography align="left"  gutterBottom>
            {props.tarif.tarifName} {props.tarif.tarifPrice} &euro;
          </Typography>
        </TableCell>
        <TableCell>
          <Fab
            aria-label="Modifier"
            color="default"
            className={classes.fab}
            onClick={() => handleCLickEdit(props.tarif, props.product)}
          >
            <EditIcon />
          </Fab>
          <Fab
            aria-label="Supprimer"
            color="secondary"
            className={classes.fab}
            onClick={() => handleClickOpen(props.tarif.tarifId)}
          >
            <DeleteIcon />
          </Fab>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(2)
  }
}));

export default TarifItem;
