import gql from 'graphql-tag';

export const SIGNUP = gql`
  mutation SIGNUP($inputSignup: SignupInput) {
    signup(input: $inputSignup)
  }
`;

export const LOGIN = gql`
  mutation LOGIN($email: String!, $password: String!) {
    login(email: $email, password: $password, isAdmin: true)
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation FORGOT_PASSWORD($email: String!) {
    forgotPassword(email: $email) {
      action
      success
      message
    }
  }
`;
