import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ISupplement from '../../../Interface/ISupplement';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '12px 24px'
  },
  closeButton: {
    color: theme.customPalette.text.white,
    paddingRight: 16
  },
  textButton: {
    color: theme.customPalette.text.white
  },
  textField: {
    marginTop: 20
  },
  titleChamp: {
    fontSize: 16,
    margin: '32px 0 16px'
  },
  button: {
    margin: '8px 0 16px'
  },
  dialogActionPadding: {
    padding: '20px 12px 4px'
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  none: {
    display: 'none'
  }
}));

export interface ISupplementProps {
  open: boolean;
  supplementToUpdate?: ISupplement;
  onClose?: () => void;
  onUpdateSupplement: (newSupplement: ISupplement) => void;
  onNewSupplement: (newSupplement: {
    supplementName: string;
    supplementPrice: string;
  }) => void;
}

const initialValues = {
  id: undefined,
  supplementName: '',
  supplementPrice: '0'
};

const DialogAddSupplement = (props: RouteComponentProps & ISupplementProps) => {
  const {
    open,
    onClose,
    onNewSupplement,
    onUpdateSupplement,
    supplementToUpdate
  } = props;
  const [values, setValues] = React.useState(initialValues);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  // called each time props.supplementToUpdate to update change
  useEffect(() => {
    if (supplementToUpdate) {
      if (supplementToUpdate.id !== values.id) {
        setValues({ ...supplementToUpdate });
      }
    } else {
      if (values.id || values.supplementName) {
        setValues(initialValues);
      }
    }
  }, [props.supplementToUpdate]);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value.toString() });
  };
  const saveSupplement = async () => {
    if (supplementToUpdate) {
      onUpdateSupplement({
        ...values,
        id: +supplementToUpdate.id
      });
    } else {
      onNewSupplement({ ...values } as {
        supplementName: string;
        supplementPrice: string;
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen} fullWidth>
      <Grid
        direction="row"
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
        container
        id="alert-dialog-title"
      >
        <DialogTitle>
          {!supplementToUpdate
            ? 'Ajouter un supplément'
            : 'Modifier un supplément'}
        </DialogTitle>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
          aria-label="fermer"
        >
          <ClearIcon />
        </IconButton>
      </Grid>
      <DialogContent className={classes.root}>
        <form>
          <TextField
            id="outlined-name"
            fullWidth
            variant="outlined"
            label="Nom du supplément"
            value={values.supplementName}
            onChange={handleChange('supplementName')}
            className={classes.textField}
            required
          />
          <TextField
            id="outlined-name"
            fullWidth
            variant="outlined"
            label="Prix du supplément"
            value={values.supplementPrice}
            onChange={handleChange('supplementPrice')}
            className={classes.textField}
            InputProps={{ inputProps: { min: 0 } }}
            required
            type="number"
          />
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActionPadding}>
        <div className="margin">
          <Button
            type="submit"
            className={classes.textButton}
            variant="contained"
            color="secondary"
            onClick={saveSupplement}
          >
            {!supplementToUpdate ? 'Ajouter' : 'Sauvegarder'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(DialogAddSupplement);
