import React from 'react';
import useStyles from './styles';
import { TextField, FormControl } from '@material-ui/core';

const FilterDate = (props: {
  label: string;
  value: string;
  handleStateChange: (value: string) => void;
}) => {
  const classes = useStyles({});
  const { label, value, handleStateChange } = props;

  const handleFilterStateChange = (e: any) => {
    handleStateChange && handleStateChange(e.target.value);
  };
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <TextField
        id="filter-date"
        label={label}
        type="date"
        defaultValue={value}
        className={classes.textField}
        InputLabelProps={{
          shrink: true
        }}
        margin="dense"
        variant="outlined"
        onChange={handleFilterStateChange}
      />
    </FormControl>
  );
};

export default FilterDate;
