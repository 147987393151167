import { Theme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

export default (theme: Theme) => ({
  root: {
    alignContent: 'center',
    color: theme.customPalette.text.white,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center'
  },
  form: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center'
  },
  margin: {
    marginBottom: 30
  },
  centerText: {
    textAlign: 'center' as 'center'
  },
  btnSave: {
    marginTop: 60,
    fontSize: '13px',
    color: theme.customPalette.text.white,
    backgroundColor: theme.customPalette.secondary.green,
    textTransform: 'none' as 'none',
    minHeight: 48,
    minWidth: 175,
    fontWeight: 400
  },
  snack: {
    backgroundColor: red[600]
  },
  forgotPassword: {
    color: theme.customPalette.secondary.main,
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    lineHeight: 3,
    verticalAlign: 'middle'
  },
  rememberMe: {
    fontSize: '13px'
  },
  radio: {
    color: `${theme.customPalette.secondary.green} !important`
  },
  // for white textfields
  cssLabel: {
    color: `#fcfcfc !important`
  },
  cssOutlinedInput: {
    color: '#fcfcfc !important',
    '&$cssFocused $notchedOutline': {
      borderColor: `#fcfcfc !important`,
      color: `#fcfcfc !important`
    }
  },
  cssFocused: {},
  notchedOutline: {
    borderColor: `#fcfcfc !important`
  }
});
