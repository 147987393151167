import gql from 'graphql-tag';
import { SETTING_FRAGMENT } from './fragment';

const SET_SETTINGS = gql`
  mutation SET_SETTING($input: [SettingInput]) {
    setSettings(input: $input) {
      ...settingInfos
    }
  }
  ${SETTING_FRAGMENT}
`;
export { SET_SETTINGS };
