export const isEmailValid = (email: string): boolean => {
  const regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  return email !== undefined && regexEmail.test(email.trim());
};

export const isValidPassword = (password: string): boolean => {
  return !!password.trim().length;
};

export const isValidUsername = (username: string): boolean => {
  return !!username.trim().length;
};

export const isNullOrEmpty = (value: any): boolean => {
  return value === null || value.length === 0;
};
export const isNullOrEmptyList = (value: any): boolean => {
  return value === null || value.length === 0;
};
