import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { ADD_PRODUCT_TO_SUBCATEGORY } from '../../graphql/product/mutation';
import { GET_ALL_PRODUCT } from '../../graphql/product/query';
import { GET_ALL_TARIF } from '../../graphql/tarif/query';
import { NotificationContainer } from 'react-notifications';
import ClearIcon from '@material-ui/icons/Clear';
import ICloudinaryUploadResult from '../../Interface/ICloudinaryUploadResult';
import ITarif from '../../Interface/ITarif';
import { DataProxy } from 'apollo-cache';
import {
  Button,
  TextField,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import { ProductAvailabilityEnum } from '../../Interface/ProductAvailabilityEnum';
import classNames from 'classnames';
import ImageWithUpload from '../../Common/cloudinary/ImageWithUpload';
const useStyles = makeStyles(theme => ({
  input: {
    display: 'none'
  },
  closeButton: {
    color: theme.customPalette.text.white,
    paddingRight: 16
  },
  textButton: {
    color: theme.customPalette.text.white
  },
  textField: {
    marginTop: 20
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  button: {
    margin: '8px 0 16px'
  },
  none: {
    display: 'none'
  },
  dialogActionPadding: {
    padding: '0 12px'
  },
  availabilityFields: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&>legend': {
      marginLeft: 10
    }
  }
}));
interface ITarifState {
  getAllTarifOfProduct: ITarif[];
}
export interface IProductProps {
  open: boolean;
  titleCateg: string;
  handleClose: (message: String) => void;
  handleError: (message: String) => void;
}
const handleUpdateCacheAfterAddTarif = (
  product: String,
  subCategory: String
) => (cache: DataProxy, mutationResult: any) => {
  const { getAllTarifOfProduct } = cache.readQuery({
    query: GET_ALL_TARIF,
    variables: {
      product: mutationResult.data.addTarif.product
    }
  });
  cache.writeQuery({
    query: GET_ALL_TARIF,
    variables: {
      product: mutationResult.data.addTarif.product
    },
    data: {
      getAllTarifOfProduct: getAllTarifOfProduct.concat([
        mutationResult.data.addTarif
      ])
    }
  });
};

const handleUpdateCacheAfterAdd = (subCategory: string) => (
  cache: DataProxy,
  mutationResult: any
) => {
  const productData = cache.readQuery<{ subCategory: any }>({
    query: GET_ALL_PRODUCT,
    variables: {
      id: subCategory
    }
  });

  if (
    productData &&
    productData.subCategory &&
    mutationResult &&
    mutationResult.data
  ) {
    const newProduct = mutationResult.data.addProduct;

    productData.subCategory.products = [
      ...productData.subCategory.products,
      newProduct
    ];
    cache.writeQuery({
      query: GET_ALL_PRODUCT,
      variables: {
        id: subCategory
      },
      data: productData
    });
  }
};

const ProductPage = (props: RouteComponentProps & IProductProps) => {
  const { titleCateg } = props;
  const idSubCategory = props.history.location.state.subCategory;
  const [nom, setNom] = useState('');
  const [description, setDescription] = useState('');
  const [timeToprepare, setTimeToprepare] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [urlPhoto, setUrlPhoto] = useState<string>(null);
  const [prixP, setPrixP] = useState('');
  const [prixM, setPrixM] = useState('');
  const [prixG, setPrixG] = useState('');
  const [prixMaxi, setPrixMaxi] = useState('');
  const [currentProduct, setCurrentProduct] = useState('');
  const [isUploading, setUploading] = useState(false);
  const [availability, setAvailability] = useState(
    ProductAvailabilityEnum.ALWAYS
  );
  const { data, loading, error } = useQuery<ITarifState>(GET_ALL_TARIF, {
    variables: {
      product: currentProduct
    },
    fetchPolicy: 'network-only'
  });
  const addProduct = useMutation(ADD_PRODUCT_TO_SUBCATEGORY, {
    update: handleUpdateCacheAfterAdd(idSubCategory)
  });

  const handleClear = () => {
    setDescription('');
    setNom('');
    setPrixP('');
    setPrixG('');
    setPrixM('');
    setPrixMaxi('');
    setIsActive(false);
    setTimeToprepare('');
    setUrlPhoto(null);
  };

  // const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const { open, handleClose, handleError } = props;
  const {
    location: { pathname }
  } = props;
  const onClose = (message: String) => {
    handleClose(message);
    handleClear();
  };
  const addProductFunction = async () => {
    try {
      await addProduct({
        variables: {
          productName: nom,
          productDescritpion: description,
          isActivate: isActive,
          timeToPrepare: timeToprepare,
          typeProduct: nom,
          subCategoryId: idSubCategory,
          urlPhoto: urlPhoto,
          tarif1: prixP,
          tarif2: prixM,
          tarif3: prixG,
          tarif4: prixMaxi,
          availability
        }
      }).then(
        valeur => {
          onClose('Enregistrement avec succès');
        },
        raison => {
          handleError("Erreur lors de l'ajout, il y a des champs obligatoire");
        }
      );
    } catch (error) {
      handleError("Erreur lors de l'ajout, il y a des champs obligatoire");
    }
  };
  const handleSubmit = async () => {
    addProductFunction();
  };
  const generatePrix = () => {
    if (titleCateg === 'pizzas') {
      return (
        <Grid
          container
          className={classes.textField}
          direction="row"
          justify="space-between"
          wrap="nowrap"
          spacing={3}
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-name"
              fullWidth
              variant="outlined"
              label="Prix P"
              placeholder="Prix P"
              required
              type="number"
              value={prixP}
              onChange={e => setPrixP(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-name"
              fullWidth
              variant="outlined"
              label="Prix M"
              placeholder="Prix M"
              required
              type="number"
              value={prixM}
              onChange={e => setPrixM(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-name"
              fullWidth
              variant="outlined"
              label="Prix G"
              placeholder="Prix G"
              required
              type="number"
              value={prixG}
              onChange={e => setPrixG(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-name"
              fullWidth
              variant="outlined"
              label="Prix Mx"
              placeholder="Prix Mx"
              required
              type="number"
              value={prixMaxi}
              onChange={e => setPrixMaxi(e.target.value)}
            />
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          className={classes.textField}
          direction="row"
          justify="space-between"
          wrap="nowrap"
          spacing={3}
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-name"
              fullWidth
              variant="outlined"
              label="Prix"
              placeholder="Prix"
              required
              type="number"
              value={prixP}
              onChange={e => setPrixP(e.target.value)}
            />
          </Grid>
        </Grid>
      );
    }
  };
  const onUploadDone = (arg: ICloudinaryUploadResult) => {
    setUrlPhoto(arg.public_id);
  };
  const handleAvailabilityChange = (event: React.ChangeEvent<unknown>) => {
    setAvailability(
      ProductAvailabilityEnum[(event.target as HTMLInputElement).value]
    );
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose('');
      }}
      fullScreen={fullScreen}
      fullWidth
    >
      <Grid
        direction="row"
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
        container
        id="alert-dialog-title"
      >
        <DialogTitle>
          {titleCateg === 'pizzas'
            ? 'Ajouter une pizza'
            : 'Ajouter une boisson'}
        </DialogTitle>
        <IconButton
          className={classes.closeButton}
          onClick={() => onClose('')}
          aria-label="fermer"
        >
          <ClearIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            id="outlined-name"
            fullWidth
            variant="outlined"
            label={
              titleCateg === 'pizzas' ? 'Nom de la pizza' : 'Nom de la boisson'
            }
            className={classes.textField}
            required
            value={nom}
            onChange={e => setNom(e.target.value)}
          />

          <TextField
            id="outlined-dense-multiline"
            fullWidth
            variant="outlined"
            label={titleCateg === 'pizzas' ? 'Ingrédients' : 'Description'}
            className={classes.textField}
            required
            multiline
            rowsMax="4"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          {titleCateg === 'pizzas' ? (
            <TextField
              id="outlined-name"
              fullWidth
              variant="outlined"
              className={classes.textField}
              label="Temps de preparation"
              placeholder="Temps de preparation"
              required
              value={timeToprepare}
              onChange={e => setTimeToprepare(e.target.value)}
            />
          ) : null}
          {generatePrix()}
          {titleCateg === 'pizzas' ? (
            <Grid
              className={classNames(
                classes.textField,
                classes.availabilityFields
              )}
            >
              <FormLabel component="legend">Disponibilité</FormLabel>
              <RadioGroup
                aria-label="Disponibilité"
                name="availability"
                value={availability}
                onChange={handleAvailabilityChange}
                row={true}
              >
                {Object.values(ProductAvailabilityEnum).map(availability => (
                  <FormControlLabel
                    key={availability}
                    value={availability}
                    control={<Radio />}
                    label={
                      availability === 'ALWAYS' ? 'Tout le temps' : 'Week-end'
                    }
                  />
                ))}
              </RadioGroup>
            </Grid>
          ) : null}
          <Grid className={classes.textField}>
            <ImageWithUpload
              publicId={urlPhoto}
              onUploadDone={onUploadDone}
              onUploadStateChange={setUploading}
              edit={true}
              width={'150'}
              height={'150'}
              crop={'fill'}
            />
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActionPadding}>
        <div className="margin">
          <Button
            onClick={handleSubmit}
            disabled={isUploading}
            type="submit"
            className={classes.textButton}
            variant="contained"
            color="secondary"
          >
            Ajouter
          </Button>
        </div>
        <NotificationContainer />
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(ProductPage);
