import gql from 'graphql-tag';
import { FIDELITY_FRAGMENT } from './fragment';

const UPDATE_FIDELITY = gql`
  mutation updateFidelity($id: ID!, $status: String, $activated: Boolean) {
    updateFidelity(input: { id: $id, status: $status, activated: $activated }) {
      ...fidelityInfos
    }
  }
  ${FIDELITY_FRAGMENT}
`;

const UPDATE_FIDELITIES = gql`
  mutation UPDATE_FIDELITIES(
    $ids: [ID]!
    $typeOperation: FidetityOperationEnum!
  ) {
    updateFidelities(input: { fidelities: $ids, operation: $typeOperation }) {
      ...fidelityInfos
      user {
        ...userInfos
      }
    }
  }
  ${FIDELITY_FRAGMENT}
`;
export { UPDATE_FIDELITY, UPDATE_FIDELITIES };
