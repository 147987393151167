import React, { useState } from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import IOrder from '../../Interface/IOrder';
import ISubcategory from '../../Interface/ISubcategory';
import { GET_ALL_ORDER } from '../../graphql/order/query';
import { useQuery } from 'react-apollo-hooks';
import moment from 'moment';
import PopupDetail from './PopupDetail/PopupDetail';
import IDetail from '../../Interface/IDetail';
import IBonus from '../../Interface/IBonus';
import IUser from '../../Interface/IUser';
import useStyles from './styles';
import { GET_SETTINGS } from '../../graphql/setting/query';
import SettingKeyEnum from '../../Interface/SettingKeyEnum';
import ISetting from '../../Interface/ISetting';
import { Grid, Button } from '@material-ui/core';
import {
  SortDirection,
  stableSort,
  getSorting,
  exportToCSV
} from '../../Utilities/DatatableUtils';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FilterState from '../../Common/TableBodyElement/FiltersControl/FilterState';
import FilterDate from '../../Common/TableBodyElement/FiltersControl/FilterDate';
import SortableHeaderCell from '../../Common/TableBodyElement/SortableHeaderCell/SortableHeaderCell';
import { Formatter } from '../../Common/constant';

const TablePaginationActions = props => {
  const classes = useStyles({});
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const StyledTableCell = withStyles(theme => ({
  root: {
    borderBottom: '1px solid #F1F1F3'
  },
  head: {
    backgroundColor: '#43425D',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);
const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(2n)': {
      backgroundColor: '#F1F1F3'
    }
  }
}))(TableRow);
const filter = (
  orders: IOrder[],
  filterState: string,
  filterDateStart: string,
  filterDateEnd: string
) => {
  return orders.filter(order => {
    let keep = true;
    if (filterState) {
      keep = order.status === filterState;
    }
    if (keep && filterDateStart) {
      // shorten date : 2019-08-20T08:52:04.000Z  >  2019-08-20
      keep =
        order.orderTime.substr(0, order.orderTime.indexOf('T')) >=
        filterDateStart;
    }
    if (keep && filterDateEnd) {
      // shorten date : 2019-08-20T08:52:04.000Z  >  2019-08-20
      keep =
        order.orderTime.substr(0, order.orderTime.indexOf('T')) <=
        filterDateEnd;
    }
    return keep;
  });
};
interface IQuerySettingResult {
  settings: ISetting[];
}
export interface ICategoryFormulesProps {
  titleCateg?: string;
}
interface IOrderState {
  orders: [IOrder];
}
interface ISUbCategoryState {
  subCategory: ISubcategory;
}

enum ColumnIds {
  DATE = 'orderTime',
  CLIENT = 'user.email',
  TOTAL = 'subTotal',
  STATUS = 'status',
  PAID = 'paid',
  DELIVERY = 'deliveryMethod'
}

const TableListeCommande = (
  props: RouteComponentProps & ICategoryFormulesProps
) => {
  const { data, loading, error, refetch } = useQuery<IOrderState>(
    GET_ALL_ORDER,
    {
      fetchPolicy: 'network-only'
    }
  );
  // get settings
  const {
    data: dataSeetting,
    loading: loadinSeeting,
    error: errorSeeting
  } = useQuery<IQuerySettingResult>(GET_SETTINGS, {
    variables: {
      key: SettingKeyEnum.DELIVERY_PRICE
    }
  });
  let deliveryPrice = '0';
  if (dataSeetting.settings && dataSeetting.settings.length > 0) {
    deliveryPrice = dataSeetting.settings[0].value;
  }
  const classes = useStyles({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deliveryMethod, setDeliveryMethod] = useState('Sur place');
  const [details, setDetails] = useState<[IDetail]>(null);
  const [bonus, setBonus] = useState<[IBonus]>(null);
  const [user, setUser] = useState<IUser>(null);
  const [order, setOrder] = useState<IOrder>(null);
  const [statusOrder, setStatus] = useState('');
  const [paidOrder, setPaid] = useState('');
  const [id, setId] = useState('');

  // ordering & filtering
  const [orderBy, setOrderBy] = useState<ColumnIds | undefined>();
  const [filterState, setFilterState] = useState('');
  const [filterDateStart, setFilterDateStart] = useState('');
  const [filterDateEnd, setFilterDateEnd] = useState('');
  const [orderByDirection, setOrderByDirection] = useState<SortDirection>(
    'asc'
  );
  const handleSortClicked = (column: string, sortDirection: SortDirection) => {
    setOrderByDirection(sortDirection);
    setOrderBy(column as ColumnIds);
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  const handleSubmit = () => {
    refetch();
  };
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return (
      <div>
        <Typography align="left" className={classes.titleCatalogue}>
          Commande vide
        </Typography>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Button
            variant="contained"
            //className={classes.textButton}
            color="secondary"
            onClick={handleSubmit}
          >
            Actualiser
          </Button>
        </Grid>
      </div>
    );
  }
  if (!data || !data.orders) {
    return <div>Error</div>;
  }

  const handleExport = () => {
    exportToCSV(
      data.orders.map(order =>
        (({
          orderTime,
          user: { email },
          subTotal,
          status,
          paid,
          deliveryMethod
        }) => ({
          'DATE CMD': moment(orderTime).format('DD/MM/YYYY HH:mm'),
          CLIENT: email,
          TOTAL: Formatter.format(parseFloat(subTotal)),
          STATUT: status,
          PAYEE: paid,
          'A LIVRER': deliveryMethod
        }))(order)
      ),
      'ListeCommandes'
    );
  };

  const isCanDetailsOpen = Boolean(details && details.length);
  const resetData = () => {
    setDetails(null);
    setUser(null);
    setId('');
    setBonus(null);
    setStatus('');
    setPaid('');
    setOrder(null);
  };
  return (
    <div className={classes.roots}>
      <PopupDetail
        deliveryMethod={deliveryMethod}
        deliveryPrice={deliveryPrice}
        details={details}
        bonus={bonus}
        user={user}
        id={id}
        order={order}
        statusOrder={statusOrder}
        paidOrder={paidOrder}
        open={isCanDetailsOpen}
        titleCateg={''}
        handleClose={message => {
          resetData();
        }}
        handleError={message => {
          resetData();
        }}
      />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <div>
          <FilterState value={filterState} handleStateChange={setFilterState} />
          <FilterDate
            label="DATE DEB"
            value={filterDateStart}
            handleStateChange={setFilterDateStart}
          />
          <FilterDate
            label="DATE FIN"
            value={filterDateEnd}
            handleStateChange={setFilterDateEnd}
          />
        </div>
        <ButtonGroup variant="contained">
          <Button onClick={handleExport} className={classes.buttonPrimary}>
            Export Excel
          </Button>
          <Button onClick={handleSubmit} className={classes.buttonSecondary}>
            Actualiser
          </Button>
        </ButtonGroup>
      </Grid>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <SortableHeaderCell
              column={ColumnIds.DATE}
              label="DATE CMD"
              className={classes.minWidthThTitle}
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              handleSortClicked={handleSortClicked}
            />
            <SortableHeaderCell
              column={ColumnIds.CLIENT}
              label="CLIENT"
              className={classes.minWidthThClient}
              align="left"
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              handleSortClicked={handleSortClicked}
            />
            <SortableHeaderCell
              column={ColumnIds.TOTAL}
              label="TOTAL"
              className={classes.minWidthThTitle}
              align="left"
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              handleSortClicked={handleSortClicked}
            />
            <SortableHeaderCell
              column={ColumnIds.STATUS}
              label="STATUT"
              className={classes.minWidthThStatus}
              align="left"
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              handleSortClicked={handleSortClicked}
            />
            <SortableHeaderCell
              column={ColumnIds.PAID}
              label="PAYEE"
              className={classes.minWidthThLivrer}
              align="center"
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              handleSortClicked={handleSortClicked}
            />
            <SortableHeaderCell
              column={ColumnIds.DELIVERY}
              label="A LIVRER"
              className={classes.minWidthThLivrer}
              align="center"
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              handleSortClicked={handleSortClicked}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort<IOrder>(
            filter(data.orders, filterState, filterDateStart, filterDateEnd),
            getSorting<keyof IOrder>(orderByDirection, orderBy as keyof IOrder)
          )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(row => (
              <StyledTableRow
                key={row.id}
                onClick={() => {
                  setDetails(row.details);
                  setUser(row.user);
                  setId(row.id);
                  setDeliveryMethod(row.deliveryMethod);
                  setBonus(row.bonus);
                  setStatus(row.status);
                  setPaid(row.paid);
                  setOrder(row);
                }}
              >
                <StyledTableCell component="th" scope="row">
                  {moment(row.orderTime).format('DD/MM/YYYY HH:mm')}
                </StyledTableCell>
                <StyledTableCell align="left">{row.user.email}</StyledTableCell>
                <StyledTableCell align="left">
                  {Formatter.format(parseFloat(row.subTotal))}
                </StyledTableCell>
                <StyledTableCell align="left">
                  <span
                    className={
                      classes.roundVertStatus ||
                      classes.roundOrangeStatus ||
                      classes.roundRedStatus ||
                      classes.roundGreyStatus
                    }
                  />
                  {row.status}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.paid === 'oui' && (
                    <div
                      style={{
                        display: 'visible'
                      }}
                      className={classes.roundVertLivrer}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    style={{
                      display:
                        row.deliveryMethod === 'A domicile' ? 'visible' : 'none'
                    }}
                    className={classes.roundVertLivrer}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 40]}
              colSpan={12}
              count={data.orders.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'Lignes par page' },
                native: true
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              className={classes.rootPagination}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

export default withRouter(TableListeCommande);
