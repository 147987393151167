import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import PizzaSizeEnum from '../../../Interface/PizzaSizeEnum';
import IProduct from '../../../Interface/IProduct';
import IFormule from '../../../Interface/IFormule';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '12px 24px'
  },
  closeButton: {
    color: theme.customPalette.text.white,
    paddingRight: 16
  },
  textButton: {
    color: theme.customPalette.text.white
  },
  textField: {
    marginTop: 20
  },
  titleChamp: {
    fontSize: 16,
    margin: '32px 0 16px'
  },
  button: {
    margin: '8px 0 16px'
  },
  dialogActionPadding: {
    padding: '20px 12px 4px'
  }
}));

export interface IFormuleProps {
  open: boolean;
  boissons: IProduct[];
  formuleToUpdate?: IFormule;
  onClose?: () => void;
  onUpdateFormule: (newFormule: IFormule) => void;
  onNewFormule: (newFormule: {
    name: string;
    pizzaQty: number;
    pizzaSize: PizzaSizeEnum;
    drinkQty: number;
    drink: number;
  }) => void;
}

const initialValues = {
  id: undefined,
  name: '',
  pizzaQty: 1,
  pizzaSize: PizzaSizeEnum.MOYENNE,
  drinkQty: 1,
  drink: -1
};

const DialogAddFormule = (props: RouteComponentProps & IFormuleProps) => {
  const {
    open,
    onClose,
    boissons,
    onNewFormule,
    onUpdateFormule,
    formuleToUpdate
  } = props;
  const [values, setValues] = React.useState(initialValues);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  const saveFormule = () => {
    if (formuleToUpdate) {
      onUpdateFormule({
        ...values,
        id: +formuleToUpdate.id,
        drink: { id: `${values.drink}` } as IProduct
      });
    } else {
      onNewFormule(values as {
        name: string;
        pizzaQty: number;
        pizzaSize: PizzaSizeEnum;
        drinkQty: number;
        drink: number;
      });
    }
    onClose && onClose();
  };
  // on formuleToUpdate props change
  useEffect(() => {
    if (formuleToUpdate && formuleToUpdate.drink) {
      if (formuleToUpdate.id !== values.id) {
        setValues({ ...formuleToUpdate, drink: +formuleToUpdate.drink.id });
      }
    } else {
      setValues(initialValues);
    }
  }, [formuleToUpdate]);

  // on boissons props change
  useEffect(() => {
    if (values.drink === -1 && boissons && boissons[0]) {
      setValues({
        ...values,
        drink: +boissons[0].id
      });
    }
  }, [boissons]);
  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen} fullWidth>
      <Grid
        direction="row"
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
        container
        id="alert-dialog-title"
      >
        <DialogTitle>
          {!formuleToUpdate ? 'Ajouter une formule' : 'Modifier une formule'}
        </DialogTitle>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
          aria-label="fermer"
        >
          <ClearIcon />
        </IconButton>
      </Grid>
      <DialogContent className={classes.root}>
        <form>
          <TextField
            id="outlined-name"
            fullWidth
            variant="outlined"
            label="Nom de la formule"
            value={values.name}
            onChange={handleChange('name')}
            className={classes.textField}
            required
          />
          <Typography align="left" className={classes.titleChamp}>
            Champ pizza
          </Typography>
          <Grid
            container
            direction="row"
            justify="space-between"
            wrap="nowrap"
            spacing={3}
          >
            <Grid item xs={6}>
              <TextField
                id="outlined-name"
                fullWidth
                variant="outlined"
                label="Quantité pizza"
                value={values.pizzaQty}
                onChange={handleChange('pizzaQty')}
                InputProps={{ inputProps: { min: 1 } }}
                required
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-select-currency-native"
                select
                fullWidth
                label="Taille pizza"
                value={values.pizzaSize}
                onChange={handleChange('pizzaSize')}
                SelectProps={{
                  native: true
                }}
                variant="outlined"
              >
                {Object.values(PizzaSizeEnum).map(option => (
                  <option key={option} value={option}>
                    {`${option[0]}${option.substr(1).toLowerCase()}`}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Typography align="left" className={classes.titleChamp}>
            Champ boisson
          </Typography>
          <Grid
            container
            direction="row"
            justify="space-between"
            wrap="nowrap"
            spacing={3}
          >
            <Grid item xs={6}>
              <TextField
                id="outlined-name"
                fullWidth
                variant="outlined"
                label="Quantité boisson"
                value={values.drinkQty}
                onChange={handleChange('drinkQty')}
                InputProps={{ inputProps: { min: 1 } }}
                required
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-select-currency-native"
                select
                fullWidth
                label="Boissons"
                value={values.drink}
                onChange={handleChange('drink')}
                SelectProps={{
                  native: true
                }}
                variant="outlined"
              >
                {boissons.map(boisson => (
                  <option key={boisson.id} value={boisson.id}>
                    {boisson.productName}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActionPadding}>
        <div className="margin">
          <Button
            type="submit"
            className={classes.textButton}
            variant="contained"
            color="secondary"
            onClick={saveFormule}
          >
            {!formuleToUpdate ? 'Ajouter' : 'Sauvegarder'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(DialogAddFormule);
