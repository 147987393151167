import React from 'react';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  closeButton: {
    color: theme.customPalette.text.white,
    paddingRight: 16
  },
  textButton: {
    color: theme.customPalette.text.white
  },

  button: {
    margin: '8px 0 16px'
  },
  dialogActionPadding: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'center'
  }
}));

export interface IConfirmationDialogProps {
  title: string;
  classes: Record<'paper', string>;
  id: string;
  keepMounted: boolean;
  value: string;
  open: boolean;
  onConfirm: Function;
  onClose: (value?: string) => void;
}

export interface IConfirmationDialogState {}

const ConfirmationDialog = (props: IConfirmationDialogProps) => {
  const { onClose, value: valueProp, open, onConfirm, title } = props;
  const [value, setValue] = React.useState(valueProp);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  function handleCancel() {
    onClose();
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      fullWidth
      fullScreen={fullScreen}
    >
      <Grid
        direction="row"
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
        container
        id="alert-dialog-title"
      >
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          className={classes.closeButton}
          onClick={() => onClose()}
          aria-label="fermer"
        >
          <ClearIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        Etes-vous sûr de vouloir supprimer cet élément ?
      </DialogContent>

      <DialogActions className={classes.dialogActionPadding}>
        <Button
          onClick={() => onConfirm()}
          variant="contained"
          className={classes.textButton}
          color="secondary"
        >
          Oui
        </Button>
        <Button
          onClick={handleCancel}
          variant="contained"
          className={classes.textButton}
          color="primary"
        >
          Non
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
