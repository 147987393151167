import React from 'react';
import useStyles from './styles';
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem
} from '@material-ui/core';
import classNames from 'classnames';

const StatusList = ['Prête', 'En attente', 'Annulée', 'Livrée'];

const FilterState = (props: {
  value: string;
  handleStateChange: (value: string) => void;
}) => {
  const classes = useStyles({});
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  const { value, handleStateChange } = props;
  const handleFilterStateChange = (e: any) => {
    handleStateChange && handleStateChange(e.target.value);
  };
  return (
    <FormControl
      variant="outlined"
      className={classNames(classes.formControl, classes.formControlSelect)}
    >
      <InputLabel ref={inputLabel} htmlFor="filter-status">
        STATUT
      </InputLabel>
      <Select
        value={value}
        onChange={handleFilterStateChange}
        margin="dense"
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name="filter-status"
            id="filter-status"
            margin="dense"
          />
        }
      >
        <MenuItem value="">
          <em>Aucun</em>
        </MenuItem>
        {StatusList.map(status => (
          <MenuItem value={status}>{status}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterState;
