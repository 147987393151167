import * as React from 'react';
import { Image, Transformation } from 'cloudinary-react';
import config from '../../config';

interface ICloudinaryProps {
  className?: string;
  height?: string;
  width?: string;
  publicId: any;
  crop: string;
  radius?: string;
}

class CloudinaryImage extends React.PureComponent<ICloudinaryProps> {
  render() {
    const { width, height, publicId, crop, className, radius } = this.props;
    return (
      <Image
        cloudName={config.cloudinaryName}
        className={className}
        radius={radius}
        publicId={publicId}
        crop={crop}
        width={width}
        height={height}
        // dpr="auto"
        responsive
      />
    );
  }
}

export default CloudinaryImage;
