import React from 'react';
import { TableCellProps } from '@material-ui/core/TableCell';
import StyledTableCell from '../StyledTableCell/StyledTableCell';
import { TableSortLabel, Theme } from '@material-ui/core';
import { SortDirection } from '../../../Utilities/DatatableUtils';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  sortLabel: {
    color: '#fff !important',
    '&:hover': {
      color: `${theme.customPalette.secondary.main} !important`
    }
  },
  sortLabelActive: {
    color: `${theme.customPalette.secondary.main} !important`
  },
  sortLabelIcon: {
    color: `${theme.customPalette.secondary.main} !important`
  }
}));

const SortableHeaderCell = (
  props: {
    column: string;
    label: string;
    orderBy: string;
    orderByDirection: SortDirection;
    handleSortClicked: (column: string, sortDirection: SortDirection) => void;
  } & TableCellProps
) => {
  const createSortHandler = (column: string) => (event: any) => {
    const isAsc = orderBy === column && orderByDirection === 'asc';

    props.handleSortClicked &&
      props.handleSortClicked(column, isAsc ? 'desc' : 'asc');
  };

  const classes = useStyles({});
  const { column, label, orderBy, orderByDirection, ...tableCellProps } = props;
  return (
    <StyledTableCell
      sortDirection={orderBy === column ? orderByDirection : false}
      {...tableCellProps}
    >
      <TableSortLabel
        active={orderBy === column}
        direction={orderByDirection}
        onClick={createSortHandler(column)}
        classes={{
          root: classes.sortLabel,
          active: classes.sortLabelActive,
          icon: classes.sortLabelIcon
        }}
      >
        {label}
      </TableSortLabel>
    </StyledTableCell>
  );
};
export default SortableHeaderCell;
