import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import TableListeCommande from '../ListeCommandes/TableListCommande';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '24px 28px'
  },

  padding24: {
    padding: '30px 24px',
    borderBottom: '1px solid #CACED5'
  },
  titleCatalogue: {
    fontSize: 18,
    color: theme.customPalette.text.orange
  }
}));

export interface ICategoryFormulesProps {
  titleCateg: string;
}
const Formules = (props: RouteComponentProps & ICategoryFormulesProps) => {
  const { location, titleCateg } = props;
  const classes = useStyles();

  return (
    <>
      <div>
        <Typography align="left" className={classes.titleCatalogue}>
          Listes des commandes
        </Typography>
        <TableListeCommande />
      </div>
    </>
  );
};

export default withRouter(Formules);
