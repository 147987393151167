import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import SupplementsAdd from '../categorySupplements/Dialog/SupplementsAdd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { GET_SUPPLEMENTS } from '../../graphql/supplement/query';
import ISupplement from '../../Interface/ISupplement';
import { useQuery, useMutation } from 'react-apollo-hooks';
import ListSupplements from './ListSupplements';
import {
  ADD_SUPPLEMENT,
  DELETE_SUPPLEMENT,
  UPDATE_SUPPLEMENT
} from '../../graphql/supplement/mutation';
import IProduct from '../../Interface/IProduct';
import {
  NotificationContainer,
  NotificationManager
} from 'react-notifications';
import ConfirmationDialog from '../../Common/ConfirmationDialog';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '24px 28px'
  },
  col: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'column' as 'column',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto'
  },
  row: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row' as 'row',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto',
    '@media (max-width: 768px)': {
      flexDirection: 'column' as 'column'
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  fab: {
    color: theme.customPalette.text.white,
    textTransform: 'capitalize',
    backgroundColor: theme.customPalette.secondary.green,
    '&:hover': {
      backgroundColor: '#739a47'
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(2)
  },
  titleCatalogue: {
    fontSize: 18,
    color: theme.customPalette.text.orange
  },
  bordure: {
    border: '1px solid #CACED5',
    marginTop: 14
  },
  padding24: {
    padding: '30px 24px',
    borderBottom: '1px solid #CACED5'
  },
  iconMarge: {
    marginRight: 8
  },
  textField: {
    width: '100%',
    marginBottom: 24
  },
  bordTop: {
    borderTop: '1px solid #CACED5',
    marginTop: 16
  }
}));

interface IQuerySupplementsResult {
  supplements: ISupplement[];
}
interface IQueryBoissonsResult {
  category: {
    products: IProduct[];
  };
}

export interface ICategorySupplementsProps {
  titleCateg: string;
}
const Supplements = (
  props: RouteComponentProps & ICategorySupplementsProps
) => {
  const titleBoutonAdd = 'Ajouter un supplément';
  const classes = useStyles();

  const [open, setOpen] = useState(false); // to open add/edit dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // to open delete dialog
  const [stagedSupplement, setStagedSupplement] = useState<ISupplement>(); // to store the supplement to delete/edit

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setStagedSupplement(undefined);
    setOpenDeleteDialog(false);
  };

  const handleOnNewSupplement = (newSupplement: {
    name: string;
    supplementName: string;
    supplementPrice: string;
  }) => {
    const { supplementName, supplementPrice } = newSupplement;
    addSupplement({
      variables: {
        supplementName,
        supplementPrice
      }
    }).then(
      valeur => {
        handleClose();
        NotificationManager.success('Supplément ajouté avec succès');
      },
      raison => {
        NotificationManager.error("Erreur lors de l'ajout");
        console.log('ADD_SUPPLEMENT FAILED :', raison);
      }
    );
  };

  const handleOnUpdateSupplement = (newSupplement: ISupplement) => {
    const { id, supplementName, supplementPrice } = newSupplement;
    updateSupplement({
      variables: {
        id,
        supplementName,
        supplementPrice
      }
    }).then(
      valeur => {
        handleClose();
        NotificationManager.success('Supplément mofifié avec succès');
      },
      raison => {
        NotificationManager.error('Erreur lors de la modification');
        console.log('UPDATE_SUPPLEMENT FAILED :', raison);
      }
    );
  };

  const deleteStagedSupplement = () => {
    deleteSupplement({
      variables: {
        id: +stagedSupplement.id
      }
    }).then(
      valeur => {
        handleClose();
        NotificationManager.success('Supplément supprimé avec succès');
      },
      raison => {
        NotificationManager.error('Erreur lors de la suppression');
        console.log('DELETE_SUPPLEMENT FAILED :', raison);
      }
    );
  };

  const handleDeleteClicked = (id: number) => {
    setStagedSupplement({ id } as ISupplement);
    setOpenDeleteDialog(true);
  };

  const handleEditClicked = (supplement: ISupplement) => {
    setStagedSupplement(supplement);
    setOpen(true);
  };

  const addSupplement = useMutation(ADD_SUPPLEMENT, {
    update: (cache, { data }: { data: { addSupplement: ISupplement } }) => {
      const { addSupplement } = data;
      const { supplements } = cache.readQuery({ query: GET_SUPPLEMENTS });
      cache.writeQuery({
        query: GET_SUPPLEMENTS,
        data: {
          supplements: supplements.concat([addSupplement])
        }
      });
    }
  });
  const updateSupplement = useMutation(UPDATE_SUPPLEMENT, {
    update: (cache, { data }: { data: { updateSupplement: ISupplement } }) => {
      const { updateSupplement } = data;
      const { supplements } = cache.readQuery({ query: GET_SUPPLEMENTS });
      var foundIndex = supplements.findIndex(
        supplement => supplement.id === updateSupplement.id
      );
      supplements[foundIndex] = updateSupplement;
      cache.writeQuery({
        query: GET_SUPPLEMENTS,
        data: {
          supplements: [...supplements]
        }
      });
    }
  });
  const deleteSupplement = useMutation(DELETE_SUPPLEMENT, {
    update: (
      cache,
      {
        data
      }: {
        data: {
          deleteSupplement: {
            message: string;
            action: string;
            success: boolean;
          };
        };
      }
    ) => {
      const { deleteSupplement } = data;
      const { supplements } = cache.readQuery({ query: GET_SUPPLEMENTS });
      cache.writeQuery({
        query: GET_SUPPLEMENTS,
        data: {
          supplements: [
            ...supplements.filter(
              supplement => supplement.id !== deleteSupplement.message
            )
          ]
        }
      });
    }
  });
  const { data: dataQuerySupplements } = useQuery<IQuerySupplementsResult>(
    GET_SUPPLEMENTS
  );
  const listSupplements = dataQuerySupplements
    ? dataQuerySupplements.supplements
    : [];

  return (
    <>
      <NotificationContainer />
      <SupplementsAdd
        open={open}
        supplementToUpdate={stagedSupplement}
        onClose={handleClose}
        onNewSupplement={handleOnNewSupplement}
        onUpdateSupplement={handleOnUpdateSupplement}
      />
      <div>
        <Typography align="left" className={classes.titleCatalogue}>
          Catalogues : Suppléments
        </Typography>
        <Grid container className={classNames(classes.bordure)} spacing={0}>
          <Grid item justify="center" direction="column" xs={12}>
            <div className={classes.root}>
              <Button
                variant="contained"
                aria-label="Ajouter"
                size="large"
                className={classes.fab}
                onClick={handleClickOpen}
              >
                <AddIcon className={classes.iconMarge} />
                {titleBoutonAdd}
              </Button>
              {/* List */}
              <div className={classes.bordTop}>
                <ListSupplements
                  supplements={listSupplements}
                  onEditClicked={handleEditClicked}
                  onDeleteClicked={handleDeleteClicked}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* Deletion dialog */}
      <ConfirmationDialog
        title={'Supprimer un supplement'}
        id={'delete-dialog'}
        classes={{
          paper: classes.paper
        }}
        keepMounted
        open={openDeleteDialog}
        onConfirm={deleteStagedSupplement}
        onClose={handleClose}
        value={''}
      />
    </>
  );
};

export default withRouter(Supplements);
