import * as React from 'react';
import ISupplement from '../../../Interface/ISupplement';
import { List, ListItem, makeStyles } from '@material-ui/core';
import SupplementItem from './SupplementItem';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  listItem: {}
}));

interface IListSupplementsProps {
  supplements: ISupplement[];
  onDeleteClicked: (id: number) => void;
  onEditClicked: (supplement: ISupplement) => void;
}
const ListSupplements = (props: IListSupplementsProps) => {
  const { supplements, onDeleteClicked, onEditClicked } = props;
  const classes = useStyles();
  return (
    <List className={classes.root}>
      {supplements &&
        supplements.map(supplement => {
          return (
            <ListItem
              key={supplement.id}
              className={classes.listItem}
              disableGutters={true}
            >
              <SupplementItem
                supplement={supplement}
                onDeleteClicked={onDeleteClicked}
                onEditClicked={onEditClicked}
              />
            </ListItem>
          );
        })}
    </List>
  );
};

export default ListSupplements;
