import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    maxWidth: 250,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 20,
    borderRadius: '8px',
    outline: 'none'
  },
  margin: {
    margin: 2
  },
  buttonReset: {
    margin: 2,
    marginTop: 2 * 4,
    backgroundColor: theme.customPalette.secondary.green,
    color: 'white',
    fontSize: '12px',
    textTransform: 'none' as 'none'
  },
  info: {
    textAlign: 'justify' as 'justify',
    fontSize: '13px',
    fontStyle: 'italic'
  },
  error: {
    color: 'red'
  },
  success: {
    color: 'green',
    fontStyle: 'normal'
  }
});
