import config from '../config';
import axios, { AxiosRequestConfig } from 'axios';

const uploadCloudinary = async (file: any) => {
  const configuration = {
    method: 'post',
    url: `https://api.cloudinary.com/v1_1/${config.cloudinaryName}/upload`,
    data: new FormData()
  } as AxiosRequestConfig;

  // eslint-disable-next-line no-undef
  configuration.data.append('file', file);
  configuration.data.append('upload_preset', config.cloudinaryUploadPreset);
  const res = await axios(configuration);
  return {
    secure_url: res.data.secure_url,
    public_id: res.data.public_id
  };
};

export { uploadCloudinary };
