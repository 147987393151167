import gql from 'graphql-tag';

const GET_SUBCATEGORIES_BY_ID = gql`
  query category($id: ID!) {
    category(id: $id) {
      id
      subCategories {
        id
        isActivate
        subCategoryName
        urlPhoto
        typeSubCategory
      }
    }
  }
`;
export { GET_SUBCATEGORIES_BY_ID };
