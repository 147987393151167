import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import classNames from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Router from '../../Common/Router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListIcon from '@material-ui/icons/List';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import IconPizza from './../../Assets/Images/icon-pizza.png';
import IconPizzaActive from './../../Assets/Images/icon-pizza-active.png';
import IconBoisson from './../../Assets/Images/icon-boisson.png';
import IconBoissonActive from './../../Assets/Images/icon-boisson-active.png';
import IconFormule from './../../Assets/Images/icon-formule.png';
import IconFormuleActive from './../../Assets/Images/icon-formule-active.png';
import IconSupplement from './../../Assets/Images/icon-supplement.png';
import IconSupplementActive from './../../Assets/Images/icon-supplement-active.png';
import IconPerson from '@material-ui/icons/Person';
import IconMonetizationOn from '@material-ui/icons/MonetizationOn';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import gitfIcone from '../../Assets/Images/icon-gift.png';
import Constants from '../../defaults/Constants';

const drawerWidth = 260;

const styles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    // overflow: "hidden",
    position: 'relative',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center'
  },
  col: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'column' as 'column',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto'
  },
  row: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row' as 'row',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto',
    '@media (max-width: 768px)': {
      flexDirection: 'column' as 'column'
    }
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.customPalette.primary.main
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    margin: 14,
    backgroundColor: theme.customPalette.text.white,
    overflowX: 'auto'
  },
  title: {
    color: theme.customPalette.secondary.main,
    textAlign: 'left',
    padding: 24,
    fontSize: 20,
    fontWeight: 'bold',
    backgroundColor: 'rgba(0, 0, 0, 0.13)'
  },
  drawerNav: {
    backgroundColor: theme.customPalette.primary.main
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  contentLeft: {
    color: theme.customPalette.text.white
  },
  icon: {
    color: theme.customPalette.text.white
  },
  listItem: {
    minHeight: 57
  },
  margeIcon: {
    marginRight: 18
  },
  textCenter: {
    textAlign: 'center'
  },
  active: {
    color: theme.customPalette.text.orange,
    borderLeft: '3px solid #FF8F00',
    backgroundColor: 'rgba(255, 255, 255, 0.05)'
  },
  iconMenu: {
    margin: '0 18px 0 32px'
  },
  kdoCode: {
    marginLeft: 22
  }
}));

const handleDrawerToggle = (
  setMobileOpen: (isOpen: boolean) => void,
  isOpen: boolean
) => () => {
  setMobileOpen(!isOpen);
};

const handleExpandClick = (
  setExpanded: (expanded: boolean) => void,
  expanded: boolean
) => () => {
  setExpanded(!expanded);
};
const handleExpandClickRapport = (
  setExpandedRapport: (expandedRapport: boolean) => void,
  expandedRapport: boolean
) => () => {
  setExpandedRapport(!expandedRapport);
};
const Menus = (props: RouteComponentProps) => {
  const classes = styles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const {
    location: { pathname }
  } = props;
  const [expanded, setExpanded] = React.useState<boolean>(
    [
      '/pizzas',
      '/boissons',
      '/formules',
      '/listeCommandes',
      '/listeClients',
      '/campagnePush',
      '/chiffresDaffaires',
      '/parametres'
    ].includes(pathname)
  );
  const [expandedRapport, setExpandedRapport] = React.useState<boolean>(
    [
      '/pizzas',
      '/boissons',
      '/formules',
      '/listeCommandes',
      '/listeClients',
      '/campagnePush',
      '/chiffresDaffaires',
      '/parametres'
    ].includes(pathname)
  );
  const drawer = (
    <div>
      <Typography className={classes.title}>D'lyss Pizza</Typography>
      <div className={classes.contentLeft}>
        <List dense disablePadding>
          <ListItem
            className={classNames(
              classes.listItem,
              (pathname === '/listeCommandes' || pathname === '/')  && classes.active
            )}
            button
            onClick={() => {
              props.history.push('/listeCommandes');
              setExpanded(false);
              setExpandedRapport(false);
            }}
          >
            <ListIcon className={classes.margeIcon} />
            <ListItemText>Liste des commandes</ListItemText>
          </ListItem>
          <ListItem
            onClick={handleExpandClick(setExpanded, expanded)}
            className={classes.listItem}
            button
          >
            <ReceiptIcon className={classes.margeIcon} />
            <ListItemText>Catalogues</ListItemText>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              aria-expanded={expanded}
            >
              <ExpandMoreIcon className={classes.icon} />
            </IconButton>
          </ListItem>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List dense>
              <ListItem
                className={classNames(
                  classes.listItem,
                  pathname === '/pizzas' && classes.active
                )}
                button
                onClick={() => {
                  props.history.push({
                    pathname: '/pizzas',
                    state: { subCategory: Constants.SUBCATEGORIE_HORS_SERIE_ID }
                  });
                  setExpandedRapport(false);
                }}
              >
                {pathname === '/pizzas' ? (
                  <img
                    src={IconPizzaActive}
                    className={classes.iconMenu}
                    alt="Pizza"
                  />
                ) : (
                  <img
                    src={IconPizza}
                    className={classes.iconMenu}
                    alt="Pizza"
                  />
                )}
                <ListItemText> Pizzas</ListItemText>
              </ListItem>
              <ListItem
                className={classNames(
                  classes.listItem,
                  pathname === '/boissons' && classes.active
                )}
                button
                onClick={() => {
                  props.history.push('/boissons');
                  setExpandedRapport(false);
                }}
              >
                {pathname === '/boissons' ? (
                  <img
                    src={IconBoissonActive}
                    className={classes.iconMenu}
                    alt="Boisson"
                  />
                ) : (
                  <img
                    src={IconBoisson}
                    className={classes.iconMenu}
                    alt="Boisson"
                  />
                )}
                <ListItemText> Boissons</ListItemText>
              </ListItem>
              <ListItem
                className={classNames(
                  classes.listItem,
                  pathname === '/formules' && classes.active
                )}
                button
                onClick={() => {
                  props.history.push('/formules');
                  setExpandedRapport(false);
                }}
              >
                {pathname === '/formules' ? (
                  <img
                    src={IconFormuleActive}
                    className={classes.iconMenu}
                    alt="Formule"
                  />
                ) : (
                  <img
                    src={IconFormule}
                    className={classes.iconMenu}
                    alt="Formule"
                  />
                )}
                <ListItemText> Formules</ListItemText>
              </ListItem>
              <ListItem
                className={classNames(
                  classes.listItem,
                  pathname === '/supplements' && classes.active
                )}
                button
                onClick={() => {
                  props.history.push('/supplements');
                  setExpandedRapport(false);
                }}
              >
                {pathname === '/supplements' ? (
                  <img
                    src={IconSupplementActive}
                    className={classes.iconMenu}
                    alt="Supplements"
                  />
                ) : (
                  <img
                    src={IconSupplement}
                    className={classes.iconMenu}
                    alt="Supplements"
                  />
                )}
                <ListItemText> Suppléments</ListItemText>
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            className={classNames(
              classes.listItem,
              pathname === '/campagnePush' && classes.active
            )}
            button
            onClick={() => {
              props.history.push('/campagnePush');
              setExpanded(false);
              setExpandedRapport(false);
            }}
          >
            <EqualizerIcon className={classes.margeIcon} />
            <ListItemText>Campagne push</ListItemText>
          </ListItem>

          <ListItem
            className={classNames(
              classes.listItem,
              pathname === '/codeKdo' && classes.active
            )}
            button
            onClick={() => {
              props.history.push('/codeKdo');
              setExpanded(false);
              setExpandedRapport(false);
            }}
          >
            <img src={gitfIcone} width="20px" height="20px" />
            <ListItemText className={classes.kdoCode}>Code KDOs</ListItemText>
          </ListItem>

          <ListItem
            onClick={handleExpandClickRapport(
              setExpandedRapport,
              expandedRapport
            )}
            className={classes.listItem}
            button
          >
            <AssignmentIcon className={classes.margeIcon} />
            <ListItemText>Rapports</ListItemText>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandedRapport
              })}
              aria-expanded={expandedRapport}
            >
              <ExpandMoreIcon className={classes.icon} />
            </IconButton>
          </ListItem>
          <Collapse in={expandedRapport} timeout="auto" unmountOnExit>
            <List dense>
              <ListItem
                className={classNames(
                  classes.listItem,
                  pathname === '/listeClients' && classes.active
                )}
                button
                onClick={() => {
                  props.history.push('/listeClients');
                  setExpanded(false);
                }}
              >
                <IconPerson className={classes.iconMenu} />
                <ListItemText> Listes des clients</ListItemText>
              </ListItem>

              <ListItem
                className={classNames(
                  classes.listItem,
                  pathname === '/chiffresDaffaires' && classes.active
                )}
                button
                onClick={() => {
                  props.history.push('/chiffresDaffaires');
                  setExpanded(false);
                }}
              >
                <IconMonetizationOn className={classes.iconMenu} />
                <ListItemText> Chiffres d'affaires</ListItemText>
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            className={classNames(
              classes.listItem,
              pathname === '/parametres' && classes.active
            )}
            button
            onClick={() => {
              props.history.push('/parametres');
              setExpanded(false);
              setExpandedRapport(false);
            }}
          >
            <SettingsIcon className={classes.margeIcon} />
            <ListItemText>Paramètres</ListItemText>
          </ListItem>
          <ListItem
            className={classNames(classes.listItem)}
            button
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            <ExitToAppIcon className={classes.margeIcon} />
            <ListItemText>Déconnexion</ListItemText>
          </ListItem>
        </List>
      </div>
    </div>
  );
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden mdUp implementation="css">
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={handleDrawerToggle(setMobileOpen, mobileOpen)}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Hidden>
      <nav className={classes.drawer} aria-label="Mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle(setMobileOpen, mobileOpen)}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <Router />
      </main>
    </div>
  );
};

export default withRouter(Menus);
