import * as React from 'react';
import ReactFileReader from 'react-file-reader';
import ImageIcon from '@material-ui/icons/Image';
import CloudinaryImage from './CloudinaryImage';
import { uploadCloudinary } from '../../Services/CloudinaryService';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ICloudinaryUploadResult from '../../Interface/ICloudinaryUploadResult';

const styles = {
  rightIcon: {
    marginLeft: 10
  },
  imgBackground: {
    backgroundColor: '#dedede'
  },
  button: {
    margin: '8px 0 16px'
  }
};
interface ImageWithUploadProps {
  publicId: string;
  width?: string;
  height?: string;
  crop?: string;
  edit: boolean;
  onUploadDone?: (arg: ICloudinaryUploadResult) => void;
  onUploadStateChange?: (isUploadgin: boolean) => void;
  className?: any;
}
interface ImageWithUploadState {
  isUploading?: boolean;
}

class ImageWithUpload extends React.PureComponent<
  ImageWithUploadProps,
  ImageWithUploadState
> {
  constructor(props) {
    super(props);
    this.state = { isUploading: false };
    this.handleFile = this.handleFile.bind(this);
  }

  async handleFile(file: any) {
    const { onUploadDone, onUploadStateChange } = this.props;
    try {
      onUploadStateChange && onUploadStateChange(true);
      this.setState({ isUploading: true });
      const upload = await uploadCloudinary(file[0]);
      // Fire after upload
      onUploadDone && onUploadDone(upload);
    } catch (error) {
      console.error('Upload failed :', error);
    } finally {
      onUploadStateChange && onUploadStateChange(false);
      this.setState({ isUploading: false });
    }
  }
  render() {
    const { publicId, width, height, edit, className, crop } = this.props;

    const DeviceImage = publicId ? (
      <CloudinaryImage
        width={width}
        height={height}
        publicId={publicId}
        crop={crop || 'scale'}
        className={className}
      />
    ) : (
      <ImageIcon />
    );

    // Display only
    if (!edit) {
      return <div>{DeviceImage}</div>;
    }
    // If edit
    return (
      <div>
        <ReactFileReader multipleFiles={false} handleFiles={this.handleFile}>
          <Button variant="contained" color="default" style={styles.button}>
            Télécharger image
            <CloudUploadIcon style={styles.rightIcon} />
          </Button>
        </ReactFileReader>
        {this.state.isUploading ? <div>Uploading...</div> : null}
        <div
          style={{
            ...styles.imgBackground,
            width: parseInt(width),
            height: parseInt(height)
          }}
        >
          {DeviceImage}
        </div>
      </div>
    );
  }
}

export default ImageWithUpload;
