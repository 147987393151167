import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import React, { Component } from "react";
import { createMuiTheme } from "@material-ui/core";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./Style.css";

import Menus from "./Component/Menus/Menus";
import Authentication from "./Component/Authentication";
import Apropos from "./Component/Apropos/apropos";
import ProtectedRoute from "./Common/ProtectedRoute";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#242533"
    },
    secondary: {
      main: "#FF8F00"
    }
  },
  customPalette: {
    primary: {
      main: "#242533",
      pop: "#43425D",
      contrastText: "#FFF"
    },
    secondary: {
      main: "#FF8F00",
      green: "#8BC34A",
      contrastText: "#FFF"
    },
    text: {
      black: "#000000",
      blueNoir: "#4D4F5C",
      white: "#ffffff",
      orange: "#FF8F00",
      red: "#FF5252",
      blueGrey: "#8B959A"
    }
  },
  customTypography: {
    fontFamily: ['"Roboto"', "Arial", "sans-serif"]
  }
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/Apropos" component={Apropos} />
            <Route exact path="/login" component={Authentication} />
            <ProtectedRoute path="/" component={Menus} />
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    );
  }
}

export default App;
