import gql from 'graphql-tag';
const GET_ALL_TARIF = gql`
    query getAllTarifOfProduct($product: String!) {
        getAllTarifOfProduct(product:$product){
            tarifName
            tarifId
            tarifPrice
          }
    }
`;
export { GET_ALL_TARIF };