import React, { KeyboardEvent, useState } from 'react';
import classNames from 'classnames';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import {
  Typography,
  FormControlLabel,
  Grid,
  Checkbox
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';
import { MutationFn } from 'react-apollo';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApolloError } from 'apollo-boost';
import AuthService from '../../../Services/AuthService';
import RadioUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioChecked from '@material-ui/icons/RadioButtonChecked';
import styles from './styles';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import { isEmailValid, isValidPassword } from '../../../Utilities/Validator';
import { useMutation } from 'react-apollo-hooks';
import { LOGIN } from '../../../graphql/authentication/mutation';

interface AuthState {
  email: string;
  password: string;
  canSubmit: boolean;
  displayForgotPwd: boolean;
  isLoading: boolean;
  rememberMe: boolean;
}

const validateFields = (email: string, password: string) => {
  return isEmailValid(email) && isValidPassword(password);
};

const initialState: AuthState = {
  email: '',
  password: '',
  canSubmit: false,
  displayForgotPwd: false,
  rememberMe: false,
  isLoading: false
};

const SignIn = (props: WithStyles & RouteComponentProps) => {
  const [state, setState] = useState(initialState);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  console.log(state);

  // handlers
  const handleMailChange = (e: any) => {
    const email = (e && e.target && e.target.value) || '';
    const { password } = state;
    setState({ ...state, email, canSubmit: validateFields(email, password) });
  };
  const toggleDisplayForgotPassword = () => {
    setState({ ...state, displayForgotPwd: !state.displayForgotPwd });
  };

  const handlePwdChange = (e: any) => {
    const password = (e && e.target && e.target.value) || '';
    const { email } = state;
    setState({
      ...state,
      password,
      canSubmit: validateFields(email, password)
    });
  };

  const handleRememberMeChange = (e: any) => {
    const rememberMe = (e && e.target && e.target.value) || false;
    setState({
      ...state,
      rememberMe
    });
  };

  const handleSubmit = () => {
    const { email, password, isLoading } = state;
    if (isLoading) {
      return;
    }
    setState({ ...state, isLoading: true });
    doLogin({
      variables: {
        email,
        password
      }
    })
      .then((rep: { data: { login: string } }) => {
        if (rep && rep.data) {
          AuthService.setAccessToken(rep.data.login);
          window.location.reload(true);
        }
      })
      .catch(onError)
      .finally(() => setState({ ...state, isLoading: false }));
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const showSnack = (snackBarMessage: string) => {
    return setSnackBarMessage(snackBarMessage);
  };

  const onError = (error: ApolloError) => {
    const { graphQLErrors, networkError } = error;
    if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
      return showSnack(graphQLErrors[0].message);
    }
    if (networkError) {
      return showSnack('Erreur réseau: veuillez verifier votre connexion');
    }
    return showSnack('Une erreur est survenue');
  };

  // mutations
  const doLogin = useMutation(LOGIN);

  // render variables
  const { classes } = props;
  const { canSubmit, displayForgotPwd, isLoading, email, password } = state;
  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!snackBarMessage}
        autoHideDuration={5000}
        onClose={() => showSnack('')}
      >
        <SnackbarContent className={classes.snack} message={snackBarMessage} />
      </Snackbar>
      <Typography variant="h6" gutterBottom className={classes.centerText}>
        Connexion
      </Typography>
      <form
        className={classes.form}
        onKeyPress={canSubmit ? handleKeyPress : undefined}
      >
        <TextField
          label="Adresse mail"
          type="email"
          className={classes.margin}
          value={email}
          onChange={handleMailChange}
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused
            }
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline
            },
            inputMode: 'email'
          }}
          fullWidth
        />

        <TextField
          label="Mot de passe"
          type="password"
          value={password}
          onChange={handlePwdChange}
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused
            }
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline
            },
            inputMode: 'password'
          }}
          fullWidth
        />
        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              value="remember-me"
              onChange={handleRememberMeChange}
              control={
                <Checkbox
                  icon={<RadioUnchecked fontSize="small" />}
                  checkedIcon={<RadioChecked fontSize="small" />}
                  classes={{ root: classes.radio, checked: classes.radio }}
                />
              }
              label="Se souvenir de moi"
              classes={{
                label: classes.rememberMe
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <span
              onClick={toggleDisplayForgotPassword}
              className={classNames(classes.forgotPassword)}
            >
              Mot de passe oublié ?
            </span>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          className={classes.btnSave}
          size="medium"
          onClick={handleSubmit}
        >
          {isLoading ? 'Connexion...' : 'Se connecter'}
        </Button>
      </form>
      <ForgotPassword
        isOpen={displayForgotPwd}
        closeModal={toggleDisplayForgotPassword}
      />
    </div>
  );
};

export default withStyles(styles)(withRouter(SignIn));
