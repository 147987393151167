import React, { Fragment } from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { History } from 'history';
import { Button, Container, Link } from '@material-ui/core';

export interface IPizzaCategoryDetailCartProps {
  history: History;
}

export interface IPizzaCategoryDetailCartState {}

const PizzaCategoryDetailCart = (props: IPizzaCategoryDetailCartProps) => {
  // const classes = useStyles();
  return (
      <Container fixed>
        <Typography variant="h2" component="h3" gutterBottom>
            Cartes - Pizza viande
        </Typography>
      </Container>
  );
};

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     button: {
//       margin: theme.spacing(1),
//     },
//     input: {
//       display: 'none',
//     },
//   }),
// );
export default PizzaCategoryDetailCart;
