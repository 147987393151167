import React from 'react';
import ReactDOM from 'react-dom';
import './Style.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloClient, ApolloLink, HttpLink } from 'apollo-boost';
import { ApolloProvider } from 'react-apollo-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory';
import config from './config';
import AuthService from './Services/AuthService';
import { setContext } from 'apollo-link-context';
import { withClientState } from 'apollo-link-state';
import { defaults } from './apollo/defaults';
import { resolvers } from './apollo/resolvers';
import { typeDefs } from './apollo/typeDef';

const authLink = setContext((_: any, { headers }: { headers: any }) => {
  const token = AuthService.getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const stateLink = withClientState({ defaults, resolvers, typeDefs });

const httpLink = new HttpLink({
  uri: config.serverUri
});

const link = ApolloLink.from([stateLink, httpLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
