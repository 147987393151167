import gql from 'graphql-tag';
import { SETTING_FRAGMENT } from './fragment';

const GET_SETTINGS = gql`
  query SETTINGS($key: ID) {
    settings(key: $key) {
      ...settingInfos
    }
  }
  ${SETTING_FRAGMENT}
`;
export { GET_SETTINGS };
