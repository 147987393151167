import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import IFidelity from '../../../Interface/IFidelity';
import IUser from '../../../Interface/IUser';
import ISession from '../../../Interface/ISession';
import StyledTableCell from '../../../Common/TableBodyElement/StyledTableCell/StyledTableCell';
import CodeClientItem from './CodeClientItem';
import { Constants } from '../../../Common/constant';
const useStyles = makeStyles(theme => ({
  input: {
    display: 'none'
  },
  closeButton: {
    color: theme.customPalette.text.white,
    paddingRight: 16
  },
  textButton: {
    color: theme.customPalette.text.white
  },
  textField: {
    marginTop: 0
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  button: {
    margin: '8px 0 16px'
  },
  none: {
    display: 'none'
  },
  dialogActionPadding: {
    padding: '24px'
  },
  roots: {
    width: '100%',
    marginTop: 24,
    overflowX: 'auto'
  },
  row: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row' as 'row',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto',
    padding: '12px 0 2px'
  },

  titleCatalogue: {
    fontSize: 18,
    color: theme.customPalette.text.orange
  },

  titleNom: {
    fontSize: 16,
    minWidth: 85
  },
  nom: {
    fontSize: 16
  },
  bordure: {
    border: '1px solid #CACED5',
    marginTop: 20,
    marginBottom: 24
  },
  widthProduct: {
    width: 200,
    fontSize: 13
  },
  widthStatus: {
    width: 200,
    fontSize: 13
  },
  widthPrice: {
    width: 94,
    fontSize: 13
  }
}));

export interface ICamPagnePush {
  getAllSessionOfMail: [ISession];
}
export interface IProductProps {
  fidelities: [IFidelity];
  user: IUser;
  open: boolean;
  titleCateg: string;
  handleClose: (message: String) => void;
  handleError: (message: String) => void;
}

const PopupCodeClient = (props: RouteComponentProps & IProductProps) => {
  const { open, handleClose, fidelities, titleCateg, user } = props;
  const handleClear = () => {};
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();
  const onClose = (message: String) => {
    handleClose(message);
    handleClear();
  };

  if (!fidelities) {
    return null;
  }
  let fds = fidelities.filter(row => row.codeKdo);
  const generateNumber = (liste: any) => {
    let somme = 0;
    liste.map(l => {
      somme += parseInt(l.quantity);
    });

    return somme;
  };
  const generateSize = (fidelity: IFidelity) => {
    // SM: 'PETITE',
    // MD: 'MOYENNE',
    // LG: 'GRANDE',
    // XLG: 'MAXI'

    const petites = fidelity.fidelityDetails.filter(d => {
      return d.sizePizza === Constants.SM;
    });

    const numberPetite = generateNumber(petites);

    const grandes = fidelity.fidelityDetails.filter(d => {
      return d.sizePizza === Constants.LG;
    });
    const numberGrande = generateNumber(grandes);
    const maxi = fidelity.fidelityDetails.filter(d => {
      return d.sizePizza === Constants.XLG;
    });
    const numberMaxi = generateNumber(maxi);
    const moyennes = fidelity.fidelityDetails.filter(d => {
      return d.sizePizza === Constants.MD;
    });
    const numberMoyenne = generateNumber(moyennes);
    return `10(${numberPetite} P ,${numberMoyenne} M ,${numberGrande} G ,${numberMaxi} Mx )`;
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose('');
      }}
      fullScreen={fullScreen}
      fullWidth
    >
      <Grid
        direction='row'
        wrap='nowrap'
        justify='space-between'
        alignItems='center'
        container
        id='alert-dialog-title'
      >
        <DialogTitle>{'Liste des cadeaux'}</DialogTitle>
        <IconButton
          className={classes.closeButton}
          onClick={() => onClose('')}
          aria-label='fermer'
        >
          <ClearIcon />
        </IconButton>
      </Grid>

      <DialogContent>
        <div className={classes.row}>
          <Typography className={classes.titleNom}>Prénom :</Typography>
          <Typography className={classes.nom}>
            {user.firstName ? user.firstName : '-'}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleNom}>Nom :</Typography>
          <Typography className={classes.nom}>
            {user.lastName ? user.lastName : '-'}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleNom}>Email :</Typography>
          <Typography className={classes.nom}>
            {user.email ? user.email : '-'}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.titleNom}>Téléphone :</Typography>
          <Typography className={classes.nom}>
            {user.phone ? user.phone : '-'}
          </Typography>
        </div>
        <div className={classes.bordure}>
          {fds.map(row => (
            <Grid
              key={row.id}
              direction='row'
              wrap='nowrap'
              justify='space-between'
              alignItems='center'
              container
            >
              <StyledTableCell
                className={classes.widthPrice}
                component='th'
                scope='row'
              >
                {row.codeKdo}
              </StyledTableCell>
              <StyledTableCell align='left' className={classes.widthProduct}>
                {generateSize(row)}
              </StyledTableCell>
              <StyledTableCell
                className={classes.widthStatus}
                component='th'
                scope='row'
              >
                {`N'est pas encore utilisé`}
              </StyledTableCell>
              <CodeClientItem fidelity={row} />
            </Grid>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withRouter(PopupCodeClient);
