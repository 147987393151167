import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import ListeCommandes from '../Component/ListeCommandes/ListeCommandes';
import CategoryMenu from '../Component/CategoryMenu/CategoryMenu';
import PizzaCategoryDetailCart from '../Component/PizzaCategoryDetailCart/PizzaCategoryDetailCart';
import ProductPage from '../Component/Products/ProductPage';
import TarifPage from '../Component/Tarifs/TarifPage';
import TarifAdd from '../Component/Tarifs/TarifAdd';
import TarifEdit from '../Component/Tarifs/TarifEdit';
import CategoryBoissons from '../Component/categoryBoissons/CategoryBoissons';
import CategoryFormules from '../Component/categoryFormules/CategoryFormules';
import CategorySupplements from '../Component/categorySupplements/CategorySupplements';
import CampagnePush from '../Component/CampagnePush/CampagnePush';
import ListeClients from '../Component/Rapports/ListesDesClients';
import ChiffresDaffaires from '../Component/Rapports/ChiffresDaffaires/ChiffresDaffaires';
import Parametres from '../Component/Parametres/Parametres';
import ListKdoCodes from '../Component/KDOCode/ListKdoCodes';
const Router = () => {
  return (
    <Switch>
      <Route path="/" exact component={ListeCommandes} />
      <Route path="/listeCommandes" exact component={ListeCommandes} />
      <Route path="/pizzas" exact component={CategoryMenu} />
      <Route
        path="/pizzas/categ/:id"
        exact
        component={PizzaCategoryDetailCart}
      />
      <Route path="/products" exact component={ProductPage} />
      <Route path="/tarifs" exact component={TarifPage} />
      <Route path="/tarifs/add" exact component={TarifAdd} />
      <Route path="/tarifs/edit" exact component={TarifEdit} />
      <Route path="/boissons" exact component={CategoryBoissons} />
      <Route path="/formules" exact component={CategoryFormules} />
      <Route path="/supplements" exact component={CategorySupplements} />
      <Route path="/campagnePush" exact component={CampagnePush} />
      <Route path="/listeClients" exact component={ListeClients} />
      <Route path="/chiffresDaffaires" exact component={ChiffresDaffaires} />
      <Route path="/parametres" exact component={Parametres} />
      <Route path="/codeKdo" exact component={ListKdoCodes} />

      {/* <Route path='/employe-add' exact component={EmployeAdd} />
      <Route path='/employe-edit/:id' exact component={EmployeEdit} />
      <Route path='/file-add/:idEmploye' exact component={FileAttachmentAdd} /> */}
    </Switch>
  );
};

export default Router;
