import { Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const styles = (theme: Theme): Record<string, CSSProperties> => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    // overflow: "hidden",
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
    alignItems: 'center'
  },
  container: {
    // background: "#f9f9f9"
  },
  authSection: {
    width: '1024px',
    // margin: '64px auto',
    background: theme.customPalette.text.white,
    borderRadius: 5,
    flexWrap: 'initial',
    boxShadow: '0px 0px 36px 8px rgba(0, 0, 0, 0.1)',
    '@media (max-width: 1048px)': {
      width: '95%'
    },
    '@media (max-width: 756px)': {
      flexWrap: 'wrap' as 'wrap'
    }
  },
  rightSide: {
    background: theme.customPalette.primary.main,
    color: theme.customPalette.text.white,
    padding: 40,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    justifyContent: 'center',
    '@media (max-width: 756px)': {
      padding: '40px 40px 72px',
      borderTopRightRadius: 5,
      borderBottomRightRadius: 0
    }
  },
  logo: {
    marginTop: '12%',
    height: '60px',
    width: '100px',
    alignSelf: 'center'
  },
  welcomeText: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 32,
    '@media (max-width: 756px)': {
      // marginTop: "70%"
    }
  },
  descriptionText: {
    textAlign: 'center',
    marginLeft: 15,
    marginRight: 15,
    marginTop: '7%',
    fontSize: 13,
    fontWeight: 'lighter'
  },
  leftSide: {
    minHeight: 650,
    display: 'flex',
    justifyContent: 'center',
    color: theme.customPalette.text.black,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    '@media (max-width: 756px)': {
      minHeight: 500,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 5
    }
  },
  authChoice: {
    marginBottom: '32%',
    '@media (max-width: 756px)': {
      marginBottom: '14%'
    }
  },
  navigation: {
    color: theme.customPalette.text.black,
    opacity: 0.3,
    marginRight: '10px',
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: 22
  },
  selectedNavigation: {
    color: theme.customPalette.primary.main,
    opacity: 1
  },
  separator: {
    color: theme.palette.divider,
    marginRight: 10,
    fontSize: 22
  }
});
