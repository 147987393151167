import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Checkbox } from '@material-ui/core';
import { TableRow } from '@material-ui/core';

import TableCell from '@material-ui/core/TableCell';

import IFidelity from '../../../Interface/IFidelity';

import moment from 'moment';
import useStyles from '../styles';
import Constants from '../../../defaults/Constants';

const StyledTableCell = withStyles(theme => ({
  root: {
    borderBottom: '1px solid #F1F1F3'
  },
  head: {
    backgroundColor: '#43425D',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(2n)': {
      backgroundColor: '#F1F1F3'
    }
  }
}))(TableRow);

interface IPropsTableKdoCodesRowItem {
  checkeds: any[];
  handleCheckeds: (id: number) => void;
  kdo: IFidelity;
}
const TableKdoCodesRowItem = (props: IPropsTableKdoCodesRowItem) => {
  const { checkeds, handleCheckeds, kdo } = props;
  const classes = useStyles({});

  return (
    <StyledTableRow>
      <StyledTableCell component="th" scope="row">
        <Checkbox
          checked={checkeds.includes(kdo.id)}
          onChange={() => handleCheckeds(kdo.id)}
          value="checkedA"
          inputProps={{
            'aria-label': 'primary checkbox'
          }}
        />
      </StyledTableCell>

      <StyledTableCell align="left">
        {kdo.validity ? moment(kdo.validity).format('DD/MM/YYYY HH:mm') : '-'}
      </StyledTableCell>
      <StyledTableCell align="left">{kdo.sizeKdo}</StyledTableCell>
      <StyledTableCell align="left">
        {kdo.status === Constants.FIDELITY_ALREADY_USED && (
          <span className={classes.roundRedStatus} />
        )}

        {kdo.codeKdo}
      </StyledTableCell>
      <StyledTableCell align="left">
        {kdo.canceled ? 'Annulé' : kdo.activated ? 'activé' : 'désactivé'}
      </StyledTableCell>
      <StyledTableCell align="left">
        {kdo.user.lastName ? kdo.user.lastName : '-'}
      </StyledTableCell>
      <StyledTableCell align="left">
        {kdo.user.firstName ? kdo.user.firstName : '-'}
      </StyledTableCell>
      <StyledTableCell align="left">
        {kdo.user.phone ? kdo.user.phone : '-'}
      </StyledTableCell>
      <StyledTableCell align="left">{kdo.user.email}</StyledTableCell>
    </StyledTableRow>
  );
};

export default TableKdoCodesRowItem;
