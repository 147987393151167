import gql from 'graphql-tag';
const ADD_TARIF = gql`
mutation addTarif(
    $tarifName: String!,
    $tarifPrice: String!,
    $urlPhoto: String!,
    $product:String!,
    $typeTarif: String!
    ) {
    addTarif(
        tarifName: $tarifName
        tarifPrice: $tarifPrice
        urlPhoto: $urlPhoto
        product:$product
        typeTarif: $typeTarif
       )
        {
            tarifName
            tarifId
            tarifPrice
            product
       }
    }
`;
const REMOVE_TARIF = gql`
mutation deleteTarif(
    $tarifId: String!
    ) {
        deleteTarif(
            tarifId: $tarifId
        ) {
            success
        }
  }
  `;
const UPDATE_TARIF = gql`
  mutation updateTarif(
      $tarifId: String!,
      $tarifName: String!,
      $tarifPrice: String!,
      $urlPhoto: String!
      $product: String!
      $typeTarif: String!
      ) {
        updateTarif(
            tarifId: $tarifId
            tarifName: $tarifName
            tarifPrice: $tarifPrice
            urlPhoto: $urlPhoto
            product: $product
            typeTarif:$typeTarif
         )
          {
            tarifName
            tarifId
            tarifPrice
         }
      }
  `;
export { ADD_TARIF,REMOVE_TARIF,UPDATE_TARIF };