import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useQuery, useMutation } from 'react-apollo-hooks';
import { History } from 'history';

import { GET_ALL_TARIF } from '../../graphql/tarif/query';
import { REMOVE_TARIF } from '../../graphql/tarif/mutation';
import {
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import ConfirmationDialog from '../../Common/ConfirmationDialog';
import TarifItem from './item/TarifItem';
import ITarif from '../../Interface/ITarif';
import IProduct from '../../Interface/IProduct';

export interface IProductProps {
  idSubCategory: string;
  history: History;
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

interface ITarifState {
  getAllTarifOfProduct: ITarif[];
}

const ProductPage = (props: IProductProps) => {
  const classes = useStyles();

  const [idProduct, setIdProduct] = useState(
    props.history.location.state.product.productId
  );
  const [idTarif, setIdTarif] = useState(
    props.history.location.state.product.productId
  );
  const { data, loading, error } = useQuery<ITarifState>(GET_ALL_TARIF, {
    variables: {
      product: idProduct
    },
    fetchPolicy: 'network-only'
  });
  const deleteTarif = useMutation(REMOVE_TARIF);
  const [open, setOpen] = React.useState(false);
  // const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const [value, setValue] = React.useState('Dione');

  function handleClickOpen(id: string) {
    setOpen(true);

    setIdTarif(id);
  }
  function handleClickEdit(tarif: ITarif, product: IProduct) {
    props.history.push({
      pathname: '/tarifs/edit',
      state: { product: product, tarif: tarif }
    });
  }
  const handleClose = (newValue?: string) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };
  const handleRemove = () => {
    setOpen(false);

    deleteTarif({
      update: (cache, mutationResult: any) => {
        const { getAllTarifOfProduct } = cache.readQuery({
          query: GET_ALL_TARIF,
          variables: {
            product: props.history.location.state.product.productId
          }
        });

        cache.writeQuery({
          query: GET_ALL_TARIF,
          variables: {
            product: idProduct
          },
          data: {
            getAllTarifOfProduct: getAllTarifOfProduct.filter(
              i => i.tarifId !== idTarif
            )
          }
        });
      },
      variables: {
        tarifId: idTarif
      }
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error</div>;
  }
  if (!data || !data.getAllTarifOfProduct) {
    return <div>Error</div>;
  }

  let menuCateg = data.getAllTarifOfProduct.map((el: ITarif, key) => {
    return (
      <TarifItem
        key={key}
        product={props.history.location.state.product}
        tarif={el}
        handleClickOpen={handleClickOpen}
        handleCLickEdit={handleClickEdit}
      />
    );
  });

  return (
    <Fragment>
      <Typography align="center" variant="h2" component="h3" gutterBottom>
        Tarifs
      </Typography>

      <Button
        variant="contained"
        aria-label="Ajouter"
        color="primary"
        size="large"
        className={classes.fab}
        onClick={() =>
          props.history.push({
            pathname: '/tarifs/add',
            state: { product: props.history.location.state.product }
          })
        }
      >
        <AddIcon />
      </Button>

      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>TARIFS</TableCell>
              <TableCell>ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {menuCateg}

            <ConfirmationDialog
              title={'Supprimer un tarif'}
              classes={{
                paper: classes.paper
              }}
              onConfirm={handleRemove}
              id="ringtone-menu"
              keepMounted
              open={open}
              onClose={handleClose}
              value={value}
            />
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  fab: {
    margin: theme.spacing(2)
  },
  extendedIcon: {
    marginRight: theme.spacing(2)
  }
}));

export default ProductPage;
