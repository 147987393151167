import { gql } from 'apollo-boost';

export const FIDELITY_FRAGMENT = gql`
  fragment fidelityInfos on Fidelity {
    id
    createdAt
    validity
    status
    activated
    canceled
    codeKdo
    sizeKdo
  }

  fragment userInfos on User {
    id
    email
    firstName
    lastName
    phone
  }
`;


