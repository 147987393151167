import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { withRouter } from 'react-router-dom';
import useStyles from './styles';
import SortableHeaderCell from '../../Common/TableBodyElement/SortableHeaderCell/SortableHeaderCell';
import FilterAndEdit from './FilterAndEdit/FilterAndEdit';
import TableKdoCodesRowItem from './TableKdoCodesRowItem/TableKdoCodesRowItem';

import {
  SortDirection,
  stableSort,
  getSorting
} from '../../Utilities/DatatableUtils';

import { Checkbox } from '@material-ui/core';

import ConfirmationDialog from './Popup/Confirmation';

import { useQuery, useMutation } from 'react-apollo-hooks';
import { FIDELITIES } from '../../graphql/fidelity/query';
import { UPDATE_FIDELITIES } from '../../graphql/fidelity/mutation';
import Constants from '../../defaults/Constants';

import IFidelity from '../../Interface/IFidelity';

const StyledTableCell = withStyles(theme => ({
  root: {
    borderBottom: '1px solid #F1F1F3'
  },
  head: {
    backgroundColor: '#43425D',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);

enum ColumnIds {
  VALIDITY = 'validity',
  SIZE_KDO = 'sizeKdo',
  CODE_KDO = 'codeKdo',
  STATUS = 'activated',
  LASTNAME = 'user.lastName',
  FIRSTNAME = 'user.firstName',
  PHONE = 'user.phone',
  EMAIL = 'user.email'
}

const TableKdoCodes = props => {
  const classes = useStyles();

  const updateFidelities = useMutation(UPDATE_FIDELITIES);

  const [checkeds, setCheckeds] = useState([]);

  const [filterState, setFilterState] = useState('');

  const handleCheckeds = (id: number) => {
    let checkedsTemp = [...checkeds];

    if (!checkeds.includes(id)) {
      /**add id in array if it's not in */
      checkedsTemp.push(id);
    } else {
      /**rm id in array if not */
      checkedsTemp = checkeds.filter((el: any) => {
        return el !== id ? el : null;
      });
    }

    setCheckeds(checkedsTemp);
  };

  const handleCheckedAll = () => {
    if (checkeds.length) {
      /**reinit checkeds */
      setCheckeds([]);
    } else {
      let array = [];
      if (
        data &&
        data.fidelities.map((el: any) => {
          array.push(el.id);
        })
      )
        setCheckeds(array);
    }
  };

  /**return if all checkbox are checked */
  const allChecked = () => {
    if (data || data.fidelities) {
      return data.fidelities.length === checkeds.length;
    }

    return false;
  };

  /**popup confirmation */
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);

  const [titleConfirmation, setTitleConfirmation] = useState('');

  const [typeOfmodification, setTypeOfModification] = useState('');

  /**ordering */
  const [orderBy, setOrderBy] = useState<ColumnIds | undefined>();
  const [orderByDirection, setOrderByDirection] = useState<SortDirection>(
    'asc'
  );

  const handleSortClicked = (column: string, sortDirection: SortDirection) => {
    setOrderByDirection(sortDirection);
    setOrderBy(column as ColumnIds);
  };

  /**handleClickOpenConfirmation */
  const handleClickOpenConfirmation = (title, typeOfmodification) => {
    setTitleConfirmation(title);
    setOpenConfirmationPopup(true);
    setTypeOfModification(typeOfmodification);
  };

  /**
   * on confirmation popup dialog
   * type
   * 1 -> activate
   * 2 -> desactivate
   * 3 -> cancel
   * @param type
   */
  const onConfirmPopupDialog = (type: string) => {
    updateFidelities({
      variables: {
        ids: checkeds,
        typeOperation: type
      }
    });
  };

  /**get kdos */
  const { data, loading, error, refetch } = useQuery(FIDELITIES, {
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <Typography>Chargement</Typography>;
  }

  if (error) {
    //TODO render something
    return null;
  }

  if (!data || !data.fidelities) {
    //TODO render something
    return null;
  }

  /**filter by state */
  const filter = (fidelities: [], filterState: string) => {
    return fidelities.filter((fidelity: IFidelity) => {
      switch (filterState) {
        case Constants.FIDELITY_OPERATION.CANCEL.VALUE:
          return fidelity.canceled === true ? fidelity : null;

        case Constants.FIDELITY_OPERATION.ACTIVATE.VALUE:
          return fidelity.activated === true && fidelity.canceled === false
            ? fidelity
            : null;

        case Constants.FIDELITY_OPERATION.DESACTIVATE.VALUE:
          return fidelity.activated === false && fidelity.canceled === false
            ? fidelity
            : null;

        default:
          return fidelity;
      }
    });
  };

  return (
    <div className={classes.roots}>
      <FilterAndEdit
        filterState={filterState}
        setFilterState={setFilterState}
        handleClickOpenConfirmation={handleClickOpenConfirmation}
        refetch={refetch}
      />
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <Checkbox
                checked={allChecked()}
                onChange={() => handleCheckedAll()}
                value="checkedA"
                inputProps={{
                  'aria-label': 'primary checkbox'
                }}
              />
            </StyledTableCell>

            <SortableHeaderCell
              column={ColumnIds.VALIDITY}
              label="DATE DE VALIDITE"
              className={classes.minWidthThTitle}
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              handleSortClicked={handleSortClicked}
            />

            <SortableHeaderCell
              column={ColumnIds.SIZE_KDO}
              label="TAILLE KDO"
              className={classes.minWidthThTitle}
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              handleSortClicked={handleSortClicked}
            />

            <SortableHeaderCell
              column={ColumnIds.CODE_KDO}
              label="CODE KDO"
              className={classes.minWidthThTitle}
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              handleSortClicked={handleSortClicked}
            />

            <SortableHeaderCell
              column={ColumnIds.STATUS}
              label="STATUT"
              className={classes.minWidthThTitle}
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              handleSortClicked={handleSortClicked}
            />

            <SortableHeaderCell
              column={ColumnIds.LASTNAME}
              label="NOM"
              className={classes.minWidthThTitle}
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              handleSortClicked={handleSortClicked}
            />

            <SortableHeaderCell
              column={ColumnIds.FIRSTNAME}
              label="PRENOM"
              className={classes.minWidthThTitle}
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              handleSortClicked={handleSortClicked}
            />

            <SortableHeaderCell
              column={ColumnIds.PHONE}
              label="TELEPHONE"
              className={classes.minWidthThTitle}
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              handleSortClicked={handleSortClicked}
            />

            <SortableHeaderCell
              column={ColumnIds.EMAIL}
              label="EMAIL"
              className={classes.minWidthThTitle}
              orderBy={orderBy}
              orderByDirection={orderByDirection}
              handleSortClicked={handleSortClicked}
            />
            {/* 
<StyledTableCell
className={classes.minWidthThLivrer}
align="center"
>
EMAIL
</StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            stableSort<IFidelity>(
              filter(data.fidelities, filterState),
              getSorting<keyof IFidelity>(
                orderByDirection,
                orderBy as keyof IFidelity
              )
            ).map((kdo: IFidelity, index: number) => {
              return (
                <TableKdoCodesRowItem
                  checkeds={checkeds}
                  handleCheckeds={handleCheckeds}
                  kdo={kdo}
                />
              );
            })}

          <ConfirmationDialog
            title={titleConfirmation}
            classes={{
              paper: classes.paper
            }}
            id="ringtone-menu"
            keepMounted
            open={openConfirmationPopup}
            onConfirm={onConfirmPopupDialog}
            onClose={() => setOpenConfirmationPopup(false)}
            typeOfmodification={typeOfmodification}
          />
        </TableBody>

        <TableFooter />
      </Table>
    </div>
  );
};

export default withRouter(TableKdoCodes);
