import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from 'react-apollo-hooks';
import IDetail from '../../../Interface/IDetail';
import { GET_CA } from '../../../graphql/chiffreAffaire/query';
import moment from 'moment';
import { Formatter } from '../../../Common/constant';
import {
  TypeAnnuel,
  TypeJourMois,
  TypeMensuel
} from '../../../Common/constant';
import { Button, Paper } from '@material-ui/core';
import {
  SortDirection,
  stableSort,
  getSorting,
  exportToCSV
} from '../../../Utilities/DatatableUtils';
import SortableHeaderCell from '../../../Common/TableBodyElement/SortableHeaderCell/SortableHeaderCell';
import FilterDate from '../../../Common/TableBodyElement/FiltersControl/FilterDate';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const useStyles = makeStyles(theme => ({
  roots: {
    width: '100%',
    marginTop: 24,
    overflowX: 'auto'
  },
  row: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row' as 'row',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto',
    alignItems: 'flex-end' as 'flex-end',
    marginTop: 24
  },
  table: {
    minWidth: 700
  },
  minWidthThTitle: {
    minWidth: 124
  },
  minWidthThClient: {
    minWidth: 144
  },
  minWidthThStatus: {
    minWidth: 84
  },
  minWidthThLivrer: {
    minWidth: 100
  },
  minWidthThSupplement: {
    minWidth: 175
  },
  roundVertStatus: {
    backgroundColor: theme.customPalette.secondary.green,
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 12,
    display: 'inline-block'
  },
  roundOrangeStatus: {
    backgroundColor: theme.customPalette.secondary.main,
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 12,
    display: 'inline-block'
  },
  roundRedStatus: {
    backgroundColor: theme.customPalette.text.red,
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 12,
    display: 'inline-block'
  },
  roundGreyStatus: {
    backgroundColor: theme.customPalette.text.blueGrey,
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 12,
    display: 'inline-block'
  },
  roundVertLivrer: {
    backgroundColor: theme.customPalette.secondary.green,
    width: 15,
    height: 15,
    borderRadius: '50%',
    margin: '0 auto'
  },
  rootPagination: {
    borderBottom: 'none',
    padding: 0
  },
  titleCatalogue: {
    fontSize: 18,
    color: theme.customPalette.text.orange
  },

  cssOutlinedInput: {
    padding: '2px 8px',
    marginLeft: '18px'
  },
  textTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.customPalette.text.blueNoir
  },
  rowPrice: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row' as 'row',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto',
    alignItems: 'center' as 'center',
    justifyContent: 'flex-end' as 'flex-end',
    width: '100%'
  },
  prix: {
    fontSize: 20,
    fontWeight: 'bold',
    minWidth: 180,
    justifyContent: 'flex-end' as 'flex-end',
    display: 'flex',
    flex: '0 1 auto',
    borderBottom: '1px solid #43425D',
    marginLeft: 18,
    color: theme.customPalette.text.blueNoir
  },
  buttonPrimary: {
    color: '#fff',
    backgroundColor: theme.customPalette.secondary.green
  },
  buttonSecondary: {
    color: '#fff',
    backgroundColor: theme.customPalette.secondary.main
  },
  paper: {
    height: 400,
    width: '100%',
    overflow: 'auto'
  }
}));

const StyledTableCell = withStyles(theme => ({
  root: {
    borderBottom: '1px solid #F1F1F3'
  },
  head: {
    backgroundColor: '#43425D',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);
const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(2n)': {
      backgroundColor: '#F1F1F3'
    }
  }
}))(TableRow);

const filter = (
  details: IDetail[],
  filterDateStart: string,
  filterDateEnd: string
) => {
  return details.filter(detail => {
    let keep = true;
    if (keep && filterDateStart) {
      // shorten date : 2019-08-20T08:52:04.000Z  >  2019-08-20
      keep =
        detail.order.orderTime.substr(0, detail.order.orderTime.indexOf('T')) >=
        filterDateStart;
    }
    if (keep && filterDateEnd) {
      // shorten date : 2019-08-20T08:52:04.000Z  >  2019-08-20
      keep =
        detail.order.orderTime.substr(0, detail.order.orderTime.indexOf('T')) <=
        filterDateEnd;
    }
    return keep;
  });
};

export interface ICa {
  chiffreAffaires: [IDetail];
}
export interface ICategoryFormulesProps {
  titleCateg?: string;
}

enum ColumnIds {
  DATE = 'order.orderTime',
  VILLE = 'order.city',
  CONTENU = 'product.productName',
  TAILLE = 'size',
  QUANTITE = 'quantity',
  TOTAL = 'subTotal'
}

const TableChiffresDaffaires = (
  props: RouteComponentProps & ICategoryFormulesProps
) => {
  const classes = useStyles({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [debut, setDebut] = useState(null);

  const [fin, setFin] = useState(null);
  const todayDeb = new Date();
  todayDeb.setHours(0, 0, 1);
  const todayFin = new Date();
  todayFin.setHours(23, 59, 59);
  useEffect(() => {
    setDebut(todayDeb);
    setFin(todayFin);
  }, []);

  const [isMoisVisible, setIsMoisVisible] = useState(false);
  const [isAnneeVisible, setIsAnneeVisible] = useState(false);
  const { data, loading, error, refetch } = useQuery<ICa>(GET_CA, {
    variables: {
      debut,
      fin
    },
    fetchPolicy: 'network-only'
  });

  // ordering & filtering
  const [orderBy, setOrderBy] = useState<ColumnIds | undefined>();
  const [orderByDirection, setOrderByDirection] = useState<SortDirection>(
    'asc'
  );
  const [filterDateStart, setFilterDateStart] = useState('');
  const [filterDateEnd, setFilterDateEnd] = useState('');

  const handleSortClicked = (column: string, sortDirection: SortDirection) => {
    setOrderByDirection(sortDirection);
    setOrderBy(column as ColumnIds);
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  const [values, setValues] = React.useState({
    typeJourMois: 'Journalier',
    typeMensuel: 'Janvier',
    typeAnnuel: '2019'
  });
  const lastday = (y, m) => {
    return new Date(y, m + 1, 0).getDate();
  };
  const generateMounth = (mounth: any) => {
    switch (mounth) {
      case 'Janvier':
        return 0;
      case 'Fevrier':
        return 1;
      case 'Mars':
        return 2;
      case 'Avril':
        return 3;
      case 'Mai':
        return 4;
      case 'Juin':
        return 5;
      case 'Juillet':
        return 6;
      case 'Août':
        return 7;
      case 'Septembre':
        return 8;
      case 'Octobre':
        return 9;
      case 'Novembre':
        return 10;
      case 'Decembre':
        return 11;
      default:
        return 0;
    }
  };
  const generateMensuel = (mounth: string) => {
    const typeOfFilter = mounth; //event.target.value;
    var month = generateMounth(typeOfFilter);
    var d = new Date();
    d.setDate(1);
    d.setHours(0, 0, 1);
    d.setMonth(month);
    setDebut(d);

    var dFin = new Date();
    dFin.setDate(lastday(dFin.getFullYear(), month));
    dFin.setHours(23, 59, 59);
    dFin.setMonth(month);
    setFin(dFin);
  };
  const generateAnnuel = (year: any) => {
    const typeOfFilter = year; //event.target.value
    var d = new Date();
    d.setFullYear(typeOfFilter);
    d.setDate(1);
    d.setHours(0, 0, 1);
    d.setMonth(0);
    setDebut(d);
    var dFin = new Date();
    dFin.setFullYear(typeOfFilter);
    dFin.setDate(31);
    dFin.setHours(23, 59, 59);
    dFin.setMonth(11);
    setFin(dFin);
  };
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
    if (name === 'typeJourMois') {
      const typeOfFilter = event.target.value;
      switch (typeOfFilter) {
        case 'Journalier':
          setIsAnneeVisible(false);
          setIsMoisVisible(false);
          setDebut(todayDeb);
          setFin(todayFin);
          break;
        case 'Mensuel':
          setIsAnneeVisible(false);
          setIsMoisVisible(true);
          generateMensuel(values.typeMensuel);
          break;
        case 'Annuel':
          setIsAnneeVisible(true);
          setIsMoisVisible(false);
          generateAnnuel(values.typeAnnuel);
          break;
        default:
          setIsAnneeVisible(false);
          setIsMoisVisible(false);
          break;
      }
    } else if (name === 'typeMensuel') {
      generateMensuel(event.target.value);
    } else if (name === 'typeAnnuel') {
      generateAnnuel(event.target.value);
    }
  };
  const handleSubmit = () => {
    refetch();
  };
  const generateTotal = () => {
    let somme = 0;
    data.chiffreAffaires.map(ca => {
      somme += parseFloat(ca.subTotal);
    });
    return somme;
  };
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return (
      <div>
        <Typography align="left" className={classes.titleCatalogue}>
          Liste vide
        </Typography>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Button
            variant="contained"
            //className={classes.textButton}
            color="secondary"
            onClick={handleSubmit}
          >
            Actualiser
          </Button>
        </Grid>
      </div>
    );
  }
  if (!data || !data.chiffreAffaires) {
    return <div>Error</div>;
  }
  const handleExport = () => {
    exportToCSV(
      data.chiffreAffaires.map(detail =>
        (({
          order: { orderTime },
          order: { city },
          product: { productName },
          size,
          quantity,
          subTotal
        }) => ({
          DATE: moment(orderTime).format('DD/MM/YYYY HH:mm'),
          VILLE: city,
          CONTENU: productName,
          TAILLE: size,
          QUANTITE: quantity,
          TOTAL: Formatter.format(parseFloat(subTotal))
        }))(detail)
      ),
      "Chiffres d'affaires"
    );
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Typography align="left" className={classes.titleCatalogue}>
          Chiffres d'affaires
        </Typography>
        <Grid item id="selectTable">
          {isMoisVisible && (
            <TextField
              id="outlined-select-currency-native"
              select
              value={values.typeMensuel}
              onChange={handleChange('typeMensuel')}
              SelectProps={{
                native: true
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput
                }
              }}
              variant="outlined"
            >
              {TypeMensuel.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          )}
          {isAnneeVisible && (
            <TextField
              id="outlined-select-currency-native"
              select
              value={values.typeAnnuel}
              onChange={handleChange('typeAnnuel')}
              SelectProps={{
                native: true
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput
                }
              }}
              variant="outlined"
            >
              {TypeAnnuel.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          )}

          <TextField
            id="outlined-select-currency-native"
            select
            value={values.typeJourMois}
            onChange={handleChange('typeJourMois')}
            SelectProps={{
              native: true
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput
              }
            }}
            variant="outlined"
          >
            {TypeJourMois.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <div className={classes.roots}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <div>
            <FilterDate
              label="DATE DEB"
              value={filterDateStart}
              handleStateChange={setFilterDateStart}
            />
            <FilterDate
              label="DATE FIN"
              value={filterDateEnd}
              handleStateChange={setFilterDateEnd}
            />
          </div>
          <ButtonGroup variant="contained">
            <Button onClick={handleExport} className={classes.buttonPrimary}>
              Export Excel
            </Button>
            <Button onClick={handleSubmit} className={classes.buttonSecondary}>
              Actualiser
            </Button>
          </ButtonGroup>
        </Grid>
        <Paper className={classes.paper}>
          <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <SortableHeaderCell
                column={ColumnIds.DATE}
                label="DATE"
                className={classes.minWidthThTitle}
                orderBy={orderBy}
                orderByDirection={orderByDirection}
                handleSortClicked={handleSortClicked}
              />
              <SortableHeaderCell
                column={ColumnIds.VILLE}
                label="VILLE"
                align="left"
                className={classes.minWidthThTitle}
                orderBy={orderBy}
                orderByDirection={orderByDirection}
                handleSortClicked={handleSortClicked}
              />
              <SortableHeaderCell
                column={ColumnIds.CONTENU}
                label="CONTENU"
                align="left"
                className={classes.minWidthThSupplement}
                orderBy={orderBy}
                orderByDirection={orderByDirection}
                handleSortClicked={handleSortClicked}
              />
              <SortableHeaderCell
                column={ColumnIds.TAILLE}
                label="TAILLE"
                align="left"
                className={classes.minWidthThTitle}
                orderBy={orderBy}
                orderByDirection={orderByDirection}
                handleSortClicked={handleSortClicked}
              />
              <SortableHeaderCell
                column={ColumnIds.QUANTITE}
                label="QUANTITE"
                align="left"
                className={classes.minWidthThTitle}
                orderBy={orderBy}
                orderByDirection={orderByDirection}
                handleSortClicked={handleSortClicked}
              />
              <SortableHeaderCell
                column={ColumnIds.TOTAL}
                label="TOTAL"
                align="right"
                className={classes.minWidthThStatus}
                orderBy={orderBy}
                orderByDirection={orderByDirection}
                handleSortClicked={handleSortClicked}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort<IDetail>(
              filter(data.chiffreAffaires, filterDateStart, filterDateEnd),
              getSorting<keyof IDetail>(
                orderByDirection,
                orderBy as keyof IDetail
              )
            ).map(row => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {moment(row.order.orderTime).format('DD/MM/YYYY HH:mm')}
                </StyledTableCell>
                <StyledTableCell align="left">{row.order.city}</StyledTableCell>
                <StyledTableCell align="left">
                  {row.product.productName}
                </StyledTableCell>
                <StyledTableCell align="left">{row.size}</StyledTableCell>
                <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                <StyledTableCell align="right">
                  {Formatter.format(parseFloat(row.subTotal))}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        </Paper>
         <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item spacing={3}>
            <div className={classes.row}>
              <Typography className={classes.textTitle}>TOTAL</Typography>
              <Typography className={classes.prix}>
                {Formatter.format(generateTotal())}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withRouter(TableChiffresDaffaires);
