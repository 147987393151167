import React from 'react';
import useStyles from '../styles';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import FilterState from '../FilterState';
import Constants from '../../../defaults/Constants';
import classNames from 'classnames';
import { OperationVariables, ApolloQueryResult } from 'apollo-boost';

interface IPropsFilterAndEdit {
  filterState: string;
  setFilterState: React.Dispatch<React.SetStateAction<string>>;
  handleClickOpenConfirmation: (title: any, typeOfmodification: any) => void;
  refetch: (variables?: OperationVariables) => Promise<ApolloQueryResult<any>>;
}
const FilterAndEdit = (props: IPropsFilterAndEdit) => {
  const classes = useStyles({});

  const { filterState, setFilterState, handleClickOpenConfirmation, refetch } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FilterState value={filterState} setFilterState={setFilterState} />

        <Button onClick={() => refetch()} className={ classNames( classes.btn , classes.refresh )}>
          Actualiser
        </Button>
      </Grid>
      <Grid item xs={6} className={classes.edit}>
        <Button
          variant="contained"
          color="default"
          className={ classNames(classes.buttonSuccess, classes.btn) }
          onClick={() =>
            handleClickOpenConfirmation(
              'Activer les code kdos selectionnés?',
              Constants.FIDELITY_OPERATION.ACTIVATE.VALUE
            )
          }
        >
          Activer
        </Button>
        <Button
          variant="contained"
          className={classNames(classes.buttonSecondary, classes.btn)}
          color="secondary"
          onClick={() =>
            handleClickOpenConfirmation(
              'Désactiver les code kdos selectionnés?',
              Constants.FIDELITY_OPERATION.DESACTIVATE.VALUE
            )
          }
        >
          Désactiver
        </Button>
        <Button
          variant="contained"
          className={classNames(classes.buttonSecondary, classes.buttonDark)}
          color="secondary"
          onClick={() =>
            handleClickOpenConfirmation(
              'Annuler les code kdos selectionnés?',
              Constants.FIDELITY_OPERATION.CANCEL.VALUE
            )
          }
        >
          Annuler
        </Button>
      </Grid>
    </Grid>
  );
};

export default FilterAndEdit;