import gql from 'graphql-tag';

const GET_ALL_SESSION = gql`
  query {
    getAllSession {
      tokenFireBase
      tokenFireBase
      id
      email
      adresseMac
    }
  }
`;
const GET_ALL_SESSION_OF_MAIL = gql`
  query getAllSessionOfMail($email: String!) {
    getAllSessionOfMail(email: $email) {
      tokenFireBase
      id
      email
      adresseMac
    }
  }
`;
export { GET_ALL_SESSION, GET_ALL_SESSION_OF_MAIL };
