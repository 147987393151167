import gql from 'graphql-tag';
const ADD_PRODUCT_TO_SUBCATEGORY = gql`
  mutation addProduct(
    $productName: String!
    $urlPhoto: String!
    $subCategoryId: ID!
    $isActivate: Boolean
    $timeToPrepare: String!
    $productDescritpion: String!
    $typeProduct: String!
    $tarif1: String!
    $tarif2: String!
    $tarif3: String!
    $tarif4: String!
    $availability: ProductAvailabilityEnum
  ) {
    addProduct(
      input: {
        productName: $productName
        urlPhoto: $urlPhoto
        subCategoryId: $subCategoryId
        isActivate: $isActivate
        timeToPrepare: $timeToPrepare
        productDescritpion: $productDescritpion
        typeProduct: $typeProduct
        tarif1: $tarif1
        tarif2: $tarif2
        tarif3: $tarif3
        tarif4: $tarif4
        availability: $availability
      }
    ) {
      id
      productName
      timeToPrepare
      isActivate
      urlPhoto
      typeProduct
      tarif1
      tarif2
      tarif3
      tarif4
      productDescritpion
      availability
      buyableByKdoCode
    }
  }
`;
const REMOVE_PRODUCT = gql`
  mutation deleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      success
    }
  }
`;
const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $id: ID!
    $productName: String!
    $urlPhoto: String!
    $subCategoryId: ID!
    $isActivate: Boolean
    $timeToPrepare: String!
    $productDescritpion: String!
    $typeProduct: String!
    $tarif1: String!
    $tarif2: String!
    $tarif3: String!
    $tarif4: String!
    $buyableByKdoCode: Boolean
    $availability: ProductAvailabilityEnum
  ) {
    updateProduct(
      input: {
        id: $id
        productName: $productName
        urlPhoto: $urlPhoto
        subCategoryId: $subCategoryId
        isActivate: $isActivate
        timeToPrepare: $timeToPrepare
        productDescritpion: $productDescritpion
        typeProduct: $typeProduct
        tarif1: $tarif1
        tarif2: $tarif2
        tarif3: $tarif3
        tarif4: $tarif4
        buyableByKdoCode: $buyableByKdoCode
        availability: $availability
      }
    ) {
      id
      productName
      timeToPrepare
      isActivate
      urlPhoto
      typeProduct
      tarif1
      tarif2
      tarif3
      tarif4
      productDescritpion
      availability
      buyableByKdoCode
    }
  }
`;
export { ADD_PRODUCT_TO_SUBCATEGORY, REMOVE_PRODUCT, UPDATE_PRODUCT };
