import gql from 'graphql-tag';
const USERS = gql`
  query {
    users {
      id
      lastName
      email
      address
      firstName
      phone
      city
      fidelities {
        id
        activated
        createdAt
        updatedAt
        validity
        sizeKdo
        codeKdo
        status
        fidelityDetails {
          id
          sizePizza
          quantity
          number
        }
      }
    }
  }
`;

export { USERS };
