import { gql } from "apollo-boost";

export const FORMULE_FRAGMENT = gql`
  fragment formuleInfos on Formule {
    id
    name
    pizzaQty
    pizzaSize
    drinkQty
    drink {
      id
      productName
    }
  }
`;
