import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { useMutation } from 'react-apollo-hooks';

import { useQuery } from 'react-apollo-hooks';
import { GET_SUBCATEGORIES_BY_ID } from '../../graphql/subcategory/query';

import { DELETE_SUB_CATEGORY } from '../../graphql/subcategory/mutation';

import { Button } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import CategoryMenuItem from '../CategoryMenu/CategoryMenuItem/CategoryMenuItem';

import ISubcategory from '../../Interface/ISubcategory';
import ICategory from '../../Interface/ICategory';
import ProductPage from '../Products/ProductPage';

import PizzaCategoryEdit from '../PizzaCategoryEdit/PizzaCategoryEdit';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import PizzaCategoryCreate from '../PizzaCategoryCreate/PizzaCategoryCreate';
import {
  NotificationContainer,
  NotificationManager
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ConfirmationDialog from '../../Common/ConfirmationDialog';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  col: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'column' as 'column',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto'
  },
  row: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row' as 'row',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  fab: {
    color: theme.customPalette.text.white,
    textTransform: 'capitalize'
  },
  extendedIcon: {
    marginRight: theme.spacing(2)
  },
  titleCatalogue: {
    fontSize: 18,
    color: theme.customPalette.text.orange
  },
  bordure: {
    border: '1px solid #CACED5',
    marginTop: 14
  },
  iconMarge: {
    marginRight: 8
  },
  bordureRight: {
    borderRight: '1px solid #CACED5'
  },
  padding24: {
    padding: '30px 24px',
    borderBottom: '1px solid #CACED5'
  },
  paddingLeftMenu: {
    padding: '30px 12px',
    borderBottom: '1px solid #CACED5'
  }
}));

export interface ICategoryMenuState {
  category: ICategory;
}

const Menus = (props: RouteComponentProps) => {
  const { history, location } = props;

  const { state } = location;

  const classes = useStyles();

  const [dialogEditCategoryId, setDialogEditCategoryId] = useState<
    number | undefined
  >(undefined);

  const [isDialogCreateCategoryOpen, setIsDialogCreateCategoryOpen] = useState<
    boolean
  >(false);

  const delSubCategory = useMutation(DELETE_SUB_CATEGORY);
  const { data, loading, error } = useQuery<ICategoryMenuState>(
    GET_SUBCATEGORIES_BY_ID,
    {
      variables: {
        id: '2' //id Boissons
      },
      fetchPolicy: 'network-only'
    }
  );

  const [open, setOpen] = useState(false);

  const [value, setValue] = useState('0');

  const goEdit = val => {
    history.push({
      state: { subcateg: val }
    });
    setDialogEditCategoryId(val.id);
  };

  const handleClickOpen = val => {
    setValue(val);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error</div>;
  }
  if (!data || !data.category.subCategories) {
    return <div>Error</div>;
  }

  if (data.category.subCategories.length && (!state || !state.subCategory)) {
    history.replace({
      pathname: location.pathname,
      state: {
        ...state,
        subCategory: data.category.subCategories[0].id
      }
    });
  }

  const deletePizzaCategory = () => {
    try {
      delSubCategory({
        update: (cache, mutationResult: any) => {
          const { category } = cache.readQuery({
            query: GET_SUBCATEGORIES_BY_ID,
            variables: {
              id: '2'
            }
          });
          category.subCategories = [
            ...category.subCategories.filter(i => i.id !== value)
          ];
          cache.writeQuery({
            query: GET_SUBCATEGORIES_BY_ID,
            variables: {
              id: '2'
            },
            data: category
          });
        },
        variables: {
          id: value + ''
        }
      }).then(
        valeur => {
          NotificationManager.success('Suppression avec succès');
          //addPrix(valeur.data).then(onClose);
        },
        raison => {
          NotificationManager.error('Erreur lors de la suppression');
        }
      );
    } catch (error) {
      NotificationManager.error('Erreur lors de la suppression');
    }

    setOpen(false);
    //props.history.push("/pizzas");
  };

  const menuCateg = data.category.subCategories.map((el: ISubcategory, key) => {
    return (
      <CategoryMenuItem
        goEdit={goEdit}
        titleCateg={'Boissons'}
        deletePizzaCategory={deletePizzaCategory}
        key={key}
        subcategory={el}
        handleClickOpen={handleClickOpen}
      />
    );
  });

  return (
    <>
      <NotificationContainer />
      <PizzaCategoryCreate
        open={Boolean(isDialogCreateCategoryOpen)}
        titleCateg={'Boissons'}
        handleError={message => {
          if (message != '') {
            NotificationManager.error(message);
          }
        }}
        handleSuccess={message => {
          if (message != '') {
            NotificationManager.success(message);
          }
        }}
        handleClose={() => setIsDialogCreateCategoryOpen(false)}
      />

      {state && state.subcateg && (
        <PizzaCategoryEdit
          key={state.subcateg.subCategoryId}
          open={Boolean(dialogEditCategoryId)}
          id={dialogEditCategoryId}
          titleCateg={'Boissons'}
          handleError={message => {
            if (message != '') {
              NotificationManager.error(message);
            }
          }}
          handleSuccess={message => {
            if (message != '') {
              NotificationManager.success(message);
            }
          }}
          handleClose={() => setDialogEditCategoryId(undefined)}
        />
      )}
      <div>
        <Typography align="left" className={classes.titleCatalogue}>
          Catalogues : Boissons
        </Typography>
        <Grid
          container
          className={classNames(classes.bordure, classes.root)}
          spacing={0}
        >
          <Grid
            className={classes.bordureRight}
            item
            justify="center"
            direction="column"
            xs={12}
            sm={12}
            md={4}
            lg={3}
          >
            <div className={classes.padding24}>
              <Button
                variant="contained"
                aria-label="Ajouter"
                color="secondary"
                size="large"
                fullWidth
                className={classes.fab}
                onClick={() => setIsDialogCreateCategoryOpen(true)}
              >
                <AddIcon className={classes.iconMarge} />
                Catégories
              </Button>
            </div>
            <div className={classes.paddingLeftMenu}>
              <div>
                {menuCateg}

                <ConfirmationDialog
                  title={'Supprimer une catégorie'}
                  classes={{
                    paper: classes.paper
                  }}
                  id="ringtone-menu"
                  keepMounted
                  open={open}
                  onConfirm={deletePizzaCategory}
                  onClose={handleClose}
                  value={value}
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            justify="center"
            direction="column"
            xs={12}
            sm={12}
            md={8}
            lg={9}
          >
            <ProductPage titleCateg={'Boissons'} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default withRouter(Menus);
