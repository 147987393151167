import gql from 'graphql-tag';
const GET_ALL_PRODUCT = gql`
  query subCategory($id: ID!) {
    subCategory(id: $id) {
      products {
        id
        productName
        timeToPrepare
        isActivate
        urlPhoto
        typeProduct
        tarif1
        tarif2
        tarif3
        tarif4
        buyableByKdoCode
        productDescritpion
        availability
      }
    }
  }
`;

const GET_BOISSONS = gql`
  query GET_BOISSONS {
    category(id: 2) {
      products {
        id
        productName
      }
    }
  }
`;

export { GET_ALL_PRODUCT, GET_BOISSONS };
