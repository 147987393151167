import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import { useMutation } from 'react-apollo-hooks';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { UPDATE_SUB_CATEGORY } from '../../graphql/subcategory/mutation';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import ImageWithUpload from '../../Common/cloudinary/ImageWithUpload';
import ICloudinaryUploadResult from '../../Interface/ICloudinaryUploadResult';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  closeButton: {
    color: theme.customPalette.text.white,
    paddingRight: 16
  },
  textButton: {
    color: theme.customPalette.text.white
  },
  textField: {
    marginTop: 25,
    marginBottom: 25
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  button: {
    margin: '8px 0 16px'
  },
  none: {
    display: 'none'
  },
  dialogActionPadding: {
    padding: '24px'
  }
}));
interface PizzaCategorieEditProps {
  id: number;
  open: boolean;
  titleCateg: string;
  handleClose: () => void;
  handleError: (message: String) => void;
  handleSuccess: (message: String) => void;
}

const PizzaCategoryEdit = (
  props: RouteComponentProps & PizzaCategorieEditProps
) => {
  const {
    history,
    id,
    open,
    handleClose,
    titleCateg,
    handleSuccess,
    handleError
  } = props;
  const classes = useStyles();

  const subcateg = history.location.state.subcateg;

  const [subCategoryName, setSubCategoryName] = useState(
    subcateg.subCategoryName
  );
  const [checked, setChecked] = useState(subcateg.isActivate);

  const [type] = useState(subcateg.typeSubCategory);
  const [urlPhoto, setUrlPhoto] = useState(subcateg.urlPhoto);
  const [isUploading, setUploading] = useState(false);
  const updateSubCategory = useMutation(UPDATE_SUB_CATEGORY);

  const handleSubmit = async () => {
    editCategory();
  };
  const onClose = () => {
    handleClose();
    handleSuccess('Enregistrement avec succès');
  };
  const editCategory = async () => {
    try {
      await updateSubCategory({
        variables: {
          id: id,
          subCategoryName: subCategoryName,
          isActivate: checked,
          typeSubCategory: type,
          urlPhoto: urlPhoto,
          categoryId: titleCateg == 'pizzas' ? '1' : '2'
        }
      }).then(onClose);
    } catch (error) {
      handleError('Erreur lors de le modification');
    }
  };
  const onUploadDone = (arg: ICloudinaryUploadResult) => {
    setUrlPhoto(arg.public_id);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog open={open} onClose={handleClose} fullScreen={fullScreen} fullWidth>
      <Grid
        direction="row"
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
        container
        id="alert-dialog-title"
      >
        <DialogTitle>Modifier une catégorie</DialogTitle>
        <IconButton
          className={classes.closeButton}
          onClick={() => handleClose()}
          aria-label="fermer"
        >
          <ClearIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <TextField
          id="outlined-name"
          label="Nom de la catégorie"
          defaultValue={subCategoryName}
          onChange={e => setSubCategoryName(e.target.value)}
          InputLabelProps={{
            shrink: true
          }}
          className={classes.textField}
          fullWidth
          margin="normal"
          variant="outlined"
        />

        {/*
             <Typography>Activé</Typography>
            <Checkbox
              checked={checked}
              onChange={() => setChecked(!checked)}
              value="checkedA"
              inputProps={{
                "aria-label": "primary checkbox"
              }}
            />*/}

        <ImageWithUpload
          publicId={urlPhoto}
          onUploadStateChange={setUploading}
          onUploadDone={onUploadDone}
          edit={true}
          width={'150'}
          height={'150'}
          crop={'fill'}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActionPadding}>
        <Button
          variant="contained"
          aria-label="valider"
          color="secondary"
          className={classes.textButton}
          onClick={handleSubmit}
          disabled={isUploading}
        >
          Modifier
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(PizzaCategoryEdit);
