import * as React from "react";
import IFormule from "../../../Interface/IFormule";
import { List, ListItem, makeStyles } from "@material-ui/core";
import FormuleItem from "./FormuleItem";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  listItem: {}
}));

interface IListFormulesProps {
  formules: IFormule[];
  onDeleteClicked: (id: number) => void;
  onEditClicked: (formule: IFormule) => void;
}
const ListFormules = (props: IListFormulesProps) => {
  const { formules, onDeleteClicked, onEditClicked } = props;
  const classes = useStyles();
  return (
    <List className={classes.root}>
      {formules &&
        formules.map(formule => {
          return (
            <ListItem
              key={formule.id}
              className={classes.listItem}
              disableGutters={true}
            >
              <FormuleItem
                formule={formule}
                onDeleteClicked={onDeleteClicked}
                onEditClicked={onEditClicked}
              />
            </ListItem>
          );
        })}
    </List>
  );
};

export default ListFormules;
