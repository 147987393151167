import gql from 'graphql-tag';

const UPDATE_ORDER = gql`
  mutation updateOrder($id: ID!, $status: String!, $paid: String!) {
    updateOrder(input: { id: $id, status: $status, paid: $paid }) {
      id
      createdAt
      updatedAt
      orderTime
      expectedDeliveryTime
      status
      deliveryMethod
      paid
      phone
      subTotal
      user {
        id
        email
        createdAt
        lastName
        firstName
      }
      details {
        subTotal
        comment
        product {
          productName
        }
        size
      }
    }
  }
`;
export { UPDATE_ORDER };
