export const Constants = {
  ACTIVATED: 'activé',
  DESACTIVATED: 'désactivé',
  SM: 'PETITE',
  MD: 'MOYENNE',
  LG: 'GRANDE',
  XLG: 'MAXI'
};
export const Formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2
});
export const TypeAnnuel = [
  {
    value: '2019',
    label: '2019'
  },
  {
    value: '2020',
    label: '2020'
  },
  {
    value: '2021',
    label: '2021'
  },
  {
    value: '2023',
    label: '2023'
  },
  {
    value: '2024',
    label: '2024'
  },
  {
    value: '2025',
    label: '2025'
  },
  {
    value: '2026',
    label: '2026'
  },
  {
    value: '2027',
    label: '2027'
  },
  {
    value: '2028',
    label: '2028'
  },
  {
    value: '2029',
    label: '2029'
  },
  {
    value: '2030',
    label: '2030'
  }
];
export const TypeJourMois = [
  {
    value: 'Journalier',
    label: 'Journalier'
  },
  {
    value: 'Mensuel',
    label: 'Mensuel'
  },
  {
    value: 'Annuel',
    label: 'Annuel'
  }
];
export const TypeMensuel = [
  {
    value: 'Janvier',
    label: 'Janvier'
  },
  {
    value: 'Fevrier',
    label: 'Fevrier'
  },
  {
    value: 'Mars',
    label: 'Mars'
  },
  {
    value: 'Avril',
    label: 'Avril'
  },
  {
    value: 'Mai',
    label: 'Mai'
  },
  {
    value: 'Juin',
    label: 'Juin'
  },
  {
    value: 'Juillet',
    label: 'Juillet'
  },
  {
    value: 'Août',
    label: 'Août'
  },
  {
    value: 'Septembre',
    label: 'Septembre'
  },
  {
    value: 'Octobre',
    label: 'Octobre'
  },
  {
    value: 'Novembre',
    label: 'Novembre'
  },
  {
    value: 'Decembre',
    label: 'Décembre'
  }
];
export const ParamsOrder = {
  prete: 'Prête',
  enAttente: 'En attente',
  paye: 'Payée',
  oui: 'oui',
  non: 'non',
  aDomicile: 'A domicile',
  surPlace: 'Sur place',
  annuler: 'Annulée',
  desactiver: 'Désactivé'
};
export const Status = [
  {
    value: 'Prête',
    label: 'Prête'
  },
  {
    value: 'En attente',
    label: 'En attente'
  },
  {
    value: 'Annulée',
    label: 'Annulée'
  },
  {
    value: 'Livrée',
    label: 'Livrée'
  },
];

export const Paid = [
  {
    value: 'oui',
    label: 'oui'
  },
  {
    value: 'non',
    label: 'non'
  }
];
export const StatusFidelity = [
  {
    value: 'Activé',
    label: 'Activé'
  },
  {
    value: 'Désactivé',
    label: 'Désactivé'
  },
  {
    value: 'Déjà utilisé',
    label: 'Déjà utilisé'
  }
];

