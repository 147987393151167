import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { UPDATE_SUB_CATEGORY } from '../../../graphql/subcategory/mutation';
import { useMutation } from 'react-apollo-hooks';
import {
  Link as RouterLink,
  withRouter,
  RouteComponentProps
} from 'react-router-dom';
import { Link } from 'react-router-dom';
import ISubcategory from '../../../Interface/ISubcategory';

const useStyles = makeStyles(theme => ({
  linkCategory: {
    color: theme.customPalette.text.blueNoir,
    fontSize: 14,
    '&:hover': {
      color: theme.customPalette.text.orange
    }
  },
  active: {
    color: theme.customPalette.text.orange
  },
  row: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row' as 'row',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto',
    alignItems: 'center'
  }
}));
export interface ICategoryMenuProps {
  handleClickOpen: Function;
  subcategory: ISubcategory;
  deletePizzaCategory: Function;
  goEdit: Function;
  titleCateg: string;
}

export interface ICategoryCategoryMenuItemtate {}

const CategoryMenuItem = (props: ICategoryMenuProps & RouteComponentProps) => {
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const classes = useStyles();
  const { handleClickOpen, subcategory, goEdit, location, titleCateg } = props;
  const { state } = location;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isActive, setIsActive] = useState(subcategory.isActivate);
  const updateSubCategory = useMutation(UPDATE_SUB_CATEGORY);
  const updateSubCateg = (e: any) => {
    setIsActive(!isActive);
    updateSubCategory({
      variables: {
        id: subcategory.id,
        subCategoryName: subcategory.subCategoryName,
        isActivate: e.target.checked,
        typeSubCategory: subcategory.typeSubCategory,
        urlPhoto: subcategory.urlPhoto,
        categoryId: titleCateg === 'pizzas' ? '1' : '2'
      }
    }).then(
      valeur => {},
      raison => {
        setIsActive(!e.target.checked);
      }
    );
  };


  return (
    <Fragment>
      <Grid
        key={subcategory.id + ''}
        container
        direction="row"
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
      >
        <div className={classes.row}>
          <Checkbox
            value="checkedActiveCategorie"
            inputProps={{
              'aria-label': 'uncontrolled-checkbox'
            }}
            checked={isActive}
            onChange={e => updateSubCateg(e)}
            //onChange={e => setIsActive(e.target.checked)}
          />
          <Link
            to={{
              pathname: location.pathname,
              search: '?query=abc',
              state: { subCategory: subcategory.id }
            }}
            className={classNames(
              classes.linkCategory,
              state && +state.subCategory === +subcategory.id && classes.active
            )}
          >
            {' '}
            {subcategory.subCategoryName}{' '}
          </Link>
        </div>
        <IconButton
          aria-label="More"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              goEdit(subcategory);
            }}
          >
            Modifier
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              handleClickOpen(subcategory.id);
            }}
          >
            Supprimer
          </MenuItem>
        </Menu>
      </Grid>
    </Fragment>
  );
};

export default withRouter(CategoryMenuItem);
