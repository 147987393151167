import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter, RouteComponentProps } from "react-router-dom";
import IFormule from "../../../Interface/IFormule";
import PizzaSizeEnum from "../../../Interface/PizzaSizeEnum";

const useStyles = makeStyles(theme => ({
  contentItemProduct: {
    borderBottom: "1px solid #CACED5",
    padding: "24px 0"
  },
  row: {
    boxSizing: "border-box" as "border-box",
    display: "flex",
    flex: "0 1 auto",
    flexDirection: "row" as "row",
    msFlex: "0 1 auto",
    webkitFlex: "0 1 auto"
  },
  noWrap: {
    flexWrap: "nowrap"
  },
  linkNamePizza: {
    color: theme.customPalette.text.red,
    fontSize: 25,
    textTransform: "uppercase",
    lineHeight: 1,
    marginBottom: 12
  },
  listIngredient: {
    fontSize: 14,
    padding: "2px 0"
  }
}));

const resolvePizzaSize = (size: PizzaSizeEnum) => {
  return `${size[0]}${size.substr(1).toLowerCase()}`;
};
export interface ICategoryMenuProps {
  formule: IFormule;
  onEditClicked: (formule: IFormule) => void;
  onDeleteClicked: (id: number) => void;
}

export interface ICategoryCategoryMenuItemtate {}

const FormuleItemPage = (props: RouteComponentProps & ICategoryMenuProps) => {
  const classes = useStyles();
  const { formule } = props;
  const onEditClicked = () => {
    props.onEditClicked(formule);
  };
  const onDeleteClicked = () => {
    props.onDeleteClicked(formule.id);
  };
  return (
    <Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        className={classNames(classes.contentItemProduct, classes.noWrap)}
      >
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          className={classes.noWrap}
        >
          <Grid container direction="column" justify="space-between">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Typography className={classes.linkNamePizza}>
                  {formule.name}
                </Typography>
              </Grid>
              <Typography className={classes.listIngredient}>
                Acheter {formule.pizzaQty} pizzas{" "}
                {resolvePizzaSize(formule.pizzaSize)}
              </Typography>
              <Typography className={classes.listIngredient}>
                {formule.drinkQty} {formule.drink.productName} offert
                {formule.drinkQty > 1 ? "s" : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.row}>
          <IconButton
            aria-label="Modifier"
            color="primary"
            onClick={onEditClicked}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="Supprimer"
            color="primary"
            onClick={onDeleteClicked}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withRouter(FormuleItemPage);
