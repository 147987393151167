import React from 'react';
import classNames from 'classnames';
import { withRouter, RouteComponentProps, Redirect } from 'react-router';
import Grid from '@material-ui/core/Grid';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { styles } from './authStyles';
import SignIn from './SignIn/SignIn';
import logo from '../../Assets/Images/logo.png';
import AuthService from '../../Services/AuthService';

const Authentification = (
  props: RouteComponentProps<{ action: string }> & WithStyles<typeof styles>
) => {
  const action = props.match.params.action;
  const { classes } = props;

  if (AuthService.isAuthenticated()) {
    return <Redirect to="/listeCommandes" />;
  }

  return (
    <div className={classes.root}>
      <Grid
        container={true}
        direction="row"
        justify="center"
        alignItems="stretch"
        className={classNames(classes.authSection, classes.container)}
        spacing={0}
      >
        <Grid
          container={true}
          direction="column"
          className={classNames(classes.leftSide)}
          item={true}
          xs={12}
        >
          <img src={logo} style={{ width: '100%' }} />
          <span className={classNames(classes.welcomeText)}>
            Baie - Mahault
          </span>
        </Grid>
        <Grid
          container={true}
          direction="column"
          className={classNames(classes.rightSide)}
          item={true}
          xs={12}
        >
          <SignIn />
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(withStyles(styles)(Authentification));
