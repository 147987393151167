import gql from 'graphql-tag';

const ADD_SUB_CATEGORY = gql`
  mutation addSubCategory(
    $subCategoryName: String!
    $isActivate: Boolean!
    $urlPhoto: String!
    $categoryId: ID!
    $typeSubCategory: String!
  ) {
    addSubCategory(
      input: {
        subCategoryName: $subCategoryName
        isActivate: $isActivate
        urlPhoto: $urlPhoto
        categoryId: $categoryId
        typeSubCategory: $typeSubCategory
      }
    ) {
      id
      isActivate
      subCategoryName
      urlPhoto
      typeSubCategory
    }
  }
`;

const UPDATE_SUB_CATEGORY = gql`
  mutation updateSubCategory(
    $id: ID!
    $subCategoryName: String!
    $isActivate: Boolean
    $urlPhoto: String!
    $categoryId: ID!
    $typeSubCategory: String!
  ) {
    updateSubCategory(
      input: {
        id: $id
        subCategoryName: $subCategoryName
        isActivate: $isActivate
        urlPhoto: $urlPhoto
        categoryId: $categoryId
        typeSubCategory: $typeSubCategory
      }
    ) {
      id
      isActivate
      subCategoryName
      urlPhoto
      typeSubCategory
    }
  }
`;

const DELETE_SUB_CATEGORY = gql`
  mutation deleteSubCategory($id: ID!) {
    deleteSubCategory(id: $id) {
      action
      success
      message
    }
  }
`;

export { ADD_SUB_CATEGORY, UPDATE_SUB_CATEGORY, DELETE_SUB_CATEGORY };
