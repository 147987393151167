import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { History } from 'history';
import { ADD_TARIF } from '../../graphql/tarif/mutation';
import { GET_ALL_TARIF } from '../../graphql/tarif/query';

import {
    Button,
    Paper,
    TextField,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import ConfirmationDialog from '../../Common/ConfirmationDialog';

import IProduct from '../../Interface/IProduct';
import ISubcategory from '../../Interface/ISubcategory';

export interface IProductProps {
    idSubCategory: string;
    history: History;
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}


const TarifAdd = (props: IProductProps) => {
    console.log("PROPS", props);

    const [tarifName, setTarifName] = useState('');
    const [tarifPrice, settarifPrice] = useState('');

    const addTarif = useMutation(ADD_TARIF);

    const [open, setOpen] = React.useState(false);
    // const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const [value, setValue] = React.useState('Dione');

    function handleClickOpen() {
        setOpen(true);
    }

    const handleClose = (newValue?: string) => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };



    const handleSubmit = () => {
        addTarif({
            update: (cache, mutationResult: any) => {
                console.log("RESULT", mutationResult);

                /* const { getAllTarifOfProduct } = cache.readQuery({ query: GET_ALL_TARIF,variables:{
                   product:props.history.location.state.product.productId
                 } });
                 cache.writeQuery({
                   query: GET_ALL_TARIF,
                   variables:{
                       product:props.history.location.state.product.productId
                   },
                   data: {
                       getAllTarifOfProduct: getAllTarifOfProduct.concat([mutationResult.data.addTarif])
                   }
                 });*/
            },
            variables: {
                product: props.history.location.state.product.productId,
                tarifName: tarifName,
                tarifPrice: tarifPrice,
                urlPhoto: "photo",
                typeTarif: tarifName

            }
        });
        console.log("SUBMIT ADD", props.history.location.state.product.productId);

        props.history.push({
            pathname: '/tarifs',
            state: { product: props.history.location.state.product }
        })

    };
    const handleChange = event => {
        setTarifName(event.target.value);
    };
    return (
        <Fragment>
            <Typography align="center" variant="h2" component="h3" gutterBottom>
                Ajouter un tarif
            </Typography>


            <Paper>
                <form onSubmit={() => handleSubmit()}>
                    <div className='margin'>
                        <Select
                            inputProps={{
                                name: 'age',
                                id: 'age-simple'
                            }}
                            onChange={handleChange}
                            value={tarifName}
                        >
                            <MenuItem value="">
                                <em />
                            </MenuItem>
                            <MenuItem value="Petite">Petite</MenuItem>
                            <MenuItem value="Moyenne">Moyenne</MenuItem>
                            <MenuItem value="Grande">Grande</MenuItem>
                            <MenuItem value="Maxi">Maxi</MenuItem>
                        </Select>
                    </div>

                    <div className='margin'>
                        <TextField
                            label='Prix'
                            placeholder='Prix'
                            fullWidth
                            required
                            type="number"
                            value={tarifPrice}
                            onChange={e => settarifPrice(e.target.value)}
                        />
                    </div>

                    <div className='margin'>
                        <Button type='submit' variant='contained' color='primary'>
                            Ajouter
                        </Button>
                    </div>
                </form>
            </Paper>
        </Fragment >
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingTop: 20

    },
    fab: {
        margin: theme.spacing(2)
    },
    extendedIcon: {
        marginRight: theme.spacing(2)
    }
}));

export default TarifAdd;
