import gql from 'graphql-tag';
import { SUPPLEMENT_FRAGMENT } from './fragment';

const ADD_SUPPLEMENT = gql`
  mutation ADD_SUPPLEMENT($supplementName: String!, $supplementPrice: String!) {
    addSupplement(
      input: {
        supplementName: $supplementName
        supplementPrice: $supplementPrice
      }
    ) {
      ...supplementInfos
    }
  }
  ${SUPPLEMENT_FRAGMENT}
`;

const DELETE_SUPPLEMENT = gql`
  mutation DELETE_SUPPLEMENT($id: ID!) {
    deleteSupplement(id: $id) {
      message
      success
      action
    }
  }
`;
const UPDATE_SUPPLEMENT = gql`
  mutation UPDATE_SUPPLEMENT(
    $id: ID!
    $supplementName: String!
    $supplementPrice: String!
  ) {
    updateSupplement(
      input: {
        id: $id
        supplementName: $supplementName
        supplementPrice: $supplementPrice
      }
    ) {
      ...supplementInfos
    }
  }
  ${SUPPLEMENT_FRAGMENT}
`;
export { ADD_SUPPLEMENT, DELETE_SUPPLEMENT, UPDATE_SUPPLEMENT };
