import React, { Fragment, useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { useMutation } from 'react-apollo-hooks';
import { UPDATE_FIDELITY } from '../../../graphql/fidelity/mutation';
import IFidelity from '../../../Interface/IFidelity';
import {
  Link as RouterLink,
  withRouter,
  RouteComponentProps
} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  linkCategory: {
    color: theme.customPalette.text.blueNoir,
    fontSize: 14,
    '&:hover': {
      color: theme.customPalette.text.orange
    }
  },
  active: {
    color: theme.customPalette.text.orange
  },
  row: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row' as 'row',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto',
    alignItems: 'center'
  }
}));
export interface ICategoryMenuProps {
  fidelity: IFidelity;
}

export interface ICategoryCategoryMenuItemtate {}

const CategoryMenuItem = (props: ICategoryMenuProps & RouteComponentProps) => {
  const classes = useStyles();
  const { fidelity } = props;
  const [isActive, setIsActive] = useState(fidelity.activated);
  const updateFidelity = useMutation(UPDATE_FIDELITY);
  const [statusFidelity, setStatusFidelity] = useState({
    status: ''
  });
  useEffect(() => {
    setStatusFidelity({ ...statusFidelity, status: fidelity.status });
  }, [fidelity]);
  const handleUpdateFidelity = (e: any) => {
    setIsActive(!isActive);
    updateFidelity({
      variables: {
        id: fidelity.id,
        activated: e.target.checked
      }
    }).then(
      valeur => {},
      raison => {
        setIsActive(!isActive);
      }
    );
  };

  return (
    <div className={classes.row}>
      {/* <Checkbox
        inputProps={{
          'aria-label': 'uncontrolled-checkbox'
        }}
        disabled={isActive}
        checked={isActive}
        onChange={e => handleUpdateFidelity(e)}
      /> */}
      <input
        type='checkbox'
        disabled={isActive}
        checked={isActive}
        onChange={e => handleUpdateFidelity(e)}
      />
      <label
        style={{
          fontSize: 13,
          marginRight: 5
        }}
      >{`Activé`}</label>
    </div>
  );
};

export default withRouter(CategoryMenuItem);
