import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import IProduct from '../../../Interface/IProduct';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ImageWithUpload from '../../../Common/cloudinary/ImageWithUpload';
import gitfIcone from '../../../Assets/Images/icon-gift.png';
import { ProductAvailabilityEnum } from '../../../Interface/ProductAvailabilityEnum';
import Constants from '../../../defaults/Constants';

const useStyles = makeStyles(theme => ({
  contentItemProduct: {
    borderBottom: '1px solid #CACED5',
    padding: '24px 0'
  },
  contentImgPizzas: {
    borderRadius: 4,
    overflow: 'hidden',
    marginRight: 30
  },
  imgPizzas: {
    width: 111,
    height: 111,
    objectFit: 'cover',
    verticalAlign: 'middle'
  },
  row: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row' as 'row',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto',
    '@media (max-width: 768px)': {
      flexDirection: 'column' as 'column'
    }
  },
  noWrap: {
    flexWrap: 'nowrap'
  },
  linkNamePizza: {
    color: theme.customPalette.text.orange,
    fontSize: 25,
    textTransform: 'uppercase',
    lineHeight: 1
  },
  listIngredient: {
    fontSize: 12,
    textTransform: 'uppercase',
    padding: '10px 0 30px'
  },
  price: {
    fontSize: 20
  },
  displayNone: {
    display: 'none'
  },
  availability: {
    marginTop: 15
  }
}));
export interface ICategoryMenuProps {
  idSubCategory: String;
  handleCLickEdit: Function;
  handleClickOpen: Function;
  product: IProduct;
  titleCateg: string;
}

export interface ICategoryCategoryMenuItemtate {}


const ProductItem = (props: RouteComponentProps & ICategoryMenuProps) => {
  const classes = useStyles();
  const {
    handleClickOpen,
    product,
    handleCLickEdit,
    idSubCategory,
    titleCateg
  } = props;

  /**availability map */
  const availabilityMap = new Map();
  availabilityMap.set(ProductAvailabilityEnum.ALWAYS, 'Tout le temps');
  availabilityMap.set(ProductAvailabilityEnum.NOT_AVAILABLE, 'Non disponible');
  availabilityMap.set(ProductAvailabilityEnum.WEEKEND, 'Week-end');

  const GeneratePRix = () => {
    if (titleCateg == 'pizzas') {
      return (
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <Typography className={classes.price}>
              {'P : '}
              {props.product.tarif1} &euro;
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.price}>
              {'M : '}
              {props.product.tarif2} &euro;
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.price}>
              {'G : '}
              {props.product.tarif3} &euro;
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.price}>
              {'Maxi : '}
              {props.product.tarif4} &euro;
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <Typography className={classes.price}>
              {'Prix : '}
              {props.product.tarif1} &euro;
            </Typography>
          </Grid>
        </Grid>
      );
    }
  };
  return (
    <Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        className={classNames(classes.contentItemProduct, classes.noWrap)}
      >
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          className={classes.noWrap}
        >
          <Grid item className={classes.contentImgPizzas}>
            <ImageWithUpload
              publicId={props.product.urlPhoto}
              edit={false}
              width={'111'}
              height={'111'}
              crop={'fill'}
              className={classes.imgPizzas}
            />
          </Grid>
          <Grid container direction="column" justify="space-between">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Checkbox
                  value="checkedProduit"
                  className={classes.displayNone}
                  inputProps={{
                    'aria-label': 'uncontrolled-checkbox'
                  }}
                />
                <Typography className={classes.linkNamePizza}>
                  {props.product.productName}
                </Typography>
                {props.product.buyableByKdoCode && titleCateg === Constants.CATEGORY_PIZZA && (
                      <img style={{marginLeft: 10}} src={gitfIcone} width="17px" height="17px" />
                )}
              </Grid>
              <Typography className={classes.listIngredient}>
                {props.product.productDescritpion}
              </Typography>
            </Grid>
            <GeneratePRix />


            <Typography className={classes.availability}>
                  Disponiblité{' '}:{' '}
              {availabilityMap.get(props.product.availability)}
            </Typography>

          </Grid>
        </Grid>
        <Grid item className={classes.row}>
          <IconButton
            aria-label="Modifier"
            color="primary"
            onClick={() => {
              handleCLickEdit(product);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="Supprimer"
            color="primary"
            onClick={() => handleClickOpen(props.product.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withRouter(ProductItem);
