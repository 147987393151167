import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useMutation } from 'react-apollo-hooks';
import { ADD_SUB_CATEGORY } from '../../graphql/subcategory/mutation';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { GET_SUBCATEGORIES_BY_ID } from '../../graphql/subcategory/query';
import ImageWithUpload from '../../Common/cloudinary/ImageWithUpload';
import ICloudinaryUploadResult from '../../Interface/ICloudinaryUploadResult';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  closeButton: {
    color: theme.customPalette.text.white,
    paddingRight: 16
  },
  textButton: {
    color: theme.customPalette.text.white
  },
  textField: {
    marginTop: 25,
    marginBottom: 25
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  button: {
    margin: '8px 0 16px'
  },
  none: {
    display: 'none'
  },
  dialogActionPadding: {
    padding: '24px'
  }
}));

export interface IPizzaCategoryCreateProps {
  open: boolean;
  titleCateg: string;
  handleClose: () => void;
  handleError: (message: String) => void;
  handleSuccess: (message: String) => void;
}

export interface IPizzaCategoryCreateState {}

const PizzaCategoryCreate = (
  props: RouteComponentProps & IPizzaCategoryCreateProps
) => {
  const { open, handleClose, handleSuccess, handleError, titleCateg } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const [subCategoryName, setSubCategoryName] = useState('');
  const [checked, setChecked] = useState(false);
  const [type, setType] = useState('a');
  const [urlPhoto, setUrlPhoto] = useState<string>(null);
  const [isUploading, setUploading] = useState(false);
  const addSubCategory = useMutation(ADD_SUB_CATEGORY);

  const onClose = () => {
    handleClose();
    handleSuccess('Enregistrement avec succès');
  };

  const handleSubmit = async () => {
    createCategory();
  };

  const createCategory = async () => {
    try {
      await addSubCategory({
        update: (cache, mutationResult: any) => {
          const { category } = cache.readQuery({
            query: GET_SUBCATEGORIES_BY_ID,
            variables: { id: titleCateg == 'pizzas' ? '1' : '2' }
          });
          const newSubCateg = mutationResult.data.addSubCategory;

          category.subCategories = [...category.subCategories, newSubCateg];
          cache.writeQuery({
            query: GET_SUBCATEGORIES_BY_ID,
            variables: { id: titleCateg == 'pizzas' ? '1' : '2' },
            data: category
          });
        },
        variables: {
          subCategoryName: subCategoryName,
          isActivate: checked,
          typeSubCategory: type,
          urlPhoto,
          categoryId: titleCateg == 'pizzas' ? '1' : '2'
        }
      }).then(onClose);
    } catch (error) {
      handleError(
        "Erreur lors de l'ajout, il y a des champs obligatoire ou le nom existe déjà"
      );
    }
  };
  const onUploadDone = (arg: ICloudinaryUploadResult) => {
    setUrlPhoto(arg.public_id);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={fullScreen} fullWidth>
      <Grid
        direction="row"
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
        container
        id="alert-dialog-title"
      >
        <DialogTitle>
          {titleCateg == 'pizzas'
            ? 'Création catégorie pizza'
            : 'Création catégorie boisson'}
        </DialogTitle>
        <IconButton
          className={classes.closeButton}
          onClick={() => handleClose()}
          aria-label="fermer"
        >
          <ClearIcon />
        </IconButton>
      </Grid>

      <DialogContent>
        <TextField
          id="outlined-name"
          label="Nom de la catégorie"
          onChange={e => setSubCategoryName(e.target.value)}
          InputLabelProps={{
            shrink: true
          }}
          className={classes.textField}
          fullWidth
          margin="normal"
          variant="outlined"
        />

        <ImageWithUpload
          publicId={urlPhoto}
          onUploadStateChange={setUploading}
          onUploadDone={onUploadDone}
          edit={true}
          width={'150'}
          height={'150'}
          crop={'fill'}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActionPadding}>
        <Button
          variant="contained"
          aria-label="valider"
          color="secondary"
          className={classes.textButton}
          onClick={handleSubmit}
          disabled={isUploading}
        >
          Ajouter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(PizzaCategoryCreate);
