const Constants = {
  FIDELITY_OPERATION: {
    ACTIVATE: {
      LABEL: 'Activé',
      VALUE: 'ACTIVATE'
    },
    DESACTIVATE: {
      LABEL: 'Désactivé',
      VALUE: 'DEACTIVATE'
    },
    CANCEL: {
      LABEL: 'Annulé',
      VALUE: 'CANCEL'
    }
  },

  CATEGORY_PIZZA: 'pizzas',

  SUBCATEGORIE_HORS_SERIE_ID:1,

  FIDELITY_ALREADY_USED: 'Déjà utilisé',
  FIDELITY_NOT_USED_YET: `N'est pas encore utilisé`,
};
export default Constants;
