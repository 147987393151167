import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ImageTest from './../../Assets/Images/pubg-ps4.jpg';
import ISetting from '../../Interface/ISetting';
import { GET_SETTINGS } from '../../graphql/setting/query';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { TarificationsEnum } from '../../Interface/TarificationsEnum';
import { SET_SETTINGS } from '../../graphql/setting/mutation';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import config from '../../config';
import ImageWithUpload from '../../Common/cloudinary/ImageWithUpload';
import ICloudinaryUploadResult from '../../Interface/ICloudinaryUploadResult';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '24px 28px'
  },

  padding24: {
    padding: '30px 24px',
    borderBottom: '1px solid #CACED5'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  titleCatalogue: {
    fontSize: 18,
    color: theme.customPalette.text.orange
  },
  bordure: {
    border: '1px solid #CACED5',
    marginTop: 14
  },
  content: {
    padding: 25
  },
  titleChamp: {
    fontSize: 18,
    margin: '0 0 16px',
    color: theme.customPalette.primary.pop,
    fontWeight: 'bold'
  },
  textField: {
    margin: '2px 0 32px'
  },
  textButton: {
    color: theme.customPalette.text.white,
    paddingRight: 40,
    paddingLeft: 40,
    margin: '22px 0 50px'
  },
  marge: {
    marginTop: 30
  },
  contentButtonAction: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row' as 'row',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto',
    justifyContent: 'flex-end',
    width: '100%'
  },
  none: {
    display: 'none'
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  button: {
    margin: '8px 0 16px'
  },
  contentImage: {
    height: 450,
    maxWidth: 450,
    marginBottom: 70
  },
  imageParametre: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}));
const TypesTarifs = Object.values(TarificationsEnum);

interface IQuerySettingResult {
  settings: ISetting[];
}
const initialSettings = {
  LOGO: '',
  TARIF: 'Euro €',
  ADDRESS: '',
  PHONE: '',
  EMAIL: '',
  DELIVERY_PRICE: '',
  FACEBOOK: '',
  OPENING_HOURS: ''
};

export interface ICategorySettingProps {}
const Parametres = (props: RouteComponentProps & ICategorySettingProps) => {
  const classes = useStyles();
  const [urlPhoto, setUrlPhoto] = useState<File | null>(null);
  // snack message
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarSuccess, setSnackBarSuccess] = useState(true);
  // store shown settings
  const [settings, setSettings] = useState<any>(initialSettings);
  const inputFileRef = useRef<HTMLInputElement>(null);

  // save settings
  const saveSettings = useMutation(SET_SETTINGS, {
    update: (cache, { data }: { data: { setSettings: ISetting[] } }) => {
      const { setSettings: newSettings } = data;
      cache.writeQuery({
        query: GET_SETTINGS,
        data: { settings: newSettings }
      });
    }
  });

  // get settings
  const { data, error, loading } = useQuery<IQuerySettingResult>(GET_SETTINGS);

  // after each fetchs, transform {key:string, value:string}[] into {key:value}
  useEffect(() => {
    if (!error && !loading) {
      const tmp = data.settings.reduce(function(prev, curr) {
        prev[curr.key] = curr.value;
        return prev;
      }, {});
      setSettings(tmp);
    }
  }, [data, error, loading]);

  const handleChange = name => event => {
    setSettings({ ...settings, [name]: event.target.value });
  };
  const handleSubmit = event => {
    event.preventDefault();
    saveSettings({
      variables: {
        input: Object.entries(settings).map(setting => ({
          key: setting[0],
          value: setting[1]
        }))
      }
    }).then(
      () => {
        setSnackBarMessage('Modification éffectuée avec succès');
        setSnackBarSuccess(true);
      },
      () => {
        setSnackBarMessage('Une erreur est survenue');
        setSnackBarSuccess(false);
      }
    );
  };

  const onUploadDone = (result: ICloudinaryUploadResult) => {
    setSettings({ ...settings, LOGO: result.public_id });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!snackBarMessage}
        autoHideDuration={5000}
        onClose={() => setSnackBarMessage('')}
      >
        <SnackbarContent
          style={{ backgroundColor: snackBarSuccess ? 'green' : 'red' }}
          message={snackBarMessage}
        />
      </Snackbar>
      <div>
        <Typography align="left" className={classes.titleCatalogue}>
          Paramètres
        </Typography>
        <form
          className={classes.container}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Grid
            container
            className={classNames(classes.bordure, classes.content)}
            spacing={3}
          >
            <Grid
              item
              justify="center"
              direction="column"
              xs={12}
              sm={12}
              md={5}
              lg={5}
            >
              <Typography align="left" className={classes.titleChamp}>
                Votre logo
              </Typography>
              <ImageWithUpload
                publicId={settings.LOGO}
                width="auto"
                className={classes.bordure}
                edit={true}
                onUploadDone={onUploadDone}
              />
            </Grid>
            <Grid
              item
              justify="center"
              direction="column"
              xs={12}
              sm={12}
              md={7}
              lg={7}
            >
              <Typography align="left" className={classes.titleChamp}>
                Tarif
              </Typography>
              <TextField
                id="outlined-select-currency-native"
                select
                fullWidth
                label="Mon tarif"
                value={settings.TARIF}
                className={classes.textField}
                onChange={handleChange('TARIF')}
                SelectProps={{
                  native: true
                }}
                variant="outlined"
              >
                {TypesTarifs.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
              <TextField
                id="outlined-name"
                label="Prix de livraison"
                className={classes.textField}
                fullWidth
                margin="normal"
                variant="outlined"
                value={settings.DELIVERY_PRICE}
                onChange={handleChange('DELIVERY_PRICE')}
              />
              <Typography align="left" className={classes.titleChamp}>
                Localisation
              </Typography>
              <TextField
                id="outlined-name"
                label="Adresse"
                className={classes.textField}
                fullWidth
                margin="normal"
                variant="outlined"
                value={settings.ADDRESS}
                onChange={handleChange('ADDRESS')}
              />
              {/* <TextField
                id="outlined-name"
                label="Coordonnées GPS (Long,Lat)"
                className={classes.textField}
                fullWidth
                margin="normal"
                variant="outlined"
                value={settings.COORDINATES}
                onChange={handleChange('COORDINATES')}
              /> */}
              <TextField
                id="outlined-name"
                label="Téléphone"
                className={classes.textField}
                fullWidth
                margin="normal"
                variant="outlined"
                value={settings.PHONE}
                onChange={handleChange('PHONE')}
              />
              <TextField
                id="outlined-name"
                label="Adresse mail"
                className={classes.textField}
                fullWidth
                margin="normal"
                variant="outlined"
                value={settings.EMAIL}
                onChange={handleChange('EMAIL')}
              />
              <TextField
                id="outlined-name"
                label="Facebook"
                className={classes.textField}
                fullWidth
                margin="normal"
                variant="outlined"
                value={settings.FACEBOOK}
                onChange={handleChange('FACEBOOK')}
              />
              <TextField
                id="outlined-opening-hours"
                multiline={true}
                rows={4}
                label="Horaires d'ouvertures"
                className={classes.textField}
                fullWidth
                margin="normal"
                variant="outlined"
                value={settings.OPENING_HOURS}
                onChange={handleChange('OPENING_HOURS')}
              />
              <div className={classes.contentButtonAction}>
                <Button
                  type="submit"
                  className={classes.textButton}
                  variant="contained"
                  color="secondary"
                >
                  Enregistrer
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default withRouter(Parametres);
