import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ISupplement from '../../../Interface/ISupplement';

const useStyles = makeStyles(theme => ({
  contentItemProduct: {
    borderBottom: '1px solid #CACED5',
    padding: '24px 0'
  },
  contentImgItem: {
    borderRadius: 4,
    overflow: 'hidden',
    marginRight: 30
  },
  row: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row' as 'row',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto'
  },
  noWrap: {
    flexWrap: 'nowrap'
  },
  labelSupplement: {
    color: theme.customPalette.text.red,
    fontSize: 25,
    textTransform: 'uppercase',
    lineHeight: 1,
    marginBottom: 12
  },
  price: {
    fontSize: 20
  }
}));

export interface ISupplementProps {
  supplement: ISupplement;
  onEditClicked: (supplement: ISupplement) => void;
  onDeleteClicked: (id: number) => void;
}

export interface ICategoryCategoryMenuItemtate {}

const SupplementItemPage = (props: RouteComponentProps & ISupplementProps) => {
  const classes = useStyles();
  const { supplement } = props;
  const onEditClicked = () => {
    props.onEditClicked(supplement);
  };
  const onDeleteClicked = () => {
    props.onDeleteClicked(supplement.id);
  };
  return (
    <Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        className={classNames(classes.contentItemProduct, classes.noWrap)}
      >
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          className={classes.noWrap}
        >
          <Grid container direction="column" justify="space-between">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Typography className={classes.labelSupplement}>
                  {supplement.supplementName}
                </Typography>
              </Grid>
              <Typography className={classes.price}>
                {supplement.supplementPrice} {' €'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.row}>
          <IconButton
            aria-label="Modifier"
            color="primary"
            onClick={onEditClicked}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="Supprimer"
            color="primary"
            onClick={onDeleteClicked}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withRouter(SupplementItemPage);
