import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo-hooks';
import ISession from '../../Interface/ISession';
import { GET_ALL_SESSION } from '../../graphql/campagnepush/query';
import { SEND_NOTIF } from '../../graphql/campagnepush/mutation';
import {
  NotificationContainer,
  NotificationManager
} from 'react-notifications';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: '24px 28px'
  },

  padding24: {
    padding: '30px 24px',
    borderBottom: '1px solid #CACED5'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  titleCatalogue: {
    fontSize: 18,
    color: theme.customPalette.text.orange
  },
  bordure: {
    border: '1px solid #CACED5',
    marginTop: 14
  },
  content: {
    padding: 25
  },
  titleChamp: {
    fontSize: 18,
    margin: '0 0 16px',
    color: theme.customPalette.primary.pop,
    fontWeight: 'bold'
  },
  textField: {
    margin: '2px 0 32px'
  },
  textButton: {
    color: theme.customPalette.text.white,
    paddingRight: 40,
    paddingLeft: 40,
    marginBottom: 92
  },
  marge: {
    marginTop: 30
  }
}));

export interface ICategoryFormulesProps {
  titleCateg: string;
}
export interface ICamPagnePush {
  getAllSession: [ISession];
}
const CampagnePush = (props: RouteComponentProps & ICategoryFormulesProps) => {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const { data, loading, error } = useQuery<ICamPagnePush>(GET_ALL_SESSION);
  const sendNotif = useMutation<ISession>(SEND_NOTIF);
  const handleSubmit = () => {
    console.log('DATA', data);
    let tokens = [];
    if (data && data.getAllSession && data.getAllSession.length) {
      tokens = data.getAllSession.map(d => {
        return d.tokenFireBase;
      });
    }
    sendNotif({
      variables: {
        tokens: tokens,
        title: title,
        message: message
      }
    }).then(
      value => {
        NotificationManager.success('Envoi réussi');
      },
      raison => {
        NotificationManager.success("Erreur lors de l'envoi");
      }
    );
  };
  return (
    <>
      <div>
        <NotificationContainer />
        <Typography align="left" className={classes.titleCatalogue}>
          Campagne push
        </Typography>
        <div className={classNames(classes.content, classes.bordure)}>
          <Typography align="left" className={classes.titleChamp}>
            Titre de la campagne
          </Typography>
          <TextField
            id="outlined-name"
            label="Titre"
            className={classes.textField}
            fullWidth
            required
            margin="normal"
            variant="outlined"
            onChange={e => setTitle(e.target.value)}
          />
          <Typography
            align="left"
            className={classNames(classes.titleChamp, classes.marge)}
          >
            Description de la campagne
          </Typography>
          <TextField
            id="outlined-multiline-static"
            label="Description"
            onChange={e => setMessage(e.target.value)}
            multiline
            rows="5"
            required
            fullWidth
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          <Button
            type="submit"
            className={classes.textButton}
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
          >
            Envoyer
          </Button>
        </div>
      </div>
    </>
  );
};

export default withRouter(CampagnePush);
