import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';

const StyledTableCell = withStyles(theme => ({
  root: {
    borderBottom: '1px solid #F1F1F3'
  },
  head: {
    backgroundColor: '#43425D',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);
export default StyledTableCell;
