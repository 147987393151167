import React, { useState } from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import IUser from '../../Interface/IUser';
import { USERS } from '../../graphql/user/query';
import { useQuery } from 'react-apollo-hooks';
import PopupCodeClient from './PopupCodeClient/PopupCodeClient';
import IFidelity from '../../Interface/IFidelity';
import logo from '../../Assets/Images/icon-gift.png';
import {
  SortDirection,
  stableSort,
  getSorting,
  exportToCSV
} from '../../Utilities/DatatableUtils';
import SortableHeaderCell from '../../Common/TableBodyElement/SortableHeaderCell/SortableHeaderCell';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid/Grid';
import Button from '@material-ui/core/Button/Button';

const useStyles = makeStyles(theme => ({
  roots: {
    width: '100%',
    marginTop: 24,
    overflowX: 'auto'
  },
  row: {
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'row' as 'row',
    msFlex: '0 1 auto',
    webkitFlex: '0 1 auto',
    alignItems: 'center' as 'center'
  },
  table: {
    minWidth: 700
  },
  minWidthThTitle: {
    minWidth: 144
  },
  minWidthThClient: {
    minWidth: 144
  },
  minWidthThStatus: {
    minWidth: 132
  },
  minWidthThLivrer: {
    minWidth: 100
  },
  minWidthThSupplement: {
    minWidth: 274
  },
  roundVertStatus: {
    backgroundColor: theme.customPalette.secondary.green,
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 12,
    display: 'inline-block'
  },
  roundOrangeStatus: {
    backgroundColor: theme.customPalette.secondary.main,
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 12,
    display: 'inline-block'
  },
  roundRedStatus: {
    backgroundColor: theme.customPalette.text.red,
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 12,
    display: 'inline-block'
  },
  roundGreyStatus: {
    backgroundColor: theme.customPalette.text.blueGrey,
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: 12,
    display: 'inline-block'
  },
  roundVertLivrer: {
    backgroundColor: theme.customPalette.secondary.green,
    width: 15,
    height: 15,
    borderRadius: '50%',
    margin: '0 auto'
  },
  rootPagination: {
    borderBottom: 'none',
    padding: 0
  },
  titleCatalogue: {
    fontSize: 18,
    color: theme.customPalette.text.orange
  },
  buttonPrimary: {
    color: '#fff',
    backgroundColor: theme.customPalette.secondary.green
  },
  gridRow: {
    paddingBottom: 16,
    paddingRight: 8
  }
}));

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1({});
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const StyledTableCell = withStyles(theme => ({
  root: {
    borderBottom: '1px solid #F1F1F3'
  },
  head: {
    backgroundColor: '#43425D',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 12
  }
}))(TableCell);
const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(2n)': {
      backgroundColor: '#F1F1F3'
    }
  }
}))(TableRow);

function createData(prenom, nom, ville, adresseMail, tel) {
  return {
    prenom,
    nom,
    ville,
    adresseMail,
    tel
  };
}
export interface ICategoryFormulesProps {
  titleCateg?: string;
}
export interface IClients {
  users: [IUser];
}

enum ColumnIds {
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  CITY = 'city',
  EMAIL = 'email',
  PHONE = 'phone'
}

const TableListeClient = (
  props: RouteComponentProps & ICategoryFormulesProps
) => {
  const classes = useStyles({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { data, loading, error } = useQuery<IClients>(USERS);
  const [fidelities, setFidelities] = useState<[IFidelity]>(null);
  const [user, setUser] = useState<IUser>(null);

  // ordering & filtering
  const [orderBy, setOrderBy] = useState<ColumnIds | undefined>();
  const [orderByDirection, setOrderByDirection] = useState<SortDirection>(
    'asc'
  );
  const handleSortClicked = (column: string, sortDirection: SortDirection) => {
    setOrderByDirection(sortDirection);
    setOrderBy(column as ColumnIds);
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>liste vide</div>;
  }
  if (!data || !data.users) {
    return <div>Error</div>;
  }

  const handleExport = () => {
    exportToCSV(
      data.users.map(order =>
        (({ firstName, lastName, city, email, phone }) => ({
          PRENOM: firstName,
          NOM: lastName,
          VILLE: city,
          'ADRESSE EMAIL': email,
          TELEPHONE: phone
        }))(order)
      ),
      'ListeClients'
    );
  };
  // const isCanDetailsOpen = Boolean(fidelities && fidelities.length);
  const isCanDetailsOpen = false;
  const resetData = () => {
    setFidelities(null);
    setUser(null);
    //setId('');
  };
  const checkNewCode = (fidelities: [IFidelity]) => {
    let res = false;

    fidelities.map(f => {
      if (f.codeKdo && !f.activated) {
        res = true;
      }
    });
    return res;
  };
  return (
    <div>
      <PopupCodeClient
        fidelities={fidelities}
        user={user}
        //id={id}
        open={isCanDetailsOpen}
        titleCateg={''}
        handleClose={message => {
          resetData();
        }}
        handleError={message => {
          resetData();
        }}
      />
      <Typography align="left" className={classes.titleCatalogue}>
        Listes des clients
      </Typography>
      <div className={classes.roots}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          className={classes.gridRow}
        >
          <ButtonGroup variant="contained">
            <Button onClick={handleExport} className={classes.buttonPrimary}>
              Export Excel
            </Button>
          </ButtonGroup>
        </Grid>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <SortableHeaderCell
                column={ColumnIds.FIRSTNAME}
                label="PRENOM"
                className={classes.minWidthThTitle}
                orderBy={orderBy}
                orderByDirection={orderByDirection}
                handleSortClicked={handleSortClicked}
              />
              <SortableHeaderCell
                column={ColumnIds.LASTNAME}
                label="NOM"
                align="left"
                className={classes.minWidthThClient}
                orderBy={orderBy}
                orderByDirection={orderByDirection}
                handleSortClicked={handleSortClicked}
              />

              <SortableHeaderCell
                column={ColumnIds.CITY}
                label="VILLE"
                align="left"
                className={classes.minWidthThTitle}
                orderBy={orderBy}
                orderByDirection={orderByDirection}
                handleSortClicked={handleSortClicked}
              />
              <SortableHeaderCell
                column={ColumnIds.EMAIL}
                label="ADRESSE EMAIL"
                align="left"
                className={classes.minWidthThTitle}
                orderBy={orderBy}
                orderByDirection={orderByDirection}
                handleSortClicked={handleSortClicked}
              />

              <SortableHeaderCell
                column={ColumnIds.PHONE}
                label="TELEPHONE"
                align="right"
                className={classes.minWidthThTitle}
                orderBy={orderBy}
                orderByDirection={orderByDirection}
                handleSortClicked={handleSortClicked}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort<IUser>(
              data.users,
              getSorting<keyof IUser>(orderByDirection, orderBy as keyof IUser)
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <StyledTableRow
                  key={row.id}
                  onClick={() => {
                    setFidelities(row.fidelities);
                    setUser(row);
                  }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row.firstName ? row.firstName : '-'}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.lastName ? row.lastName : '-'}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.city ? row.city : '-'}
                  </StyledTableCell>
                  
                  <StyledTableCell align="left">
                    {row.email ? row.email : '-'}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {' '}
                    {row.phone ? row.phone : '-'}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40]}
                colSpan={12}
                count={data.users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Lignes par page' },
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                className={classes.rootPagination}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  );
};

export default withRouter(TableListeClient);
