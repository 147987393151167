enum SettingKeyEnum {
  LOGO = 'LOGO',
  TARIF = 'TARIF',
  ADDRESS = 'ADDRESS',
  COORDINATES = 'COORDINATES',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  DELIVERY_PRICE = 'DELIVERY_PRICE',
  FACEBOOK = 'FACEBOOK',
  WEB = 'WEB'
}
export default SettingKeyEnum;
