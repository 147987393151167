
import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { History } from 'history';
import { UPDATE_TARIF } from '../../graphql/tarif/mutation';
import { GET_ALL_TARIF } from '../../graphql/tarif/query';

import {
    Button,
    Paper,
    TextField,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';

import IProduct from '../../Interface/IProduct';
import ISubcategory from '../../Interface/ISubcategory';

export interface IProductProps {
    idSubCategory: string;
    history: History;
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
    product: IProduct
}


const ProductEdit = (props: IProductProps) => {
    console.log("PROPS", props);

    const [nom, setNom] = useState(props.history.location.state.tarif.tarifName);
    const [prix, setPrix] = useState(props.history.location.state.tarif.tarifPrice);

    const updateTarif = useMutation(UPDATE_TARIF);

    const [open, setOpen] = React.useState(false);
    // const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const [value, setValue] = React.useState('Dione');

    function handleClickOpen() {
        setOpen(true);
    }

    const handleClose = (newValue?: string) => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };



    const handleSubmit = () => {
        updateTarif({
            update: (cache, mutationResult: any) => {
            },
            variables: {
                tarifId: props.history.location.state.tarif.tarifId,
                tarifName: nom,
                tarifPrice: prix,
                typeTarif: nom,
                product: props.history.location.state.product.productId,
                urlPhoto: "photo"
            }
        });
        props.history.push({
            pathname: '/tarifs',
            state: { product: props.history.location.state.product }
        })

    };
    const handleChange = event => {
        setNom(event.target.value);
    };
    return (
        <Fragment>
            <Typography align="center" variant="h2" component="h3" gutterBottom>
                Modifier un tarif
            </Typography>


            <Paper>
                <form onSubmit={() => handleSubmit()}>
                    <div className='margin'>
                        <Select
                            inputProps={{
                                name: 'nom',
                                id: 'nom'
                            }}
                            onChange={handleChange}
                            value={nom}
                        >

                            <MenuItem value="Petite">Petite</MenuItem>
                            <MenuItem value="Moyenne">Moyenne</MenuItem>
                            <MenuItem value="Grande">Grande</MenuItem>
                            <MenuItem value="Maxi">Maxi</MenuItem>
                        </Select>
                    </div>
                    <div className='margin'>
                        <TextField
                            label='Prix'
                            placeholder='Prix'
                            fullWidth
                            type="number"
                            required
                            value={prix}
                            onChange={e => setPrix(e.target.value)}
                        />
                    </div>
                    <div className='margin'>
                        <Button type='submit' variant='contained' color='primary'>
                            Modifier
                        </Button>
                    </div>
                </form>
            </Paper>
        </Fragment >
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        paddingTop: 20

    },
    fab: {
        margin: theme.spacing(2)
    },
    extendedIcon: {
        marginRight: theme.spacing(2)
    }
}));

export default ProductEdit;

