import gql from 'graphql-tag';
const GET_ALL_ORDER = gql`
  query {
    orders {
      id
      createdAt
      updatedAt
      orderTime
      expectedDeliveryTime
      status
      deliveryMethod
      paid
      phone
      deliveryAddress
      subTotal
      city
      deliveryPrice
      bonus {
        id
        qty
        formule {
          drinkQty
          drink {
            productName
          }
        }
      }
      user {
        id
        email
        createdAt
        lastName
        firstName
        phone
        address
      }
      details {
        comment
        codeKDO
        supplements {
          id
          supplementName
          supplementPrice
        }
        subTotal
        quantity
        comment
        priceUnit
        product {
          productName
          tarif1
          tarif2
          tarif3
          tarif4
        }
        size
      }
    }
  }
`;
export { GET_ALL_ORDER };
